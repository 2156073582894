
<div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
            <div class="row">
                <div class="form-group col-1.5 ml-2">
                  <label>From Date</label>
                  <input class="form-control border-primary" [max] = "to_date" [owlDateTime]="dt" placeholder="Date" name="from_date"
                    [(ngModel)]="from_date" readonly>
                  <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                  <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-1.5 ml-2 mr-2">
                    <label>To Date</label>
                    <input class="form-control border-primary" [min]="from_date" [max] = "maxDate" [owlDateTime]="dt1" placeholder="Date" name="to_date"
                      [(ngModel)]="to_date" readonly>
                    <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                    <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-1.5">
                  <button type="button" style="margin-top: 30px;" class="btn btn-primary btn-fw" (click)="page=1;getList('')">Load</button>
                </div>
              </div> 
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">News and Events List
              <a *ngIf = "permissionsService.checkPermission('news_and_events')" style="float: right;" title="Add News And Event" routerLink="/news-and-events"> <i class="fa fa-plus-circle" style="font-size: 35px;cursor: pointer;" aria-hidden="true"></i></a>
              </div>
            </div>
          </h4>
          <div class="table-responsive" *ngIf="newsEventsList && newsEventsList.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Year</th>
                <th>Type</th>
                <th>Subject</th>
                <th>Description</th>
                <th>File</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of newsEventsList; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>{{data?.date}}</td>
                <td>{{data?.year}}</td>
                <td>{{data?.type}}</td>
                <td>{{data?.subject}}</td>
                <td>{{data?.description}}</td>
                <td>
                    <a target="_blank" href="{{data?.files[0]?.file_path}}">
                        <img src="{{data?.files[0]?.thumbnail_path}}" />
                    </a>
                </td>
                <td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="newsEventsList && !newsEventsList.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
          <ngb-pagination  *ngIf="newsEventsList && newsEventsList.length" [collectionSize]="totalCount" [pageSize]="perPage"
          [(page)]="page" [maxSize]="3" (pageChange)="getList('pagination')"
          class="pull-right justify-content-end page-mt-10"
          aria-label="Default pagination"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>

