import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-visitors-feedback',
  templateUrl: './visitors-feedback.component.html',
  styleUrls: ['./visitors-feedback.component.scss']
})
export class VisitorsFeedbackComponent implements OnInit {
  public feedbackList: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  public perPage = 5;
  public totalCount = 0;
  public page = 1;
  public maxDate =  '';

  constructor(
    private apiService: ApiService,
    private appComponet: AppComponent,
    public permissionsService: PermissionsService,
    private appComponent: AppComponent, 
    ) { }

  ngOnInit() {
    this.getList('');
  }

  getList(_type) {
    this.appComponet.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/visitor_feedback?from_date=${from_date}&to_date=${to_date}&page=${this.page}&perpage=${this.perPage}`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.feedbackList = res;
        this.feedbackList.data.map(res => {
          res.date = moment.utc(res.date).local().format('DD-MM-yyyy');
        });
        this.totalCount = res.total;
      },
      err => {
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        this.appComponet.hideLoader();
        console.log(err);
      }
    );
  }

}