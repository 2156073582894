<div style="display: inline-block;width: 100%;height: auto;">
    <div style="width: 100%; float: left; padding-left: 20px;box-sizing: border-box;">
        <ul style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;">
            <div class="form-group col-4-div wrapper_label" style="padding-right: 10px;box-sizing: border-box;">
                <label>Qr Code*</label>
                <textarea #autofocus class="form-control border-primary" rows="1" minlength="6" maxlength="26" type="text"
                    (ngModelChange)="changeSearchValue()" [(ngModel)]="registraionNo" name="registraionNo"></textarea>
                <div class="inaild_no" *ngIf="errorMsg">{{errorMsg}}</div>
            </div>
        </ul>
        <div class="card"
            *ngIf="registraionDetail && registraionDetail.visitor_basic_details && registraionDetail.visitor_basic_details.length">
            <div class="card-body upper-header">
                <div class="table-responsive">
                    <ul
                        style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;margin-top: 50px;">
                        <li class="wrapper_label">
                            <label>Registration No.</label>
                            <small>{{registraionDetail?.pnr_no}}</small>
                        </li>

                        <li class="wrapper_label">
                            <label>Mobile Number</label>
                            <small>{{registraionDetail?.mobile_no}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>Type of visit</label>
                            <small>{{registraionDetail?.darshan_type.name}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>Date of visit</label>
                            <small>{{registraionDetail?.date_of_visit | date:'dd-MM-yyyy'}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>Time of visit</label>
                            <small>{{registraionDetail?.time_of_visit}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>City</label>
                            <small>{{registraionDetail?.city?.name}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>State</label>
                            <small>{{registraionDetail?.state.name}}</small>
                        </li>
                        
                        
                    </ul>
                    <h4 class="card-title">
                        <div class="d-flex bd-highlight">
                            <div class="p-2 flex-fill bd-highlight">Visitor Details</div>
                        </div>
                    </h4>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Registration No.</th>
                                <th>Name</th>
                                <th>Id Proof Type</th>
                                <th>Id Proof Number</th>
                                <th>Scan Time</th>
                                <th>Image</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of registraionDetail.visitor_basic_details; let i = index">
                                <td>
                                    <span>{{i+1}}</span>
                                </td>
                                <td>{{data?.reg_id}}</td>
                                <td>{{data?.name}}</td>
                                <td>{{data?.id_proof_type?.name}}</td>
                                <td>{{data?.id_proof_number}}</td>
                                <td>{{data?.qrcode_scan?.created_at}}</td>
                                <td>
                                    <img style="cursor: pointer;" (click) = "viewImage(data?.image_url)" src = "{{data?.image_url}}">
                                </td>
                                <td class="fixed-side">
                                    <button type="button" *ngIf = "!data?.verify"
                                        class="btn btn-inverse-primary btn-fw" (click) = "getVisitorDetail(data?.reg_id, data)" >Verify</button>
                                    <i  *ngIf = "data?.verify" style="color: green;" class="fa fa-check" aria-hidden="true"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

               
            </div>
        </div>
        <div class="card" *ngIf="visitorDetails">
            <div class="card-body upper-header">
                <ul 
                    style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;margin-top: 50px;">
                    <li class="wrapper_label">
                        <label>Registration No.</label>
                        <small>{{visitorDetails?.reg_no}}</small>
                    </li>
                    <li class="wrapper_label">
                        <label>Name</label>
                        <small>{{visitorDetails?.visitor_detail?.name}}</small>
                    </li>
                    <li class="wrapper_label">
                        <label>Mobile Number</label>
                        <small>{{visitorDetails?.visitor_detail?.registration?.mobile_no}}</small>
                    </li>
                    <li class="wrapper_label">
                        <label>Type of visit</label>
                        <small>{{visitorDetails?.visitor_detail?.registration?.darshan_type.name}}</small>
                    </li>
                    <li class="wrapper_label">
                        <label>Date of visit</label>
                        <small>{{visitorDetails?.visitor_detail?.registration?.date_of_visit |
                            date:'dd-MM-yyyy'}}</small>
                    </li>
                    <li class="wrapper_label">
                        <label>Time of visit</label>
                        <small>{{visitorDetails?.visitor_detail?.registration?.time_of_visit}}</small>
                    </li>
                    <li class="wrapper_label">
                        <label>City</label>
                        <small>{{visitorDetails?.visitor_detail?.registration?.city?.name}}</small>
                    </li>
                    <li class="wrapper_label">
                        <label>State</label>
                        <small>{{visitorDetails?.visitor_detail?.registration?.state.name}}</small>
                    </li>
                    <li class="wrapper_label">
                       <img style="cursor: pointer;" width="100" (click) = "viewImage(visitorDetails?.visitor_detail?.image_url)" src="{{visitorDetails?.visitor_detail?.image_url}}">
                    </li>
                </ul>
                <div style="text-align: center;color: green;" *ngIf = "successMsg">
                    {{successMsg}}
                </div>
            </div>
        </div>

        <div class="p-12"
        *ngIf="registraionNo && !registraionDetail && !visitorDetails && !loading && !errorMsg">
           <span class="ng-star-inserted no_data">No Data found.</span>
         </div>
         <div  class="mt-4" *ngIf="registraionDetail">
            <button [disabled] = "checkVerifyStatus()" class="btn btn-primary btn-fw top-btn-head" (click)="verify()">Next</button>
        </div>
        <div  class="mt-4" *ngIf="visitorDetails">
            <button class="btn btn-primary btn-fw top-btn-head" (click)="verify()">Next</button>
        </div>
    </div>
</div>