
  <div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
            <div class="row">
                <div class="form-group col-1.5 ml-2">
                  <label>From Date</label>
                  <input class="form-control border-primary" [max] = "to_date" [owlDateTime]="dt" placeholder="Date" name="from_date"
                    [(ngModel)]="from_date" readonly>
                  <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                  <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-1.5 ml-2">
                    <label>To Date</label>
                    <input class="form-control border-primary" [min]="from_date" [max] = "maxDate" [owlDateTime]="dt1" placeholder="Date" name="to_date"
                      [(ngModel)]="to_date" readonly>
                    <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                    <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-1.5 ml-2">
                    <label >Locker Location</label>
                    <select class="form-control border-primary"  name="locker_location_id" [(ngModel)]="locker_location_id"
                    >
                      <option [value]="''">All</option>
                      <option [value] ="type.id" *ngFor = "let type of locker_list">{{type.name}}</option>
                    </select>
                  </div>
                <div class="form-group col-1.5 ml-2">
                    <label >Status</label>
                    <select class="form-control border-primary" name="status_booking" [(ngModel)]="status_booking">
                      <option [value]="''">All</option>
                      <option [value]="'pending'">Pending</option>
                      <option [value]="'completed'">Completed</option>
                    </select>
                </div>
                <div class="form-group col-1.5 ml-2">
                  <button type="button" style="margin-top: 30px;" class="btn btn-primary btn-fw" (click)="getList($event)">Load</button>
                </div>
              </div>
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Locker Booking List</div>
              <div class="p-2 flex-shrink-1 bd-highlight searchValue">
                  <input  class="form-control border-primary margin-top-search" [(ngModel)] = "searchValue" 
                  placeholder="Search By Locker Number, Mobile No, " (ngModelChange) = "changeSearchValue()" name="name" >
                </div>
            </div>
          </h4>
          <div class="table-responsive" *ngIf="allLockerBooking && allLockerBooking.data && allLockerBooking.data.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Mobile No.</th>
                <th>Locker Location</th>
                <th>Locker Number</th>
                <th>item Qty</th>
                <th>Collected By</th>
                <th>Return By</th>
                <th>Status</th>
                <!-- <th>Item Image Proof</th>
                <th>Visitor Image</th> -->
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of allLockerBooking?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>{{data?.name}}</td>
                <td>{{data?.mobile_no}}</td>
                <td>{{data?.locker_location?.name}}</td>
                <td>{{data?.locker_no}}</td>
                <td>{{data?.item_qty}}</td>
                <td>{{data?.collect_by_user?.username}}</td>
                <td>{{data?.return_by?.username}}</td>
                <td>
                    <span *ngIf = "data?.is_return">Completed</span>
                    <span *ngIf = "data?.is_return == false">Pending</span>
                </td>
                <!-- <td><img class="tab_imag" src="{{data?.items_image_url}}" /> </td>
                <td><img class="tab_imag" src="{{data?.person_image_url}}" /> </td> -->
                <td>
                  <a title="Locker Booking Detail" class="ml-4" routerLink = "/locker-qr-scan/{{data.id}}">
                    <i class="fa fa-info-circle" style="font-size: 24px !important;" aria-hidden="true"></i>
                  </a>

                  <a title="Receipt" class="ml-4" (Click)="pdfDownload(data.receipt_url)" (click)="pdfDownload(data.receipt_url)">
                    <i class="fa fa-download" style="font-size: 24px !important;" aria-hidden="true"></i>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="allLockerBooking && allLockerBooking.data && !allLockerBooking.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
          <ngb-pagination  *ngIf="allLockerBooking && allLockerBooking.data && allLockerBooking.data.length" [collectionSize]="totalCount" [pageSize]="perPage"
          [(page)]="page" [maxSize]="3" (pageChange)="getList('pagination')"
          class="pull-right justify-content-end page-mt-10"
          aria-label="Default pagination"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>