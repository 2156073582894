import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { ConfirmationPopoverComponent } from '../confirmation-popover/confirmation-popover.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionsService } from '../services/permissions.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { SortListService } from '../services/sort-list.service';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-item-transactions',
  templateUrl: './item-transactions.component.html',
  styleUrls: ['./item-transactions.component.scss']
})
export class ItemTransactionsComponent implements OnInit {

  items = [];
  form: FormGroup;
  formMode = 'Add';
  page = 1;
  itemList = [];
  itemCategoryList = [];
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  public item_id = '';
  item_category_filter_id = '';
  item_category_id = '';
  item_unit = '';
  cat_id = '';

  constructor(
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    private appComponet: AppComponent,
    private modalService: NgbModal,
    public permissionsService: PermissionsService,
    private appComponent: AppComponent, 
    private sortListService: SortListService
    ) { }

  ngOnInit() {
    this.getList('');
    this.getAllItemList();
    this.getAllCategoryList();
  }

  getList(_type?) {
    this.appComponet.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/item_transactions?from_date=${from_date}&to_date=${to_date}&item_id=${this.item_id}&item_category_id=${this.item_category_id}`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.items = res.data;
        // this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }
  
  exportData(){
    this.appComponet.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/export_transactions?from_date=${from_date}&to_date=${to_date}&item_id=${this.item_id}&item_category_id=${this.item_category_id}`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        if(res.status_code === 200) {
          const imageArry =  res.data.split('/uploads');
          fileSaver.saveAs(res.data, imageArry[1]);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  ChangeItemCategory(category_id){
    this.cat_id = category_id;
    this.getAllItemList();
  }
  
  getAllItemList() {
    this.apiService.getDataFromServer('/items?category_id='+ this.cat_id).subscribe(
      res => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.itemList = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllCategoryList() {
    this.apiService.getDataFromServer('/item_categories').subscribe(
      res => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.itemCategoryList = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  
  deleteItemUnits(_item){
    const modalRef = this.modalService.open(ConfirmationPopoverComponent,
      {
        centered: false, size: 'sm', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const deleteComponent: ConfirmationPopoverComponent = modalRef.componentInstance;
    deleteComponent.massage = 'Are you sure you want to delete this?';
    modalRef.result.then((result) => {
      if (result === 'success') {
        this.apiService.deleteDataFromServer('/item_transactions/' + _item.id).subscribe(
          res => {
            this.appComponet.showMessageAlert(res['message'], 'success');
            this.getList();
          },
          err => {
            console.log(err);
          }
        );
      }
    });
  
  }

  createForm(data?) {
    this.form = this._formBuilder.group({
      item_id: [data ?  data.item_id : '', [Validators.required]],
      quantity: [data ? data.quantity : '', [Validators.required]],
      transaction_type: [data ? data.transaction_type : '', [Validators.required]],
      id:[data ?  data.id : '']
    });
   
  }

  close(){
    this.form = null;
    this.cat_id = '';
  }

  addNewItems(){
    this.createForm();
    this.formMode = 'Add';
    this.item_id = '';
    this.item_category_id = '';
    this.cat_id = '';
    this.getAllItemList();
  } 

  editItem(_data){
    this.formMode = 'Edit';
    this.createForm(_data);
  }

  onItemChange(value){
    this.item_unit = '';
  let data = this.itemList.find((item)=> item.id == value);
  this.item_unit = data.unit.name;
  this.form.controls.quantity.clearValidators();
  if(data.unit.is_fractional_value_allowed){
    this.form.controls.quantity.setValidators([Validators.required, Validators.pattern(/^[.\d]+$/)]);
  } else {
    this.form.controls.quantity.setValidators([Validators.required, Validators.pattern(/^[\d]+$/)]);
  }
  }

  saveData() {
    let formObj = this.form.getRawValue();
    // console.log(formObj);

      this.appComponent.showLoader();
      if(this.formMode == 'Add'){
        this.apiService.postDataToServer('/item_transactions' , formObj).subscribe(
          res => {
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(res['message'], 'success');
            this.createForm();
            this.close();
            this.getList();
          },
          err => {
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
            console.log(err);
          }
        );
      } else {
        this.apiService.putDataToServer('/item_transactions/' + formObj.id , formObj).subscribe(
          res => {
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(res['message'], 'success');
            this.createForm();
            this.close();
            this.getList();
          },
          err => {
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
            console.log(err);
          }
        );
      }

      this.cat_id = '';
      
  }

}
