
<div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header" >
  
          <div class="filter-wrapper field_space" *ngIf = "form">
            <form   [formGroup]="form" novalidate (ngSubmit)="saveData()">
              <div class="form-group col-12-div">

                <div class="form-group col-4-div">
                  <label >Name*</label>
                  <input [pattern]="'^[a-zA-Z \-\']+'" class="form-control border-primary"  formControlName="name" name="name" required>
                  <div class="" *ngIf="form.controls.name.touched && !form.controls.name.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.name.errors.pattern">Only Alphabet Allowed</div>
                    </div>
                  <div class="" *ngIf="form.controls.name.touched && !form.controls.name.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.name.errors.required">Name is required!</div>
                  </div>
              </div>
              </div>
  
              ​<div class="button-wrap">
              <button  type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw">Submit</button>
              <button  type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button>
            </div>
  
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  