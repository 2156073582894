<div class="row">
    <div class="col-lg-12 grid-margin">
        <div class="card">
            <div class="card-body upper-header">

                <h4 class="card-title">
                    <div class="d-flex bd-highlight">
                      <div class="p-2 flex-grow-1 bd-highlight">Local Darshanarthis Face Registration</div>
                    </div>
                  </h4>

                <div class="filter-wrapper field_space" *ngIf="form">
                    <form [formGroup]="form" novalidate (ngSubmit)="saveData()">
                        <div class="form-group visitor_info_list upload-btn-wrapper" style="width: 250px;text-align: center;float: none;margin: 0 auto;padding: 0;" >
                            <button type="button" style="margin-top: 30px;" class="btn" (click)="onClickCapturePhoto($event)">Capture Photo</button><br>
                            <img style="margin-top: 25px;border: 5px solid #f8aa4b;width: 100%;border-radius: 10px;" *ngIf="face_img" [src]="face_img" />
                          </div>

                          ​<div class="button-wrap">
                            <button  type="submit" class="btn btn-primary btn-fw">Submit</button>
                          </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>