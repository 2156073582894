import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit {

  form?: FormGroup | any;
  loading =  false;
  roleData: any;
  userList = [];
  errorMsg = '';

  constructor(
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    public activeModal: NgbActiveModal,
    ) { }

  ngOnInit(): void {
    this.CreateForm();

  }

  CreateForm() {
    this.form = this._formBuilder.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required], 
    });
  }

  
  public closeModal() {
    this.activeModal.close('');
  }

  public matchPassword() {
    if(this.form.value.password == this.form.value.confirm_password) {
      this.errorMsg = "";
    } else {
      this.errorMsg = "Passwords must match"
    }
  }
  

  submit() {
    this.apiService.putDataToServer(`/reset_user_password/${this.roleData.id}`,this.form.value).subscribe((res: any) => {
      this.activeModal.close('success');
    }, (error) => {
      console.log(error , 'error');
    });
  }
}
