import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { saveAs } from 'file-saver';
import { PermissionsService } from '../services/permissions.service';
import { SortListService } from '../services/sort-list.service';


@Component({
  selector: 'app-payment-report',
  templateUrl: './payment-report.component.html',
  styleUrls: ['./payment-report.component.scss']
})
export class PaymentReportComponent implements OnInit {

  public payment_report: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  public payment_status = 'Pending';
  public onChangeSearch = new Subject<string>();
  public perPage = 20;
  public totalCount = 0;
  public page = 1;
  public maxDate =  ''; //moment(new Date()).format('YYYY-MM-DD');
  public allDarshanTypes = [];
  public payment_type = '';
  public darshan_type_id = '';

  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent,
    public permissionsService: PermissionsService,
    private sortListService: SortListService
    ) { }

  ngOnInit() {
    this.getList('');
    this.getAllDarshanTypes();
  }

  getList(_type) {
    this.appComponent.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/payment_report?date=${from_date}&end_date=${to_date}&page=${this.page}&perpage=${this.perPage}&payment_type=${this.payment_type}&darshan_type_id=${this.darshan_type_id}`).subscribe(
      (res: any) => {
        this.payment_report = res;
        this.appComponent.hideLoader();
        this.payment_report.data.map(res => {
          res.date = moment.utc(res.date).local().format('DD-MM-YYYY');
        });
        this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }

  
  getAllDarshanTypes() {
    this.apiService.getDataFromServer('/darshan_types').subscribe(
      res => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allDarshanTypes = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  public export() {
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.appComponent.showLoader();
    this.apiService.getDataFromServer(`/export_payment_report?date=${from_date}&payment_type=${this.payment_type}&darshan_type_id=${this.darshan_type_id}&end_date=${to_date}&page=1&perpage=10000`).subscribe((res: any) => {
      if(res.status_code === 200) {
        const fileArry =  res.data.split('/uploads/');
        this.apiService.getBlobFileFromServer(res.data).subscribe(res => {
          this.appComponent.hideLoader();
          let blob:any = new Blob([res], { type: res.type });
          const url = window.URL.createObjectURL(blob);
          let file_name = fileArry[fileArry.length - 1];
          saveAs(blob, file_name);
        }, (error) => {
        });
      } 
      this.appComponent.hideLoader();
    }, (error) => {
      this.appComponent.hideLoader();
      console.log('error' , error);
    });
  }


}

