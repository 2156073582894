import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { ConfirmationPopoverComponent } from '../confirmation-popover/confirmation-popover.component';
import { SendSmsComponent } from '../send-sms/send-sms.component';
import { ApiService } from '../services/api.service';
import { SortListService } from '../services/sort-list.service';
import { PermissionsService } from '../services/permissions.service';
import { Router } from '@angular/router';
import { VerfiyPaymentStatusComponent } from '../verfiy-payment-status/verfiy-payment-status.component';
import { SmsLogsComponent } from '../sms-logs/sms-logs.component';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-registration-list',
  templateUrl: './registration-list.component.html',
  styleUrls: ['./registration-list.component.scss']
})
export class RegistrationListComponent implements OnInit {

  public registrationList: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  public searchValue = '';
  public allDarshanTypes = [];
  public payment_status = 'Pending';
  public perPage = 20;
  public totalCount = 0;
  public page = 1;
  public onChangeSearch = new Subject<string>();
  public darshan_type_id:any = '';
  public bharmaarti_type = '';
  public entry_type  = '';
  public originalSMSLogList = [];

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private appcomponent: AppComponent,
    private sortListService: SortListService,
    public permissionsService: PermissionsService,
    public router: Router
    ) { }

  ngOnInit() {
    this.onChangeSearch
    .pipe(debounceTime(1000))
    .subscribe(() => {
      this.getList('');
    });
    this.getAllDarshanTypes();
    this.getList('');
  }

  getList(_type) {
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.appcomponent.showLoader();
    this.apiService.getDataFromServer(`/visitors?date=${from_date}&end_date=${from_date}&payment_status=${this.payment_status}&page=${this.page}&perpage=${this.perPage}&search=${this.searchValue}&darshan_type_id=${this.darshan_type_id}&bharmaarti_type=${this.bharmaarti_type}&entry_type=${this.entry_type}`).subscribe(
      (res: any) => {
        this.appcomponent.hideLoader();
        this.registrationList = res;
        this.registrationList.data.map(res => {
          res.created_at = moment.utc(res.created_at).local().format('DD-MM-YYYY h:mm a z');
          res.date_of_visit = moment.utc(res.date_of_visit).local().format('DD-MM-YYYY');
          res.visitor_payment_details = res.visitor_payment_details && res.visitor_payment_details.length ? res.visitor_payment_details[0] : null;
        });
        this.totalCount = res.total;

      },
      err => {
        this.appcomponent.hideLoader();
        console.log(err);
      }
    );
  }

  viewSmsLog(data){
    const modalRef = this.modalService.open(SmsLogsComponent,
      {
        centered: false, size: 'md', windowClass: 'sms-log', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const smsLogsComponent: SmsLogsComponent = modalRef.componentInstance;
    smsLogsComponent.visitor_id = data.id;
    modalRef.result.then((result) => {
    });
  }

  verify(element){
    const modalRef = this.modalService.open(VerfiyPaymentStatusComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const verfiyPaymentStatusComponent: VerfiyPaymentStatusComponent = modalRef.componentInstance;
    verfiyPaymentStatusComponent.clientTxnId = element.visitor_payment_details.clientTxnId;
    modalRef.result.then((result) => {
      this.getList('');
    });

  }

  // reSendSMS(element){
  //   this.appcomponent.showLoader();
  //   this.apiService.getDataFromServer(`/send_epass_sms/${element.id}`).subscribe(
  //     res => {
  //       this.appcomponent.hideLoader();
  //       this.appcomponent.showMessageAlert(res['message'], 'success');
  //     },
  //     err => {
  //       this.appcomponent.showMessageAlert(err.error.error[0].message, 'error');
  //       this.appcomponent.hideLoader();
  //       console.log(err);
  //     }
  //   );

  // }

  resendSMS(visitor_id){
    this.apiService.getDataFromServer(`/resend_registration_sms/${visitor_id}`).subscribe(
      res => {
        this.appcomponent.showMessageAlert('Sms sent successfully', 'success');
      },
      err => {
        this.appcomponent.showMessageAlert('Please try after 1 hours of last sent SMS', 'error');
      }
    );
  }


  getSmsLog(visitor_id) {
    this.apiService.getPublicDataFromServer(`/sms_logs/${visitor_id}`).subscribe(
      (res:any) => {
        this.originalSMSLogList = JSON.parse(JSON.stringify(res.data));
        const lastSms = this.originalSMSLogList && this.originalSMSLogList.length ? this.originalSMSLogList[this.originalSMSLogList.length - 1] : '';
        let maxTime:any = '';
        if(lastSms && (!lastSms.delivery_date_time || lastSms.description == 'FAILED')){
          const created_at = new Date(moment.utc(lastSms.created_at).local().format('YYYY-MM-DD h:mm a z'));
          maxTime = new Date(created_at.setHours(created_at.getHours() + 1));
          if(maxTime < new Date()){
            this.resendSMS(visitor_id);
          } else {
            this.appcomponent.showMessageAlert('Please try after 1 hours of last sent SMS', 'error');
          }
        } else if(lastSms && lastSms.delivery_date_time) {
          this.appcomponent.showMessageAlert(`SMS already delivered at ${moment.utc(lastSms.delivery_date_time).local().format('YYYY-MM-DD h:mm a z')}`, 'error');
        }

        if(!lastSms){
          this.resendSMS(visitor_id);
        }

      },
      err => {
        console.log(err);
      }
    );
  }

  reSendSMS(element){
    this.apiService.getPublicDataFromServer(`/sms_logs/${element.id}`).subscribe(
      (res:any) => {
        if(res){
          const lastSms = res.data && res.data.length ? res.data[res.data.length - 1] : '';
            let maxTime:any = '';
            if(lastSms){
              const created_at = new Date(moment.utc(lastSms.created_at).local().format('YYYY-MM-DD h:mm a z'));
              maxTime = new Date(created_at.setHours(created_at.getHours() + 4));
            }
            if(maxTime < new Date() || !lastSms){
              this.appcomponent.showLoader();
              this.apiService.getDataFromServer(`/send_epass_sms/${element.id}`).subscribe(
                res => {
                  this.appcomponent.hideLoader();
                  this.appcomponent.showMessageAlert(res['message'], 'success');
                },
                err => {
                  this.appcomponent.showMessageAlert(err.error.error[0].message, 'error');
                  this.appcomponent.hideLoader();
                  console.log(err);
                }
              );
            } else {
              this.appcomponent.showMessageAlert('Please try after 4 hours of last sent SMS', 'error');
            }
        }
      },
      err => {
        console.log(err);
      }
    );


  }

  onChangeDarshanType() {
    if(this.darshan_type_id != 1) {
      this.bharmaarti_type = '';
    }
  }

  getAllDarshanTypes() {
    this.apiService.getDataFromServer('/darshan_types').subscribe(
      res => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allDarshanTypes = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllVisitorTypes() {
    this.apiService.getDataFromServer('/visitor_types').subscribe(
      res => {
        this.allDarshanTypes = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }

  checkRoles(role) {
    const roles = JSON.parse(localStorage.getItem('loginDetails')).roles;
    const result = roles.find(res => res != role);
    return result ? true : false
   }


  public changeSearchValue(): void {
    this.onChangeSearch.next();
  }


  // sendSms(data) {
  //   const modalRef = this.modalService.open(ConfirmationPopoverComponent,
  //     {
  //       centered: false, size: 'sm', windowClass: 'my-class', scrollable: false,
  //       backdrop: 'static', keyboard: false,
  //     });
  //   const deleteComponent: ConfirmationPopoverComponent = modalRef.componentInstance;
  //   deleteComponent.massage = 'Are you sure you want to re-send SMS?';
  //   modalRef.result.then((result) => {
  //     if (result === 'success') {
  //       this.apiService.getDataFromServer('/send_sms/' + data.id).subscribe(
  //         res => {
  //           this.appcomponent.showMessageAlert(res['message'], 'success');
  //         },
  //         err => {
  //           console.log(err);
  //         }
  //       );
  //     }
  //   });
  // }


  deleteRegistration(_item){
    const modalRef = this.modalService.open(ConfirmationPopoverComponent,
      {
        centered: false, size: 'sm', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const deleteComponent: ConfirmationPopoverComponent = modalRef.componentInstance;
    deleteComponent.massage = 'Are you sure you want to delete this?';
    modalRef.result.then((result) => {
      if (result === 'success') {
        this.apiService.deleteDataFromServer('/visitors/' + _item.id).subscribe(
          res => {
            this.appcomponent.showMessageAlert(res['message'], 'success');
            this.getList('');
          },
          err => {
            console.log(err);
          }
        );
      }
    });

  }


  deleteRegistrationAfterPayment(_item){
    const modalRef = this.modalService.open(ConfirmationPopoverComponent,
      {
        centered: false, size: 'sm', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const deleteComponent: ConfirmationPopoverComponent = modalRef.componentInstance;
    deleteComponent.massage = 'Are you sure you want to delete this?';
    modalRef.result.then((result) => {
      if (result === 'success') {
        this.apiService.deleteDataFromServer('/delete_visitor/' + _item.id).subscribe(
          res => {
            this.appcomponent.showMessageAlert(res['message'], 'success');
            this.getList('');
          },
          err => {
            console.log(err);
            this.appcomponent.showMessageAlert(err.error.error[0]['message'], 'error');
          }
        );
      }
    });

  }



  onPrintClick(_data){
      this.apiService.getDataFromServer('/generate_entry_pass/' + _data.id).subscribe(
        res => {
          window.open(res['data'][0]);
        },
        err => {
          console.log(err);
        }
      );
  }


  getTagHtml(_data){
    let PrintData = '';
    _data.visitor_basic_details.forEach((val, key) =>{
       PrintData += `<div class="container" >
       <div class="logo_wrap">
       <img style="width: 20mm;float: left;" src=${val.actual_qr_url}>
       </div>
            <div class="hader_tag">
                <h4>श्री महाकालेश्वर मंदिर प्रबंध समिति</h4>
            </div>
          <div class="heading">
              <div>Name : ${val.name} (${val.gender.slice(0,1)})</div>
              <div>Date: ${_data.date_of_visit}</div>

          </div>
          <div class="heading">
              <div>Darshan Type : ${_data.darshan_type.name}</div>
              <div>Department : ${_data.reference_department.name}</div>
          </div>
          
          <div class="heading" style="padding-top: 0;height: 100px;transform: inherit;display: inline-block;margin-top: 45px;width: 250px;">
          <img style="width: 20mm;padding-top: 1px;float: right;" src=${val.actual_qr_url}>
      </div>
  
      </div>`;
    })
    return `<!DOCTYPE html>
    <html lang="en">
    
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
      
        <style>
        .container {
          padding-top: 200px;
          padding-bottom: 20px;
          box-sizing: border-box;
          position: relative;
          min-height: 850px;
        }
        .main_div{	
          position: absolute;
          left: -172px;
        }
        .heading {
          width: 270px;
          height: 225px;
          transform: rotate(90deg);
          font-weight: bold;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          padding-top: 50px;
        } 
        .logo_wrap{
          width: 155px;
          transform: rotate(90deg);
          padding-top: 0;
          height: 225px;
          display: inline-block;
          position: absolute;
          top: 0;
          right: 55px;
        }
        .hader_tag{
          width: 350px;
          transform: rotate(90deg);
          padding-top: 0;
          height: 25px;
          display: inline-block;
          position: absolute;
          top: 48%;
          left: 82px;
          text-align: center;
        }
        </style>
    </head> 
    <body>
    <div class="wrap">
<div class="main_div">
    ${PrintData}
    </div>
    </div>
    </body>
    </html>`;
  }


  printTag(_data){

    this.apiService.postDataToServer('/check_printed_status',{"visitor_id": _data.id}).subscribe(
      (res:any) => {
        if(res.data.is_tag_printed == false){
          let popupWinindow
          let innerContents = this.getTagHtml(_data);
          popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.write(innerContents);
          popupWinindow.document.close();
          popupWinindow.focus();
          setTimeout(val => {
            popupWinindow.print();
            popupWinindow.close();
          },1000);
          if(res.data.is_admin == false){
            _data.is_tag_printed = true;
          }
        } else {
          this.appcomponent.showMessageAlert('Tag already printed', 'error');
        }
        
      },
      err => {
        console.log(err);
      }
    );
    
  }



  exportList(){
    this.appcomponent.showLoader();
    let from_date = moment(this.from_date).format('YYYY-MM-DD');
    let to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/export_visitors?date=${from_date}&end_date=${from_date}&payment_status=${this.payment_status}&page=${this.page}&perpage=${this.perPage}&search=${this.searchValue}&darshan_type_id=${this.darshan_type_id}&bharmaarti_type=${this.bharmaarti_type}&entry_type=${this.entry_type}`).subscribe(
      (res: any) => {
        this.appcomponent.hideLoader();
        if(res.status_code === 200) {
          const imageArry =  res.data.split('/uploads');
          fileSaver.saveAs(res.data, imageArry[1]);
        } 
      }, err => {
        this.appcomponent.showMessageAlert(err.error.error[0].message, 'error');
        this.appcomponent.hideLoader();
      });
  }

}
