
  <div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header" *ngIf="roles && roles.data && roles.data.length">
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-fill bd-highlight">Users</div>
            </div>
          </h4>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile No.</th>
                <th>Reset Password</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of roles?.data; let i = index">
                <td>{{i+1}}</td>
                <td>{{data?.username}}</td>
                <td>{{data?.email}}</td>
                <td>{{data?.mobile_no}}</td>
                <td>
                 <a title="Reset Password" (click)="resetPassword(data)"><i class="fa fas fa-lock"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="p-12" *ngIf="roles && roles.data && !roles.data.length">
          <span class="ng-star-inserted no_data" >No Data found.</span>
        </div>
      </div>
    </div>
  </div>

