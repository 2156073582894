
<div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
            <div class="row">
                <div class="form-group col-1.5 ml-2">
                  <label>From Date</label>
                  <input class="form-control border-primary" [max] = "to_date" [owlDateTime]="dt" placeholder="Date" name="from_date"
                    [(ngModel)]="from_date" readonly>
                  <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                  <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-1.5 ml-2">
                    <label>To Date</label>
                    <input class="form-control border-primary" [min]="from_date" [max] = "maxDate" [owlDateTime]="dt1" placeholder="Date" name="to_date"
                      [(ngModel)]="to_date" readonly>
                    <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                    <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                
                
                <div class="form-group col-1.5 ml-2">
                  <button type="button" style="margin-top: 30px;" class="btn btn-primary btn-fw" (click)="getList($event)">Load</button>
                </div>
              </div>
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Bhasmaarti Advance Booking Requests List</div>
              <div class="p-2 flex-shrink-1 bd-highlight searchValue">
                  <!-- <input  class="form-control border-primary margin-top-search" [(ngModel)] = "searchValue" 
                  placeholder="Search By Locker Number, Mobile No, " (ngModelChange) = "changeSearchValue()" name="name" > -->
                </div>
            </div>
          </h4>
          <div class="table-responsive" *ngIf="allBhasmaartiRequests && allBhasmaartiRequests.data && allBhasmaartiRequests.data.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Mobile No.</th>
                <th>No. Of Persons</th>
                <th>Created Date</th>
                <th>Request For Date</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of allBhasmaartiRequests?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>{{data?.first_name}} {{data?.last_name}}</td>
                <td>{{data?.mobile_no}}</td>
                <td>{{data?.no_of_persons}}</td>
                <td>{{data?.created_at | date : 'dd-MM-yyyy'}}</td>
                <td>{{data?.request_for_date | date : 'dd-MM-yyyy'}}</td>
                <td>
                    <a title="Bhasmaarti Request Detail" class="ml-4" routerLink = "/bhasmaarti-requests-detail/{{data.id}}">
                      <i class="fa fa-info-circle" style="font-size: 24px !important;" aria-hidden="true"></i>
                    </a>
                  </td>
            
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="allBhasmaartiRequests && allBhasmaartiRequests.data && !allBhasmaartiRequests.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
          <ngb-pagination  *ngIf="allBhasmaartiRequests && allBhasmaartiRequests.data && allBhasmaartiRequests.data.length" [collectionSize]="totalCount" [pageSize]="perPage"
          [(page)]="page" [maxSize]="3" (pageChange)="getList('pagination')"
          class="pull-right justify-content-end page-mt-10"
          aria-label="Default pagination"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>