
<div class="modal-header py-1">
    <h5 class="modal-title">SMS</h5>
    <button type="button" 
      class="close" 
      aria-label="Close" 
      (click)="activeModal.close('Close click')" 
      [disabled]="loading">
      <span aria-hidden="true">&times;</span>
    </button> 
  </div>
  <div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group">
        <label>Subject*</label>
        <input type="text" class="form-control form-control-sm" formControlName="subject">
      </div>
      <div class="form-group">
        <label for="exampleFormControlTextarea1">Message*</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="7" formControlName="message" name="message"></textarea>
    </div>
  </form>
  </div>
  <div class="modal-footer">
    <button  type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw" (click) = "submit()">Send</button>
    <button 
      type="button" 
      class="btn btn-secondary" 
      (click)="closeModal()">
      Cancel
  </button>
  </div>
    