import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { ConfirmationPopoverComponent } from '../confirmation-popover/confirmation-popover.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionsService } from '../services/permissions.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {

 
  public department: any;
  public onChangeSearch = new Subject<string>();
  public perPage = 20;
  public totalCount = 0;
  public page = 1;
  form: FormGroup;
  formMode = 'Add';

  constructor(
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    private appComponet: AppComponent,
    private modalService: NgbModal,
    public permissionsService: PermissionsService,
    private appComponent: AppComponent, 
    ) { }

  ngOnInit() {
    this.getList('');
  }

  getList(_type) {
    this.appComponet.showLoader();
    this.apiService.getDataFromServer(`/departments`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.department = res;
        // this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }

  
  deleteDepartment(_item){
    const modalRef = this.modalService.open(ConfirmationPopoverComponent,
      {
        centered: false, size: 'sm', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const deleteComponent: ConfirmationPopoverComponent = modalRef.componentInstance;
    deleteComponent.massage = 'Are you sure you want to delete this?';
    modalRef.result.then((result) => {
      if (result === 'success') {
        this.apiService.deleteDataFromServer('/departments/' + _item.id).subscribe(
          res => {
            this.appComponet.showMessageAlert(res['message'], 'success');
            this.getList('');
          },
          err => {
            console.log(err);
          }
        );
      }
    });
  
  }

  createForm(data?) {
    this.form = this._formBuilder.group({
      name: [data ?  data.name : '', [Validators.required]],
      eod_time: [data && data.max_time_allowed && data.max_time_allowed.eod_time ?  moment(data.max_time_allowed.eod_time, 'HH:mm:ss').format() : '', [Validators.required]],
      id:[data ?  data.id : '']
    });
   
  }

  close(){
    this.form = null;
  }

  saveData() {
    let formObj = this.form.getRawValue();
    formObj.eod_time = moment(formObj.eod_time).format('HH:mm:ss');

      this.appComponent.showLoader();
      this.apiService.putDataToServer('/department_max_time/' + formObj.id , formObj).subscribe(
        res => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.createForm();
          this.close();
          this.getList('');
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
  }

}