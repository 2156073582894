import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-dharmshala-reservations-list',
  templateUrl: './dharmshala-reservations-list.component.html',
  styleUrls: ['./dharmshala-reservations-list.component.scss']
})
export class DharmshalaReservationsListComponent implements OnInit {
  public payment_report: any;
  allDharmshalaBooking: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  public payment_status = 'Pending';
  public onChangeSearch = new Subject<string>();
  public perPage = 10;
  public totalCount = 0;
  public page = 1;
  public maxDate = ''; // moment(new Date()).format('YYYY-MM-DD');

  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent,
    public permissionsService: PermissionsService

    ) { }

  ngOnInit() {
    this.getList('');
  }

  getList(_type) {
    this.appComponent.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/reservations?from_date=${from_date}&to_date=${to_date}&page=${this.page}&perpage=${this.perPage}`).subscribe(
      (res: any) => { 
        this.appComponent.hideLoader();
        this.allDharmshalaBooking = res;
        this.allDharmshalaBooking.data.map(res => {
          // res.created_at = moment.utc(res.created_at).local().format('DD-MM-YYYY h:mm a z');
          res.from_date = moment.utc(res.from_date).local().format('DD-MM-YYYY');
          res.to_date = moment.utc(res.to_date).local().format('DD-MM-YYYY');
        });
        this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }


}