
<div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Item Units</div>
              <div class="form-group  text-center pt-4 mt-2 ml-4" style="margin-left: auto !important;">
                <a title="Add Item Units" *ngIf = "permissionsService.checkPermission('create_item_units')" (click)="addNewItems()"> <i class="fa fa-plus-circle" style="font-size: 35px;cursor: pointer;" aria-hidden="true"></i></a>
              </div>
            </div>
          </h4>

          <div class="filter-wrapper field_space" *ngIf = "form">
            <form   [formGroup]="form" novalidate (ngSubmit)="saveData()">
              <div class="form-group col-12-div">

                <div class="form-group col-4-div">
                    <label >Name*</label>
                    <input [pattern]="'^[a-zA-Z \-\']+'" class="form-control border-primary"  formControlName="name" name="name" required>
                    <div class="" *ngIf="form.controls.name.touched && !form.controls.name.valid">
                        <div  class="inaild_no" *ngIf=" form.controls.name.errors.pattern">Only Alphabet Allowed</div>
                      </div>
                    <div class="" *ngIf="form.controls.name.touched && !form.controls.name.valid">
                        <div  class="inaild_no" *ngIf=" form.controls.name.errors.required">Name is required!</div>
                    </div>
                </div>

                <div class="form-group col-4-div">
                  <label >Abbreviation*</label>
                  <input  class="form-control border-primary"  formControlName="abbreviation" name="abbreviation" required>
                 
                  <div class="" *ngIf="form.controls.abbreviation.touched && !form.controls.abbreviation.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.abbreviation.errors.required">Abbreviation is required!</div>
                  </div>
              </div>

              <div class="form-group col-4-div" style="padding-left: 34px;padding-top: 28px;">
                <input type="checkbox"  formControlName="is_fractional_value_allowed" name="is_fractional_value_allowed" style="zoom: 1.6;" class="form-check-input" id="exampleCheck1">
                <label class="form-check-label" style="padding-top: 8px;" for="exampleCheck1">Is Fractional Value Allowed</label>
              </div>

      
              </div>
  
             <div class="button-wrap">
              <button  type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw">Submit</button>
              <button  type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button>
            </div>
  
            </form>
          </div>
          
          <ng-container *ngIf="!form">
          <div class="table-responsive"  *ngIf="itemUnits.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
               <th>Name</th>
               <th>Abbreviation</th>
               <th>Fractional Value Allowed</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of itemUnits; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>
                  <span *ngIf = "data?.name">{{data?.name}}</span>
                </td>
                <td>
                  <span *ngIf = "data?.abbreviation">{{data?.abbreviation}}</span>
                </td>
                <td>
                  <span>{{data?.is_fractional_value_allowed ? 'Yes' : 'No'}}</span>
                </td>
                <td class="fixed-side">
                  <a title="Edit" *ngIf = "permissionsService.checkPermission('update_item_units')"   (click)="editItem(data)"><i class="fa fa-pencil" ></i></a>
                  <a title="Delete" *ngIf = "permissionsService.checkPermission('delete_item_units')" class="ml-4" (click)="deleteItemUnits(data)"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="itemUnits && !itemUnits.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
        </ng-container>
        </div>
      </div>
    </div>
  </div>

