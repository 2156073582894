import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-send-otp',
  templateUrl: './send-otp.component.html',
  styleUrls: ['./send-otp.component.scss']
})
export class SendOtpComponent implements OnInit {

  form?: FormGroup | any;
  loading =  false;
  userDetail: any;
  errorMsg = '';
  reSendOtpMsg = '';

  constructor(
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    public activeModal: NgbActiveModal,
    ) { }

  ngOnInit(): void {
    this.CreateForm();


  }

  CreateForm() {
    this.form = this._formBuilder.group({
      otp: ['', Validators.required],
      mobile_no: [this.userDetail.mobile_no, Validators.required],
    });
  }

  
  public closeModal() {
    this.activeModal.close('');
  }

  public reSendOTP() {
    this.errorMsg = '';
    this.apiService.postDataToServer('/generate_otp',this.userDetail).subscribe((res: any) => {
      if(res.success) {
        this.reSendOtpMsg = res.message;
        this.CreateForm();
      }
   
    }, (error) => {
      console.log(error , 'error');
    });
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  
  

  submit() {
    this.errorMsg = '';
    this.reSendOtpMsg = '';
    this.apiService.postDataToServer('/validate_otp',this.form.value).subscribe((res: any) => {
      if(res.success) {
        this.activeModal.close(res);
      }
    }, (error) => {
      this.errorMsg = error.error.error[0].message;
      console.log(error , 'error');
    });
  }
}
