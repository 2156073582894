import { Component,QueryList,OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';


@Component({
  selector: 'app-qr-scan',
  templateUrl: './qr-scan.component.html',
  styleUrls: ['./qr-scan.component.scss']
})
export class QrScanComponent implements OnInit {
  visitorDetails: any;
  registraionDetail:any;
  @ViewChild('autofocus', { static: false }) textAreas: ElementRef;

  registraionNo = '';
  public onChangeSearch = new Subject<string>();
  public errorMsg = '';
  public successMsg = '';
  public loading = false;
  public env: any = environment;

  constructor(
  private appComponent: AppComponent,
  private apiService: ApiService,
  private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.onChangeSearch
    .pipe(debounceTime(1500))
    .subscribe(() => {
      this.registraionNo = this.registraionNo.trim();
      if(this.registraionNo){
        if (this.registraionNo && this.registraionNo.length > 8 && this.registraionNo.substring(0, 18) == 'Registration ID : ') {
          this.registraionNo = this.registraionNo.substring(18);
        }
        this.getVisitorDetail();
      }
    });
  }
  ngAfterViewInit(){
    this.textAreas.nativeElement.focus();
  }

  viewImage(imageUrl){
    const modalRef = this.modalService.open(ImageDialogComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const imageDialogComponent: ImageDialogComponent = modalRef.componentInstance;
    imageDialogComponent.imageUrl = `${imageUrl}`;
  }


  getVisitorDetail(registraionNo?,element?){
    this.loading = true;
   
    this.errorMsg = '';
    if((this.registraionNo && this.registraionNo.length == 8) || registraionNo) {
      let formObj = {
        reg_no : registraionNo ? registraionNo : this.registraionNo
      };
     this.appComponent.showLoader();
     this.apiService.postDataToServer('/qr_code_scans', formObj).subscribe(
       (res:any) => {
         if(registraionNo){
           element.verify = true
         } else{
          this.visitorDetails = res && res.data ? res.data : res;
         }
         this.appComponent.showMessageAlert(res['message'], 'success');
        this.appComponent.hideLoader();
        this.errorMsg = '';
        this.loading = false;
        this.successMsg = res['message'];
       },
       err => {
        this.errorMsg = err.error.error[0].message;
         this.appComponent.hideLoader();
         this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
         console.log(err);
         this.loading = false;
       }
     );
    } else if(this.registraionNo.length == 6) {
      this.getList();
    } else {
      this.errorMsg = 'Invalid Qr-Code!'
      this.loading = false;
    }

  }

  getList() {
    this.appComponent.showLoader();
    this.apiService.getDataFromServer(`/visitors?search=${this.registraionNo}`).subscribe(
      (res: any) => {
        this.registraionDetail = res && res.data && res.data.length ? res.data[0] : null;
        this.appComponent.hideLoader();
         this.loading = false;
      },
      err => {
        this.errorMsg = err.error.error[0].message;
         this.appComponent.hideLoader();
         this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
         this.loading = false;
      }
    );
  }

  verify() {
    this.registraionNo = '';
    this.errorMsg = '';
    this.textAreas.nativeElement.focus();
    this.visitorDetails = null;
    this.registraionDetail = null;
    this.successMsg = '';
  }

  public changeSearchValue(): void {
    this.successMsg = '';
    this.loading = true;
    this.visitorDetails = null;
    this.registraionDetail = null;
    this.errorMsg = '';
    this.onChangeSearch.next();
  }

  public checkVerifyStatus() {
    if(this.registraionDetail && this.registraionDetail.visitor_basic_details){
     const isExist = this.registraionDetail.visitor_basic_details.find(res =>  !res.verify);
      if(isExist) {
        return true;
      } else {
        return false;
      }
      
    } else {
      return false;
    }
  }

}
