
 <div class="row dashbord-box" id="reg_ls">
   <div class="col-3">
    <div class="card mb-3" >
      <div class="card-body">
        <h5 class="card-title">Registered</h5>
        <h2 class="card-text"><b>{{registrationList?.totoal_registation_count}}</b></h2>
      </div>
    </div>
   </div>
   <div class="col-3">
    <div class="card mb-3" >
      <div class="card-body">
        <h5 class="card-title">Completed</h5>
        <h2 class="card-text"><b>{{registrationList?.visited_count}}</b></h2>
      </div>
    </div>
 </div>
 <div class="col-3">
  <div class="card mb-3" >
    <div class="card-body">
      <h5 class="card-title">Current</h5>
      <h2 class="card-text"><b>{{registrationList?.current_visit_count}}</b></h2>
    </div>
  </div>
</div>
<div class="col-3">
  <div class="card mb-3" >
    <div class="card-body">
      <h5 class="card-title">Pending</h5>
      <h2 class="card-text"><b>{{registrationList?.pendind_visit_count}}</b></h2>
    </div>
  </div>
</div>
 </div>

 
 <div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
            <div class="row">
                <div class="form-group col-2">
                  <label>Date of Visit</label>
                  <input class="form-control border-primary" [owlDateTime]="dt1" placeholder="Date" name="from_date"
                    [(ngModel)]="from_date" readonly>
                  <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                  <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
              <div class="form-group col-2">
                <label >Payment Status</label>
                <select class="form-control border-primary" name="payment_status" [(ngModel)]="payment_status">
                  <option [value]="''">All</option>
                  <option [value]="'Pending'">Pending</option>
                  <option [value]="'Paid'">Visited</option>
                </select>
              </div>
              <div class="form-group col-2">
                <label >Entry Type</label>
                <select class="form-control border-primary" name="entry_type" [(ngModel)]="entry_type">
                  <option [value]="''">All</option>
                  <option [value]="'Public Domain'">Public Domain</option>
                  <option [value]="'Mandir Office'">Mandir Office</option>
                </select>
              </div>
              <div class="form-group col-2">
                <label >Darshan Type</label>
                <select class="form-control border-primary" (change)="onChangeDarshanType()"  name="darshan_type_id" [(ngModel)] = "darshan_type_id">
                  <option [value]="''">select</option>
                  <option *ngFor="let data of allDarshanTypes" [value]="data.id">{{data.name}}</option>
                </select>
              </div>
              <div class="form-group col-2" *ngIf = "darshan_type_id == 1">
                <label >Bhasma Aarti Type</label>
                <select class="form-control border-primary" name="bharmaarti_type" [(ngModel)]="bharmaarti_type">
                  <option value = "">Select</option>
                  <option [value]="'Paid'">Paid</option>
                  <option [value]="'Free'">Free</option>
                </select>
              </div>
                <div class="form-group">
                  <button type="button" style="margin-top: 30px;" class="btn btn-primary btn-fw top-btn-head" (click)="getList($event)">Load</button>
                </div>
                 <div class="form-group col-1  text-center pt-4 mt-2 ml-4" style="margin-left: auto !important;" *ngIf = "permissionsService.checkPermission('create_visitors')">
                   <a title="Add Registration" routerLink="/registration"> <i class="fa fa-plus-circle" style="font-size: 35px;cursor: pointer;" aria-hidden="true"></i></a>
                 </div>
              </div>
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-fill bd-highlight">Registration List</div>
              <div class="p-2 flex-shrink-1 bd-highlight searchValue">
                <button style="float: right;" type="button" class="btn btn-primary btn-fw top-btn-head" (click)="exportList()">Export</button>
                <input style="float: right;width: 250px;margin-right: 10px;"  class="form-control border-primary margin-top-search" [(ngModel)] = "searchValue" placeholder="Search By First Name, Reg No., Mobile No. " (ngModelChange) = "changeSearchValue()" name="name" >
              </div>
            </div>
          </h4>
          <div class="table-scroll" *ngIf="registrationList && registrationList.data && registrationList.data.length">
            <div class="table-wrap" >
            <table class="table main-table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Registration No.</th>
                <th>Name</th>
                <th>Mobile No.</th>
                <th>Darshan Type</th>
                <th>Date Of Visit</th>
                <th>Time Of Visit</th>
                <th>No. Of People</th>
                <th>Transaction ID</th>
                <th>City</th>
                <th>State</th>
                <th>Created By</th>
                <th>Created At</th>
                <th class="fixed-side">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of registrationList?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>{{data?.pnr_no}}</td>
                <td>{{data?.first_name}} {{data?.last_name}}</td>
                <td>{{data.mobile_no}}</td>
                <td>{{data?.darshan_type?.name}}</td>
                <td>{{data.date_of_visit}}</td>
                <td>{{data.time_of_visit}}</td>
                <td>{{data.no_of_persons}}</td>
                <td>
                    <span *ngIf="data?.payment_status == 'Paid'"><span *ngIf="data?.visitor_payment_details">{{data?.visitor_payment_details?.trx_id}}</span></span>
                    <span *ngIf="data?.payment_status != 'Paid'">Unpaid</span>
                </td>
                <td>{{data?.city?.name}}</td>
                <td>{{data?.state?.name}}</td>
                <td>{{data?.created_by_user?.username}}</td>
                <td>{{data?.created_at}}</td>

                <td class="fixed-side">
                  <a title="Edit" *ngIf = "permissionsService.checkPermission('update_visitors')" [ngClass]="data.payment_status == 'Paid' ? 'anchor_disabled':''"  [routerLink]="['/registration/', data.id]"><i class="fa fa-pencil" ></i></a>
                  <a title="Delete" class="ml-4" *ngIf = "permissionsService.checkPermission('delete_visitors') && data.payment_status != 'Paid'" (click)="deleteRegistration(data)"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                  <a title="Delete After Payment" class="ml-4" *ngIf = "permissionsService.checkPermission('allow_deletion_after_payment')  && data.reference_department && data.reference_department.allow_deletion_after_payment && data.payment_status == 'Paid'" (click)="deleteRegistrationAfterPayment(data)"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                  <a  title="Print" *ngIf="data.payment_status != 'Pending' && permissionsService.checkPermission('read_generate_entry_pass')" ><i class="fa fa-print ml-4" aria-hidden="true"
                    (click)="onPrintClick(data)"></i></a>
                  <a title="Pay" class="ml-4"  *ngIf="data.payment_status == 'Pending' && permissionsService.checkPermission('create_visitor_payment') && data?.entry_type != 'Public Domain'" ><i class="fa fa-credit-card" aria-hidden="true"
                    routerLink="/payment/{{data.id}}"></i></a>
                  <a title="Verify" *ngIf = "data?.visitor_payment_details && data?.visitor_payment_details?.clientTxnId && data?.entry_type == 'Public Domain' && data?.visitor_payment_details.spRespStatus != 'SUCCESS'" class="ml-4 verify_icon" (click) = "verify(data)">
                    <img class="verify_icon" src = "assets/images/verified.png">
                  </a>
                  <a title="Resend SMS For E-Pass" *ngIf = "data?.payment_status == 'Paid'" class="ml-4 verify_icon" (click) = "reSendSMS(data)">
                    <img class="verify_icon" src = "assets/images/resend.png">
                  </a>
                  <a title="Resend SMS For Registration " *ngIf = "data?.entry_type == 'Mandir Office' && data?.payment_status != 'Paid'" class="ml-4 verify_icon" (click) = "getSmsLog(data.id)">
                    <img class="verify_icon" src = "assets/images/resend.png">
                  </a>
                  <a title="View SMS Details" class="ml-4" (click) = "viewSmsLog(data)">
                    <img class="verify_icon" style="width: 30px;height: 30px;border-radius: 0px;" src = "assets/images/sms_log.png">
                  </a>

                  <a title="Visitor Info" class="ml-4" routerLink = "/visitors-details/{{data.pnr_no}}">
                    <i class="fa fa-info-circle" style="font-size: 26px !important;" aria-hidden="true"></i>
                  </a>
                  <a *ngIf="data?.payment_status == 'Paid' && (data?.darshan_type_id == 1 || data?.darshan_type_id == 4) && !data?.is_tag_printed" title="Print Tag" class="ml-4" (click) = "printTag(data)">
                    <i class="fa fa-tag" style="font-size: 26px !important;" aria-hidden="true"></i>
                  </a>
                  <!-- <button type="button"  >Verify</button> -->
                  <!-- <a  class="ml-4" (click) = "sendSms(data)"><i class="fa fa-commenting" aria-hidden="true"></i></a> -->
                </td>
              </tr>
              </tbody>
            </table>
          </div>
            
  
          </div>
          <div class="p-12" *ngIf="registrationList && registrationList.data && !registrationList.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
          <ngb-pagination *ngIf="registrationList && registrationList.data && registrationList.data.length" [collectionSize]="totalCount" [pageSize]="perPage"
            [(page)]="page" (pageChange)="getList('pagination')"[maxSize]="3"
            class="pull-right justify-content-end page-mt-10"
            aria-label="Default pagination"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>

