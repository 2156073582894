import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import * as fileSaver from 'file-saver';
import { PermissionsService } from '../services/permissions.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-daily-quota',
  templateUrl: './daily-quota.component.html',
  styleUrls: ['./daily-quota.component.scss']
})
export class DailyQuotaComponent implements OnInit {

  public payment_report: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public payment_status = 'Pending';
  public onChangeSearch = new Subject<string>();
  public perPage = 20;
  public totalCount = 0;
  public page = 1;
  public darshan_type = '';


  constructor(
    private apiService: ApiService,
    private appComponet: AppComponent,
    public permissionsService: PermissionsService
    ) { }

  ngOnInit() {
    this.getList('');
  }

  getList(_type) {
    this.appComponet.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/daily_quota?date=${from_date}&darshan_type=${this.darshan_type}`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.payment_report = res;
        // this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }

  public export() {
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    this.appComponet.showLoader();
    this.apiService.getDataFromServer(`/export_daily_quota_report?date=${from_date}&darshan_type=${this.darshan_type}`).subscribe((res: any) => {
      if(res.status_code === 200) {
        const fileArry =  res.data.split('/uploads/');
        this.apiService.getBlobFileFromServer(res.data).subscribe(res => {
          this.appComponet.hideLoader();
          let blob:any = new Blob([res], { type: res.type });
          const url = window.URL.createObjectURL(blob);
          let file_name = fileArry[fileArry.length - 1];
          saveAs(blob, file_name);
        }, (error) => {
        });
      } 
      this.appComponet.hideLoader();
    }, (error) => {
      this.appComponet.hideLoader();
      console.log('error' , error);
    });
  }


}
