
<div class="modal-header py-1">
    <h5 class="modal-title">Reset Password</h5>
    <button type="button" 
      class="close" 
      aria-label="Close" 
      (click)="activeModal.close('Close click')" 
      [disabled]="loading">
      <span aria-hidden="true">&times;</span>
    </button> 
  </div>
  <div class="modal-body">
  <form [formGroup]="form">
      <div class="row">
        <div class="form-group col-12">
            <label>New Password</label>
            <input type="password" class="form-control form-control-sm" formControlName="password">
        </div>
        <div class="form-group col-12">
          <label>New Password (Confirm)</label>
          <input type="password" (ngModelChange) = "matchPassword()" class="form-control form-control-sm" formControlName="confirm_password">
          <span style="color: red;" *ngIf = "errorMsg">{{errorMsg}}</span>
      </div>
      </div>
  </form>
  </div>
  <div class="modal-footer">
    <button  type="submit" [disabled]="!form.valid || errorMsg" class="btn btn-primary btn-fw" (click) = "submit()">Submit</button>
    <button 
      type="button" 
      class="btn btn-secondary" 
      (click)="closeModal()">
      Cancel
  </button>
  </div>
    