import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {AppComponent} from '../app.component';
import {ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.scss']
})
export class AddDepartmentComponent implements OnInit {

  form: FormGroup;
  formMode = 'Add';
  allDepartments = [];
  userList = [];

  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService, 
    private appComponent: AppComponent, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appComponet: AppComponent,

    ) {
   
  }

  ngOnInit() {
    const id = this.activatedRoute.snapshot.params['id'];
    if(id) {
      this.formMode =  'Edit';
      this.getDepartmentDetail(id);
    } else {
      this.createForm();
    }
  }

  getDepartmentDetail(id) {
    this.appComponet.showLoader();
    this.apiService.getDataFromServer(`/departments/${id}`).subscribe(
      (res:any) => {
        this.appComponent.hideLoader();
        this.createForm(res.data);
      },
      err => {
        console.log(err);
      }

    );
  }


  createForm(data?) {
    this.form = this._formBuilder.group({
      name: [data ?  data.name : '', [Validators.required,this.removeSpaces]],
      dept_per_day_permission_limit: [data ? data.dept_per_day_permission_limit : '', [Validators.required]],
      per_day_protocol_darshan_limit: [data ? data.per_day_protocol_darshan_limit : '', [Validators.required]],
      id:[data ?  data.id : '']
    });
   
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
  


  saveData() {
    const formObj = this.form.getRawValue();

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postDataToServer('/departments', formObj).subscribe(
        res => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.createForm();
          this.close();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
     else {
      this.appComponent.showLoader();
      this.apiService.putDataToServer(`/departments/${formObj.id}`, formObj).subscribe(
        res => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.close();
          this.createForm();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }

  close() {
    this.router.navigate(['/department']);

  }

}