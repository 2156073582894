import { Component,QueryList,OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-visitors-details',
  templateUrl: './visitors-details.component.html',
  styleUrls: ['./visitors-details.component.scss']
})
export class VisitorsDetailsComponent implements OnInit {

  visitorDetails = null;
  registraionDetail = null;
  @ViewChild('autofocus', { static: false }) textAreas: ElementRef;
  registraionNo = '';
  public registraionNoByParams = '';
  public errorMsg = '';
  public successMsg = '';
  public loading = false;
  public env: any = environment;

  constructor(
  private appComponent: AppComponent,
  private apiService: ApiService,
  private modalService: NgbModal,
  private route: ActivatedRoute
  ) { 
    this.registraionNoByParams = this.route.snapshot.params.id;
    this.registraionNo = this.registraionNoByParams;
    if(this.registraionNoByParams) {
      this.getList();
    }
  }

  ngOnInit() {
  }
  ngAfterViewInit(){
    if(this.textAreas && this.textAreas.nativeElement){
      this.textAreas.nativeElement.focus();
    }
  }

  viewImage(imageUrl){
    const modalRef = this.modalService.open(ImageDialogComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const imageDialogComponent: ImageDialogComponent = modalRef.componentInstance;
    imageDialogComponent.imageUrl = `${imageUrl}`;
  }



  getList() {
    this.successMsg = '';
    this.loading = true;
    this.visitorDetails = null;
    this.registraionDetail = null;
    this.errorMsg = '';
    this.appComponent.showLoader();
    this.apiService.getDataFromServer(`/reg_details/${this.registraionNo}`).subscribe(
      (res: any) => {
        this.registraionDetail = res && res.data ? res.data : null;
        this.visitorDetails = res && res.data ? res.data.visitor_payment_details : null;
        this.visitorDetails.map(res => {
          res.created_at = moment.utc(res.created_at).local().format('DD-MM-YYYY h:mm a z');
        });
        this.appComponent.hideLoader();
         this.loading = false;
      },
      err => {
        this.errorMsg = err.error.error[0].message;
         this.appComponent.hideLoader();
         this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
         this.loading = false;
      }
    );
  }


}
