import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { ConfirmationPopoverComponent } from '../confirmation-popover/confirmation-popover.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionsService } from '../services/permissions.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-items-units',
  templateUrl: './items-units.component.html',
  styleUrls: ['./items-units.component.scss']
})
export class ItemsUnitsComponent implements OnInit {

  itemUnits = [];
  form: FormGroup;
  formMode = 'Add';
  page = 1;


  constructor(
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    private appComponet: AppComponent,
    private modalService: NgbModal,
    public permissionsService: PermissionsService,
    private appComponent: AppComponent, 
    ) { }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.appComponet.showLoader();
    this.apiService.getDataFromServer(`/item_units`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.itemUnits = res.data;
        // this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }

  
  deleteItemUnits(_item){
    const modalRef = this.modalService.open(ConfirmationPopoverComponent,
      {
        centered: false, size: 'sm', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const deleteComponent: ConfirmationPopoverComponent = modalRef.componentInstance;
    deleteComponent.massage = 'Are you sure you want to delete this?';
    modalRef.result.then((result) => {
      if (result === 'success') {
        this.apiService.deleteDataFromServer('/item_units/' + _item.id).subscribe(
          res => {
            this.appComponet.showMessageAlert(res['message'], 'success');
            this.getList();
          },
          err => {
            console.log(err);
          }
        );
      }
    });
  
  }

  createForm(data?) {
    this.form = this._formBuilder.group({
      name: [data ?  data.name : '', [Validators.required]],
      abbreviation: [data ? data.abbreviation : '', [Validators.required]],
      is_fractional_value_allowed:[data ?  data.is_fractional_value_allowed : '', [Validators.required]],
      id:[data ?  data.id : '']
    });
   
  }

  close(){
    this.form = null;
  }

  addNewItems(){
    this.createForm();
    this.formMode = 'Add';
  } 

  editItem(_data){
    this.formMode = 'Edit';
    this.createForm(_data);
  }

  saveData() {
    let formObj = this.form.getRawValue();

      this.appComponent.showLoader();
      if(this.formMode == 'Add'){
        this.apiService.postDataToServer('/item_units' , formObj).subscribe(
          res => {
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(res['message'], 'success');
            this.createForm();
            this.close();
            this.getList();
          },
          err => {
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
            console.log(err);
          }
        );
      } else {
        this.apiService.putDataToServer('/item_units/' + formObj.id , formObj).subscribe(
          res => {
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(res['message'], 'success');
            this.createForm();
            this.close();
            this.getList();
          },
          err => {
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
            console.log(err);
          }
        );
      }
      
  }

}
