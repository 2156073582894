
<div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header" >
  
          <div class="filter-wrapper field_space" *ngIf = "form">
            <form   [formGroup]="form" novalidate (ngSubmit)="saveData()">
              <div class="form-group col-12-div">

                <div class="form-group col-3-div">
                    <label >Role Name*</label>
                    <input readonly class="form-control border-primary"  formControlName="role_name" name="role_name" required>
                </div>

                <div class="form-group col-3-div">
                    <label>Permissions*</label>
                    <angular2-multiselect [data]="allPermission" formControlName="permission_ids"
                     [settings]="{singleSelection: false,
                              text:'Select Permission' ,
                              enableSearchFilter: true,
                              labelKey:'name',
                              badgeShowLimit: 2,
                              primaryKey:'id'}"
                              >
                    <c-badge>
                      <ng-template let-item="item">
                        <label>{{item.name}}</label>
                      </ng-template>
                    </c-badge>
                  </angular2-multiselect>
                  </div>
<!--   
                <div class="form-group col-4-div">
                  <label >Permission*</label>
                  <select class="form-control border-primary"  name="permission_ids" formControlName="permission_ids">
                    <option [value]="''">select</option>
                    <option *ngFor="let data of allPermission" [value]="data.id">{{data.name}}</option>
                  </select>
                  <div class="" *ngIf="form.controls.permission_ids.touched && !form.controls.permission_ids.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.permission_ids.errors.required">Permission is required!</div>
                  </div>
                </div> -->
  
              </div>
              
  
              ​<div class="button-wrap">
              <button  type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw">Submit</button>
              <button  type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button>
            </div>
  
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  