import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { saveAs } from 'file-saver';
import { ThrowStmt } from '@angular/compiler';
import { PermissionsService } from '../services/permissions.service';
import { SortListService } from '../services/sort-list.service';


@Component({
  selector: 'app-payment-summary-report',
  templateUrl: './payment-summary-report.component.html',
  styleUrls: ['./payment-summary-report.component.scss']
})
export class PaymentSummaryReportComponent implements OnInit {

  
  public payment_report: any;
  public paymentSummary: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  public payment_status = 'Pending';
  public onChangeSearch = new Subject<string>();
  public perPage = 20;
  public totalCount = 0;
  public page = 1;
  public allDarshanTypes = [];
  public darshan_type_id = '';
  public maxDate =  ''; //moment(new Date()).format('YYYY-MM-DD');
  public department_id = '';
  public allReferences = [];
  public referred_by_id = '';




  constructor(
    private apiService: ApiService,
    private appComponet: AppComponent,
    public permissionsService: PermissionsService,
    private sortListService: SortListService,
    private appComponent: AppComponent, 
    ) { }

  ngOnInit() {
    this.getList('');
    this.getAllDarshanTypes();
    this.getAllReferences();
  }

  getList(_type) {
    this.appComponet.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/payment_summary?date=${from_date}&referred_by_id=${this.referred_by_id}&darshan_type_id=${this.darshan_type_id}&end_date=${to_date}`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.paymentSummary = res;
        this.paymentSummary.data.map(res => {
          res.date = moment.utc(res.date).local().format('DD-MM-yyyy');
        });
      },
      err => {
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        this.appComponet.hideLoader();
        console.log(err);
      }
    );
  }

  getAllReferences() {
    this.referred_by_id = '';
    this.apiService.getDataFromServer(`/references?darshan_type_id=${this.darshan_type_id}`).subscribe(
      res => {
        // this.allReferences = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allReferences = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }



  getAllDarshanTypes() {
    this.apiService.getDataFromServer('/darshan_types').subscribe(
      res => {
        // this.allDarshanTypes = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allDarshanTypes = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

}