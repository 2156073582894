
<div *ngIf="ribbonShow" id="popup-overlay-launch">


  <div id="ribbon">
      <div class="ribbon bow"></div>
      <div class="ribbon ribbon--l"></div>
      <div class="ribbon ribbon--r"></div>
     
  </div>
      
  <img id="imgFollow" style="position: relative;" class="imgFollow" src="../../assets/images/scissors-4741_256.gif" />
      
</div>
<div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
         
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Bhasmarti Waiting Requests List</div>
              <!-- <div class="p-2 flex-shrink-1 bd-highlight searchValue">
                  <input  class="form-control border-primary margin-top-search" [(ngModel)] = "searchValue" 
                  placeholder="Search here" (ngModelChange) = "changeSearchValue()" name="name" >
                </div> -->
            </div>
          </h4>
          <div class="table-responsive" *ngIf="allWaitingRequests && allWaitingRequests.length">
            <table class="table main-table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Gender</th>
                <th>Aadhar Number</th>
                <th>Created At</th>
                <th>Image</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of allWaitingRequests |slice: (page-1) * 20 : (page-1) * 20 + 20; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>{{data?.name}}</td>
                <td>{{data.gender}}</td>
                <td>{{data?.aadhar_number}}</td>
                <td>{{data?.created_at}}</td>
                <td *ngIf = "data?.image_url"><img style="cursor: pointer;" (click) = "viewImage(data?.image_url)" src = "{{data?.image_url}}"></td>

              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="!allWaitingRequests.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
          <ngb-pagination  *ngIf="allWaitingRequests.length" [collectionSize]="totalCount" [pageSize]="perPage"
          [(page)]="page" [maxSize]="3"
          class="pull-right justify-content-end page-mt-10"
          aria-label="Default pagination"></ngb-pagination>
        </div>  
      </div>
    </div>
  </div>