<div class="modal-content modal-content">
    <div class="modal-header py-1">
        <h4 class="modal-title">Payment Status</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" style="font-size: 40px !important;">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-0">
        <div *ngIf="loader"  style="position: initial !important; height: 300px; background: transparent !important;" class="loader-wrap">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="text-center" style="width: 100%" *ngIf = "!loader && paymentDetail">
            <i *ngIf = "paymentDetail?.spRespStatus == 'SUCCESS'"  style="font-size: 100px;margin: 20px" class="mdi mdi-check-circle-outline"></i>
            <i *ngIf = "paymentDetail?.spRespStatus == 'PENDING'" style="font-size: 100px;margin: 20px" class="fa fa-exclamation-circle"></i>
            <i *ngIf = "paymentDetail?.spRespStatus != 'SUCCESS' && paymentDetail?.spRespStatus != 'PENDING'" style="font-size: 100px;margin: 20px" class="fa fa-times-circle"></i>
              <h3 style="margin: 20px"  *ngIf = "paymentDetail?.spRespStatus != 'SUCCESS'">PAYMENT {{paymentDetail?.spRespStatus}}!</h3>
              <h3 style="margin: 20px"  *ngIf = "paymentDetail?.spRespStatus == 'SUCCESS'">Payment Status Updated Successfully</h3>
              <p *ngIf = "paymentDetail?.spRespStatus == 'SUCCESS'"
               style="border: 1px solid; width: 80%;margin: 0 auto; padding: 30px">
               {{paymentDetail?.firstName}} {{paymentDetail?.lastName}}. We have received your payment of ₹{{paymentDetail?.amount}}.
              </p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-inverse-primary" (click)="closeModal()">
            Okay
        </button>
    </div>
</div>