
  <div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
            <div class="row">
                <div class="form-group col-1.5 ml-2 mr-2">
                  <label>Date</label>
                  <input class="form-control border-primary"  [owlDateTime]="dt" placeholder="Date" name="from_date"
                    [(ngModel)]="from_date" readonly>
                  <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                  <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-1.5  mr-2">
                  <label >Darshan Type</label>
                  <select class="form-control border-primary" name="darshan_type" [(ngModel)]="darshan_type">
                    <option [value]="''">Select</option>
                    <option [value]="'Protocol Darshan'">Protocol Darshan</option>
                    <option [value]="'Bhasmaarti'">Bhasma Arti</option>
                  </select>
                </div>
                <div class="form-group col-1.5">
                  <button type="button" style="margin-top: 30px;" class="btn btn-inverse-primary btn-fw top-btn-head" (click)="getList($event)">Load</button>
                </div>
              </div>
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Daily Quota</div>
              <div class="p-2 bd-highlight"  *ngIf = "permissionsService.checkPermission('export_daily_quota_report')"><button  type="btn"  class="btn btn-primary btn-fw" (click) = "export()">Export</button>
              </div>
              <!-- <div class="p-2 bd-highlight"><button  type="btn"  class="btn btn-primary btn-fw" (click) = "export()">Export</button>
              </div> -->
            </div>
          </h4>
          <div class="table-responsive"  *ngIf="payment_report && payment_report.data && payment_report.data.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Darshan Type</th>
                <th>Deprtment</th>
                <th>Reference Name</th>
                <th>Remaining Quota</th>
                <th>Used Quota</th>
                <th>Re Assign Quota</th>
                <th>Total Quota</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of payment_report?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>{{data?.date}}</td>
                <td>
                  <span *ngIf = "data?.darshan_type">{{data?.darshan_type}}</span>
                </td>
                <td>
                    <span *ngIf = "data?.deprtment_name">{{data?.deprtment_name}}</span>
                </td>
                <td>
                  <span *ngIf = "data?.reference_name">{{data?.reference_name}}</span>
               </td>
                <td>{{data?.remaining_quota}}</td>
                <td>{{data?.used_quota}}</td>
                <td>{{data?.re_assign_quota}}</td>
                <td>{{data?.total_quota}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="payment_report && payment_report.data && !payment_report.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
          <!-- <ngb-pagination  *ngIf="payment_report && payment_report.data && payment_report.data.length" [collectionSize]="totalCount" [pageSize]="perPage"
          [(page)]="page" [maxSize]="3" (pageChange)="getList('pagination')"
          class="pull-right justify-content-end page-mt-10"
          aria-label="Default pagination"></ngb-pagination> -->
        </div>
      </div>
    </div>
  </div>

