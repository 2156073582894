import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { PermissionsService } from '../services/permissions.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationPopoverComponent } from '../confirmation-popover/confirmation-popover.component';

@Component({
  selector: 'app-news-and-events-list',
  templateUrl: './news-and-events-list.component.html',
  styleUrls: ['./news-and-events-list.component.scss']
})
export class NewsAndEventsListComponent implements OnInit {
  
  newsEventsList = [];
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  public perPage = 10;
  public totalCount = 0;
  public page = 1;
  public maxDate = ''; // moment(new Date()).format('YYYY-MM-DD');

  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent,
    public permissionsService: PermissionsService,
    private modalService: NgbModal
    ) { }

  ngOnInit() {
    this.getList('');
  }

  getList(_type) {
    this.appComponent.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/news_and_events?from_date=${from_date}&to_date=${to_date}&page=${this.page}&perpage=${this.perPage}`).subscribe(
      (res: any) => { 
        this.appComponent.hideLoader();
        this.newsEventsList = res.data;
        this.newsEventsList.map(res => {
          // res.created_at = moment.utc(res.created_at).local().format('DD-MM-YYYY h:mm a z');
          res.date = moment.utc(res.date).local().format('DD-MM-YYYY');
        });
        this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }

  deleteNewsEvent(_item){
    const modalRef = this.modalService.open(ConfirmationPopoverComponent,
      {
        centered: false, size: 'sm', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const deleteComponent: ConfirmationPopoverComponent = modalRef.componentInstance;
    deleteComponent.massage = 'Are you sure you want to delete this?';
    modalRef.result.then((result) => {
      if (result === 'success') {
        this.apiService.deleteDataFromServer('/news_and_events/' + _item.id).subscribe(
          res => {
            this.appComponent.showMessageAlert(res['message'], 'success');
            this.getList('');
          },
          err => {
            console.log(err);
          }
        );
      }
    });
  
  }



}