import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {AppComponent} from '../app.component';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { SortListService } from '../services/sort-list.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CapturePhotoPopoverComponent } from '../capture-photo-popover/capture-photo-popover.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {
  public env: any = environment;


  form: FormGroup;
  allDarshanTypes = [];
  allStates = [];
  allCities = [];
  allReferences = [];
  minDate = moment(new Date()).format('YYYY-MM-DD');
  maxDate = ''
  timeDisable = false;
  formMode = 'Add';
  visitors_basic_details: any;
  dateErrorMsg = '';
  visitor_type = [];
  id_proof_type_list = []
  allDepartments = [];
  show_reference_limit_msg = '';
  user_roles:any;
  selecedType = "bhasma_arti";
  addImageUrl = [];
  image_required = false;
  selectedElement:any;
  garbhGrahSlot = '';

  myFilter = (d): boolean => {
    const time = d.get('hour');
    if(time >= 6 && time <= 22){
      this.timeDisable = false;
      return true;
    } else {
      this.timeDisable = true;
      return false;
    }
  }
  apiLoadInterval = null;

  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private appComponent: AppComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sortListService: SortListService,
    private modalService: NgbModal,

    ) {
      this.getAllDepartment();
    }

  ngOnInit() {
    this.user_roles = JSON.parse(localStorage.getItem('loginDetails')).roles.length ? JSON.parse(localStorage.getItem('loginDetails')).roles[0]: '';
    const id = this.activatedRoute.snapshot.params['id'];
    this.getAllStates();
    this.getAllDarshanTypes();
    this.getIdProofTypesList();
    // this.getAllVisitorTypes();
    if(id) {
      this.formMode =  'Edit';
      this.getRegistrationDetail(id);
    } else {
      this.createForm();
    }
  }




  createForm(data?) {
    this.selectedElement = data;
    this.form = this._formBuilder.group({
      first_name: [data ? data.first_name : '', [Validators.required,this.removeSpaces]],
      last_name: [data ? data.last_name : '', [Validators.required,this.removeSpaces]],
      mobile_no: [data ? data.mobile_no : '', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      // email: [data ? data.email : '', [Validators.required, Validators.email]],
      date_of_visit: [data ? data.date_of_visit : '', [Validators.required]],
      time_of_visit: [data ? moment(data.time_of_visit, 'hh:mm').format(): '', [Validators.required]],
      darshan_type_id: [data ? data.darshan_type_id: '', [Validators.required]],
      state_id: [data ? data.state_id || '': '', [Validators.required]],
      city_id: [data ? data.city_id || '': '', [Validators.required]],
      referred_by_id: [data ? data.referred_by_id: '', data && data.darshan_type_id == 3 ? []:[Validators.required]],
      reference_person_name: [data ? data.reference_person_name: ''],
      department_id: [data ? data.department_id: '', data && (data.darshan_type_id == 3  || data.darshan_type_id == 14)? [] : [Validators.required]],
      no_of_persons: [data ? data.no_of_persons: '1', [Validators.required]],
      id: [data ? data.id: ''],
      token_number: [data ? data.token_number: ''],
      is_exempt: [data ? data.is_exempt: ''],
      nandi_hall_darshan: [data ? data.nandi_hall_darshan: ''],
      id_proof_type_id : [data && data.darshan_type_id == 2 ? data.visitor_basic_details[0].id_proof_type_id: '', data && data.darshan_type_id == 2 ? [Validators.required] : []],
      id_proof_number  : [data && data.darshan_type_id == 2 ? data.visitor_basic_details[0].id_proof_number : '', data && data.darshan_type_id == 2 ? [Validators.required ,this.removeSpaces] : []],
      // alternate_mobile_no: [data ? data.alternate_mobile_no : ''],
      visitors_basic_details: this._formBuilder.array([this.getPersonRow()])
    });
    if(data) {
      this.selecedType = data.darshan_type_id == 2 ? 'protocal_darsan': 'bhasma_arti';
      this.onChangeDepartment('onLoad');
      this.getAllDepartment(this.form.value.darshan_type_id);
      this.onSelectReferredBy();
      this.checkDepartmentBySlug('district_protocol',data);
    }
  }

  checkEntryType() {
    if(this.selectedElement) {
      return this.selectedElement.entry_type == 'Public Domain' ? false : true;

    }else {
      return true
    }
  }


  getPersonRow() {
    return this._formBuilder.group({
      name: ['', [Validators.required]],
      // visitor_type_ids: [''],
      // age: ['', [Validators.required,Validators.min(0),Validators.max(100)]],
      gender: ['', Validators.required],
      id_proof_type_id : ['', Validators.required],
      id_proof_number  : ['', [Validators.required,this.removeSpaces]],
      image_url: ['']
    });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }

  getRegistrationDetail(id) {
    this.apiService.getDataFromServer(`/visitors/${id}`).subscribe(
      (res:any) => {
        this.createForm(res.data);
        this.getAllCities(res.data.state_id);
        this.getAllReferences(res.data.darshan_type_id);
        this.visitors_basic_details = res.data.visitor_basic_details;
        this.onPersonCountChange('onLoad');
      },
      err => {
        console.log(err);
      }

    );
  }



  onChangeDepartment(type?) {
    if(!type && this.form.controls['darshan_type_id'].value != 3) {
      this.form.controls['referred_by_id'].setValue('');
      this.form.controls['reference_person_name'].setValue('');
    }

    if(this.form.controls['department_id'].value == 4 || this.form.controls['department_id'].value == 5) {
      this.form.controls['reference_person_name'].setValue('');
      this.form.controls['reference_person_name'].clearValidators();
      this.form.controls['referred_by_id'].setValidators([Validators.required]);
    } else {
      if(this.form.controls['darshan_type_id'].value != 3) {
        if(!this.checkDepartmentBySlug('district_protocol')){
          this.form.controls['referred_by_id'].setValue('');
          this.form.controls['referred_by_id'].clearValidators();
          this.form.controls['reference_person_name'].setValidators([Validators.required,this.removeSpaces]);
        }
      }
    }

    if(this.form.controls['darshan_type_id'].value == 14) {
      this.form.controls['reference_person_name'].setValue('');
      this.form.controls['reference_person_name'].clearValidators();
    }

    if((this.checkDepartmentId(this.form.controls['department_id'].value) == 'general_bhasmaarti' || this.checkDepartmentId(this.form.controls['department_id'].value) == 'general_bhasmaarti_paid')) {
      if(!this.checkDepartmentBySlug('district_protocol')){
        this.form.controls['referred_by_id'].setValue('');
        this.form.controls['reference_person_name'].setValue('');
      }
      this.form.controls['referred_by_id'].clearValidators();
      this.form.controls['reference_person_name'].clearValidators();
    }



    this.form.controls['reference_person_name'].updateValueAndValidity();
    this.form.controls['referred_by_id'].updateValueAndValidity();

    this.getAllReferences(this.form.value.darshan_type_id);
    if(((this.form.controls['department_id'].value == 4 || this.form.controls['department_id'].value == 5 || !this.form.controls['department_id'].value) && this.form.controls['darshan_type_id'].value != 3) && (this.checkDepartmentId(this.form.controls['department_id'].value) != 'general_bhasmaarti_paid' && this.checkDepartmentId(this.form.controls['department_id'].value) != 'general_bhasmaarti')) {
    }else {
      if(!type) {
        this.check_Reference_Limit();
      }
    }
    this.checkImageConfig();
    if(this.selectedElement && this.selectedElement.entry_type == 'Public Domain'){
      setTimeout(() => {
        this.form.controls['reference_person_name'].clearValidators();
        this.form.controls['referred_by_id'].clearValidators();
        this.form.controls['reference_person_name'].updateValueAndValidity();
        this.form.controls['referred_by_id'].updateValueAndValidity();
      }, 1000);

    }

  }

  onChangeName() {
    const controlArray = <FormArray> this.form.get('visitors_basic_details');
    if(controlArray.controls && controlArray.controls.length) {
      controlArray.controls[0].get('name').setValue(`${this.form.value.first_name} ${this.form.value.last_name}`);
    }
  }

  onIdProofTypeChange(index) {
    const controlArray = <FormArray> this.form.get('visitors_basic_details');
    if(controlArray.controls && controlArray.controls.length) {
      controlArray.controls[index].get('id_proof_number').setValue('');
    }
  }

  onIdProofTypeChangeSingle() {
    this.form.controls['id_proof_number'].setValue('');
  }

  getAllDarshanTypes() {
    this.apiService.getDataFromServer('/darshan_types').subscribe(
      res => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allDarshanTypes = res;
        });
        // this.allDarshanTypes = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }

  check_Reference_Limit(type?) {
    this.show_reference_limit_msg = '';
    if(!type || (type &&  this.form.value.referred_by_id)) {
      if(this.form.value.darshan_type_id) {
        const data: any = {
          "darshan_type_id": this.form.value.darshan_type_id,
          "date_of_visit": moment(this.form.value.date_of_visit).format('YYYY-MM-DD'),
          "no_of_persons": this.form.value.no_of_persons,
          "referred_by_id": this.form.value.referred_by_id,
          "department_id": this.form.value.department_id,
          "visitor_id": this.form.value.id
          }
        this.apiService.postDataToServer('/check_reference_limit',data).subscribe(
          (res:any) => {
            this.show_reference_limit_msg = res.message == 'Reference limit available.' ? '' : res.message;
          },
          err => {
            console.log(err);
          }
        );
      }
    }

  }

  getAllDepartment(Id?) {
    const id = Id ? Id : '';
    this.apiService.getDataFromServer(`/departments?darshan_type_id=${id}`).subscribe(
      res => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allDepartments = res;
        });
        // this.allDepartments = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllVisitorTypes() {
    this.apiService.getDataFromServer('/visitor_types').subscribe(
      res => {
        this.visitor_type = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }

  checkImageConfig() {
    const data = {
      department_id: this.form.value.department_id,
      darshan_type_id: this.form.value.darshan_type_id
    }
    this.apiService.postDataToServer('/check_image_config', data).subscribe(
      (res:any) => {
        this.image_required = res.image_required;
        const controlArray = <FormArray> this.form.get('visitors_basic_details');
        if(this.image_required) {
          if(controlArray.controls && controlArray.controls.length && this.form.value.darshan_type_id != 3) {
            this.form.value.visitors_basic_details.map((res, index) => {
              controlArray.controls[index].get('image_url').setValidators([Validators.required]);
              controlArray.controls[index].get('image_url').updateValueAndValidity();
            });
          }
        } else {
          if(controlArray.controls && controlArray.controls.length) {
            this.form.value.visitors_basic_details.map((res, index) => {
              if ((this.form.value.darshan_type_id == 3 && index != 0) || this.form.value.darshan_type_id != 3) {
                controlArray.controls[index].get('image_url').clearValidators();
                controlArray.controls[index].get('image_url').updateValueAndValidity();
              }
            });
          }
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllStates() {
    this.apiService.getDataFromServer('/states').subscribe(
      res => {
        // this.allStates = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allStates = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllCities(_id) {
    this.apiService.getDataFromServer('/cities/?state_id=' + _id).subscribe(
      res => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allCities = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getIdProofTypesList() {
    this.apiService.getDataFromServer('/id_proof_types').subscribe(
      res => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.id_proof_type_list = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllReferences(_id) {
    this.apiService.getDataFromServer(`/references?darshan_type_id=${_id}&department_id=${this.form.value.department_id}`).subscribe(
      res => {
        // this.allReferences = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allReferences = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  checkDepartmentId(id) {
    const department:any = this.allDepartments.find(res =>  res.id == id);
      return  department && department.slug ?  department.slug : null
  }

  checkDepartmentBySlug(slug,data?) {
    const department:any = this.allDepartments.find(res =>  res.slug == slug && res.id == this.form.value.department_id);
      if(department && data && !this.form.value.referred_by_id){
         this.form.controls['referred_by_id'].setValue('other');
      }
      return  department ?  true : false;
  }

  checkDarshanTypeId(id) {
    const darshanType:any = this.allDarshanTypes.find(res =>  res.id == id);
    return  darshanType && darshanType.slug ?  darshanType.slug : null
  }

  onClickCapturePhoto(index, data) {
    console.log(this.form)
    const modalRef = this.modalService.open(CapturePhotoPopoverComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const capturePhotoPopoverComponent: CapturePhotoPopoverComponent = modalRef.componentInstance;
    capturePhotoPopoverComponent.data = data;

    modalRef.result.then((result) => {
      if (result) {
        this.appComponent.showLoader();
        this.apiService.getBlobFileFromServer(result).subscribe(res => {
          let blob:any = new Blob([res], { type: res.type.toString() });
          const controlArray = <FormArray> this.form.get('visitors_basic_details');
          let file_name = controlArray.controls[index].get('name').value;
          const imageFile = new File([blob], `${file_name}-image`, { type: 'image/png' });
          controlArray.controls[index].get('image_url').setValue(imageFile);
          const isExist = this.addImageUrl.find((img:any) => img.index == index);

          if(isExist) {
            isExist.image_url = result;
            isExist.addStatus = true;
          } else {
            this.addImageUrl.push({index:index, image_url: result, addStatus: true});
          }
          this.appComponent.hideLoader();
        });
      }
    });
  }
  onFileChange(event:any, index:any) {
    if (event.target.files && event.target.files.length) {
      const controlArray = <FormArray> this.form.get('visitors_basic_details');
      controlArray.controls[index].get('image_url').setValue(event.target.files[0]);
      const isExist = this.addImageUrl.find((img:any) => img.index == index);

      const reader = new FileReader();
      reader.onload = (e: any) => {
        if(isExist) {
          isExist.image_url = e.target.result;
          isExist.addStatus = true;
        } else {
          this.addImageUrl.push({index:index, image_url: e.target.result, addStatus: true});
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    console.log(this.form)
  }

  findImageUrl(index) {
    const imageData = this.addImageUrl.find(res => res.index == index);
    return imageData && imageData.image_url ? imageData.image_url : '';
  }

  async saveImageData(): Promise<void>  {
    this.appComponent.showLoader();
    let data:any = null;
    let count = 0;
    let receivedCount = 0;
    if(this.image_required) {
      const visitors_basic_details = this.form.value.visitors_basic_details;
      visitors_basic_details.map(async (res, index) => {
        // await this.actulePlaceOrder(report, index, data.length);
        const isExist = this.addImageUrl.find(img => img.index == index);
        if(isExist && isExist.addStatus) {
          count = count + 1;
          data = await this.addImage(res,index);
          if(data) {
            receivedCount = receivedCount + 1
          }
          if(data && receivedCount == count) {
            this.saveData();
          }
        }

        const isExist1 = this.addImageUrl.find(img => img.addStatus == true);
        if(!isExist1) {
          this.saveData();
        }

      });
      // visitors_basic_details.map((res, index) => {
      //  const isExist = this.addImageUrl.find(img => img.index == index);
      //  if(isExist && isExist.addStatus) {
      //    this.addImage(res,index);
      //  }
      // });
      // setTimeout(() => {
      //   this.saveData();
      // }, 4000);
    } else {
      this.saveData();
    }

  }

  addImage(res,index): Promise<void>  {
    return new Promise((resolve) => {
      var formData = new FormData();
      formData.append('file', res.image_url);
      this.apiService.postDataToServer('/add_image', formData).subscribe(
        (res:any) => {
          const controlArray = <FormArray> this.form.get('visitors_basic_details');
          controlArray.controls[index].get('image_url').setValue(res.data);
          resolve(res);
        },err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        });
    });

  }



  saveData() {
    const formObj = this.form.getRawValue();

    formObj.date_of_visit = moment(formObj.date_of_visit).format('YYYY/MM/DD');
    if (formObj.darshan_type_id == 14){
      if (formObj.time_of_visit < moment().format()){
        formObj.time_of_visit = moment().add(10, 'm').format();
      }
    }
    if(formObj.time_of_visit){
      formObj.time_of_visit = moment(formObj.time_of_visit).format('HH:mm');
    } else {
      formObj.time_of_visit = formObj.time_of_visit;
    }
    if(this.form.value.referred_by_id == 'other'){
      formObj.referred_by_id = '';
    }

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postDataToServer('/visitors', formObj).subscribe(
        (res:any) => {
          this.router.navigate(['/registration-success', res['data']['id']]);
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.createForm();
          if(res.data && res.data.payment_status == 'Paid') {
            this.generateEnterPass(res.data.id);
          }
          setTimeout(() => {
            this.appComponent.hideLoader();
          },200);
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    } else {
      this.appComponent.showLoader();
      this.apiService.putDataToServer(`/visitors/${formObj.id}`, formObj).subscribe(
        res => {
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.router.navigate(['/registration-list']);
          this.createForm();
          setTimeout(() => {
            this.appComponent.hideLoader();
          }, 200);
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }

 generateEnterPass(id) {
  this.apiService.getDataFromServer('/generate_entry_pass/' + id).subscribe(
    res => {
      window.open(res['data'][0]);
    },
    err => {
      console.log(err);
    }
  );
 }

  onChangeDate() {
    const date = moment(this.form.value.date_of_visit).format('YYYY/MM/DD');
    const currentDate = moment(new Date()).format('YYYY/MM/DD');
    // if(this.form.value.darshan_type_id == 1 && date == currentDate && this.user_roles != 'general_bhasmaarti') {
    //   this.dateErrorMsg = "You can't select today date for bhasma aarti"
    // } else {
      this.dateErrorMsg = '';
    // }
    this.check_Reference_Limit();
  }

  onPersonCountChange(type?){
    this.addImageUrl = [];
    let visitors_basic_details : any  = <FormArray>this.form.controls['visitors_basic_details'];

    visitors_basic_details.clear();

    if(this.visitors_basic_details) {

      this.visitors_basic_details.map((res, index) => {
        if(this.form.controls['no_of_persons'].value >= (index + 1) ) {
          visitors_basic_details.push(this._formBuilder.group({
            name: [res.name, [Validators.required]],
            // visitor_type_ids: [res.visitor_type],
            // age: [res.age, [Validators.required, Validators.min(0),Validators.max(100)]],
            gender: [res.gender, Validators.required],
            id_proof_type_id : [res.id_proof_type_id ? res.id_proof_type_id : '' , Validators.required],
            id_proof_number  : [res.id_proof_number ? res.id_proof_number : '', Validators.required],
            id: [res.id],
            image_url: [res.image_url, this.image_required ? Validators.required : '']
          }));
          if(type) {
            if(res.image_url) {
              this.addImageUrl.push({index:index, image_url: `${res.image_url}`, addStatus: false});
            }
          }
        }

      })

      if(this.form.controls['no_of_persons'].value != this.visitors_basic_details.length) {
        for (let i=0; i< this.form.controls['no_of_persons'].value - this.visitors_basic_details.length; i++) {
          visitors_basic_details.push(this._formBuilder.group({
            name: ['', [Validators.required]],
            // visitor_type_ids: [''],
            // age: ['', [Validators.required, Validators.min(0),Validators.max(100)]],
            gender: ['', Validators.required],
            id_proof_type_id : ['', Validators.required],
            id_proof_number  : ['', Validators.required],
            image_url: ['', this.image_required ? Validators.required : '']

          }));
        }
      }

    } else {
      visitors_basic_details.push(this._formBuilder.group({
        name: [this.form.controls['first_name'].value + ' ' + this.form.controls['last_name'].value, [Validators.required]],
        // visitor_type_ids: [''],
        // age: ['', [Validators.required,Validators.min(0),Validators.max(100)]],
        gender: ['', Validators.required],
        id_proof_type_id : ['', Validators.required],
        id_proof_number  : ['', Validators.required],
        image_url: ['', this.image_required ? Validators.required : '']
      }));

      let darshan_type_id = this.form.controls['darshan_type_id'].value;
        for (let i=0; i< this.form.controls['no_of_persons'].value -1 ; i++) {
          visitors_basic_details.push(this._formBuilder.group({
            name: ['', [Validators.required]],
            // visitor_type_ids: [''],
            // age: ['', [Validators.required, Validators.min(0),Validators.max(100)]],
            gender: ['', Validators.required],
            id_proof_type_id : ['', darshan_type_id != 3 ? [Validators.required] : ''],
            id_proof_number  : ['', darshan_type_id != 3 ? [Validators.required] : ''],
            image_url: ['',  this.image_required && darshan_type_id != 3 ? [Validators.required] : '']
          }));

        }
    }
    if(!type && this.selecedType == 'protocal_darsan') {
      this.onChangeProofNumber();
    }
    if(!type) {
      this.check_Reference_Limit();
    }
    if (this.form.controls['darshan_type_id'].value == 14){
      this.getNextGarbhGrahSlot();
    }

    if (this.form.controls['darshan_type_id'].value == 15){
      this.getNextJalArpanSlot();
    }
  }

  onStateChange(_value){
    this.getAllCities(_value.target.value);
  }

  onChangeProofNumber() {
    this.form.value.visitors_basic_details.map((res:any , index) => {
      const controlArray = <FormArray> this.form.get('visitors_basic_details');
        controlArray.controls[index].get('id_proof_type_id').setValue(this.form.value.id_proof_type_id);
        controlArray.controls[index].get('id_proof_number').setValue(this.form.value.id_proof_number);
    });
  }
  onChangePeopleProofNumber(i) {
    const controlArray = <FormArray> this.form.get('visitors_basic_details');
    if (controlArray.controls[i].get('id_proof_number') && controlArray.controls[i].get('id_proof_number').value && !controlArray.controls[i].get('id_proof_number').value.replace(/\s/g, '').length) {
      controlArray.controls[i].get('id_proof_number').setValue('');
    }
  }

  onChangePeopleName(i) {
    const controlArray = <FormArray> this.form.get('visitors_basic_details');
    if (controlArray.controls[i].get('name') && controlArray.controls[i].get('name').value && !controlArray.controls[i].get('name').value.replace(/\s/g, '').length) {
      controlArray.controls[i].get('name').setValue('');
    }
  }

  onChangeReferredBy() {
    if (this.form.controls['reference_person_name'] && this.form.controls['reference_person_name'].value && !this.form.controls['reference_person_name'].value.replace(/\s/g, '').length) {
      this.form.controls['reference_person_name'].setValue('');
    }
  }

  onSelectReferredBy(){
    if(this.form.value.referred_by_id != 'other' && this.checkDepartmentBySlug('district_protocol')){
      this.form.controls['reference_person_name'].clearValidators();
      this.form.controls['reference_person_name'].updateValueAndValidity();
    }

    if(this.form.value.referred_by_id == 'other' && this.checkDepartmentBySlug('district_protocol')){
      this.form.controls['reference_person_name'].setValidators([Validators.required]);
      this.form.controls['reference_person_name'].updateValueAndValidity();
    }
  }

  onDarshanChange(_value?) {
    const date = new Date();
    if(_value) {
      this.getAllReferences(_value.target.value);
      this.getAllDepartment(_value.target.value);
    }
    this.form.controls['referred_by_id'].setValue('');
    if(_value.target.value == 1) {
      this.selecedType = "bhasma_arti";
      date.setDate( date.getDate() + 1 );
      this.form.controls['date_of_visit'].setValue(date);
      this.form.controls['time_of_visit'].setValue('');
      this.form.controls['time_of_visit'].clearValidators();

      this.form.controls['id_proof_type_id'].setValue('');
      this.form.controls['id_proof_type_id'].clearValidators();
      this.form.controls['id_proof_number'].setValue('');
      this.form.controls['id_proof_number'].clearValidators();
      this.onChangeDate();
    } else {
      var futureTime = new Date(date.getTime() + 15*60000);
      this.form.controls['date_of_visit'].setValue(date);
      this.form.controls['time_of_visit'].setValue(moment(futureTime, 'hh:mm').format());

      this.form.controls['time_of_visit'].setValidators([Validators.required]);
      this.dateErrorMsg = '';
    }

    if(_value.target.value == 2) {
      this.selecedType = "protocal_darsan";
      this.form.controls['id_proof_type_id'].setValidators([Validators.required]);
      this.form.controls['id_proof_number'].setValidators([Validators.required, this.removeSpaces]);
    }

    if(_value.target.value == 3) {
      this.selecedType = "bhasma_arti";
      this.form.controls['department_id'].setValue('');
      this.form.controls['department_id'].clearValidators();
      this.form.controls['referred_by_id'].setValue('');
      this.form.controls['referred_by_id'].clearValidators();
      this.form.controls['state_id'].clearValidators();
      this.form.controls['city_id'].clearValidators();
      this.form.controls['id_proof_type_id'].setValue('');
      this.form.controls['id_proof_number'].setValue('');
      this.form.controls['id_proof_type_id'].clearValidators();
      this.form.controls['id_proof_number'].clearValidators();
      this.form.controls['reference_person_name'].clearValidators();
   
    } else {
      this.form.controls['state_id'].setValidators([Validators.required]);
      this.form.controls['city_id'].setValidators([Validators.required])
      if (_value.target.value == 14){
        this.form.controls['department_id'].clearValidators();
        this.form.controls['referred_by_id'].clearValidators();
        this.form.controls['reference_person_name'].clearValidators();
        this.getNextGarbhGrahSlot();
      } else if (_value.target.value == 15){
        this.form.controls['department_id'].setValidators([Validators.required]);
        this.form.controls['referred_by_id'].clearValidators();
        this.form.controls['reference_person_name'].clearValidators();
        this.form.controls['referred_by_id'].setValidators([Validators.required]);
        this.getNextJalArpanSlot();
      } else {
        this.form.controls['department_id'].setValidators([Validators.required]);
        if (this.form.controls['department_id'].value == 4 || this.form.controls['department_id'].value == 5 || this.form.controls['department_id'].value == '') {
          this.form.controls['referred_by_id'].setValidators([Validators.required]);
        } else {
          this.form.controls['reference_person_name'].setValidators([Validators.required, this.removeSpaces]);
        }
      }
    }

    // if(this.user_roles == 'general_bhasmaarti') {
    //   const todayDate = moment(new Date).format('YYYY-MM-DD')
    //   this.form.controls['date_of_visit'].setValue(todayDate);
    //   //this.maxDate = moment(todayDate).format('YYYY-MM-DD');
    //   this.dateErrorMsg = '';
    // }
    this.form.controls['reference_person_name'].updateValueAndValidity();
    this.form.controls['department_id'].updateValueAndValidity();
    this.form.controls['referred_by_id'].updateValueAndValidity();
    this.form.controls['time_of_visit'].updateValueAndValidity();
    this.form.controls['id_proof_type_id'].updateValueAndValidity();
    this.form.controls['id_proof_number'].updateValueAndValidity();
    this.form.controls['state_id'].updateValueAndValidity();
    this.form.controls['city_id'].updateValueAndValidity();
    this.checkImageConfig();
    this.check_Reference_Limit();
    if(this.selectedElement && this.selectedElement.entry_type == 'Public Domain'){
      setTimeout(() => {
        this.form.controls['reference_person_name'].clearValidators();
        this.form.controls['referred_by_id'].clearValidators();
        this.form.controls['reference_person_name'].updateValueAndValidity();
        this.form.controls['referred_by_id'].updateValueAndValidity();
      }, 1000);
    }

    let darshan_type_id = this.form.controls['darshan_type_id'].value;
    let visitors_basic_details : any  = <FormArray>this.form.controls['visitors_basic_details'];

    for (let i=0; i< this.form.controls['no_of_persons'].value ; i++) {
         if (darshan_type_id == 3){
           if (i == 0) {
             visitors_basic_details['controls'][i]['controls']['id_proof_type_id'].setValidators([Validators.required]);
             visitors_basic_details['controls'][i]['controls']['id_proof_type_id'].updateValueAndValidity();
             visitors_basic_details['controls'][i]['controls']['id_proof_number'].setValidators([Validators.required]);
             visitors_basic_details['controls'][i]['controls']['id_proof_number'].updateValueAndValidity();
             visitors_basic_details['controls'][i]['controls']['image_url'].setValidators([Validators.required]);
             visitors_basic_details['controls'][i]['controls']['image_url'].updateValueAndValidity();
           } else {
             visitors_basic_details['controls'][i].get('id_proof_type_id').setValue('');
             visitors_basic_details['controls'][i].get('id_proof_number').setValue('');
             visitors_basic_details['controls'][i].get('id_proof_type_id').clearValidators();
             visitors_basic_details['controls'][i].get('id_proof_number').clearValidators();
             visitors_basic_details['controls'][i].get('image_url').clearValidators();
             visitors_basic_details['controls'][i].get('id_proof_type_id').updateValueAndValidity();
             visitors_basic_details['controls'][i].get('id_proof_number').updateValueAndValidity();
             visitors_basic_details['controls'][i].get('image_url').updateValueAndValidity();
           }
         } else {
           if (this.image_required){
             visitors_basic_details['controls'][i]['controls']['id_proof_type_id'].setValidators([Validators.required]);
             visitors_basic_details['controls'][i]['controls']['id_proof_type_id'].updateValueAndValidity();
             visitors_basic_details['controls'][i]['controls']['id_proof_number'].setValidators([Validators.required]);
             visitors_basic_details['controls'][i]['controls']['id_proof_number'].updateValueAndValidity();
             visitors_basic_details['controls'][i]['controls']['image_url'].setValidators([Validators.required]);
             visitors_basic_details['controls'][i]['controls']['image_url'].updateValueAndValidity();
           } else {
             visitors_basic_details['controls'][i].get('id_proof_type_id').clearValidators();
             visitors_basic_details['controls'][i].get('id_proof_number').clearValidators();
             visitors_basic_details['controls'][i].get('image_url').clearValidators();
             visitors_basic_details['controls'][i].get('id_proof_type_id').updateValueAndValidity();
             visitors_basic_details['controls'][i].get('id_proof_number').updateValueAndValidity();
             visitors_basic_details['controls'][i].get('image_url').updateValueAndValidity();
           }
         }
    }
  }

  getNextGarbhGrahSlot() {
    this.garbhGrahSlot =  '';
    let _data = {
      "no_of_persons" : this.form.controls['no_of_persons'].value
    }
    this.apiService.postDataToServer('/next_garbh_grah_slot', _data).subscribe(
      (res:any) => {
         if (res.data && res.data.from_time) {
           this.form.controls['time_of_visit'].setValue(moment(res.data.from_time, 'HH:mm:ss').format());
           this.garbhGrahSlot = `Next Slot Available is ${moment(res.data.from_time, 'HH:mm:ss').format('hh:mm A')} - ${moment(res.data.to_time, 'HH:mm:ss').format('hh:mm A')}`;
          clearTimeout(this.apiLoadInterval);
           this.apiLoadInterval = setTimeout(() => {
             this.getNextGarbhGrahSlot();
           },20000);

         }
      },
      err => {
        console.log(err.error);
        this.form.controls['time_of_visit'].setValue('');
        this.garbhGrahSlot = err.error.message;
        clearTimeout(this.apiLoadInterval);
        this.apiLoadInterval = setTimeout(() => {
          this.getNextGarbhGrahSlot();
        },20000);

      }
    );
  }

  getNextJalArpanSlot() {
    this.garbhGrahSlot =  '';
    let _data = {
      "no_of_persons" : this.form.controls['no_of_persons'].value
    }
    this.apiService.postDataToServer('/next_jal_arpan_slot', _data).subscribe(
      (res:any) => {
         if (res.data && res.data.from_time) {
           this.garbhGrahSlot = `Next Slot Available is ${moment(res.data.from_time, 'HH:mm:ss').format('hh:mm A')} - ${moment(res.data.to_time, 'HH:mm:ss').format('hh:mm A')}`;
          clearTimeout(this.apiLoadInterval);
           this.apiLoadInterval = setTimeout(() => {
             this.getNextJalArpanSlot();
           },20000);

         }
      },
      err => {
        console.log(err.error);
        this.garbhGrahSlot = err.error.message;
        clearTimeout(this.apiLoadInterval);
        this.apiLoadInterval = setTimeout(() => {
          this.getNextJalArpanSlot();
        },20000);

      }
    );
  }

  public remove(i: number) {
    this.form.get('no_of_persons').setValue(this.form.value.no_of_persons - 1);
    const contrls = <FormArray>this.form.controls['visitors_basic_details'];
      contrls.removeAt(i);
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  close() {
    this.router.navigate(['/registration-list']);
  }

}
