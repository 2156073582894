
<div class="modal-header py-1">
    <h5 class="modal-title">Assign User</h5>
    <button type="button" 
      class="close" 
      aria-label="Close" 
      (click)="activeModal.close('Close click')" 
      [disabled]="loading">
      <span aria-hidden="true">&times;</span>
    </button> 
  </div>
  <div class="modal-body field_space">
  <form [formGroup]="form">
      <div class="row">
        <div class="form-group col-6">
            <label>Role</label>
            <input type="text" class="form-control form-control-sm" readonly formControlName="role_name">
          </div>
          <div class="form-group col-6">
            <label>User</label> <br>
            <select class="form-select" style="width: 100%;" formControlName="user_id" aria-label="Default select example">
                <option value="">Select User</option>
                <option [value]="user.id" *ngFor = "let user of userList">{{user.username}}</option>
             </select>
          </div>
      </div>
  </form>
  </div>
  <div class="modal-footer">
    <button  type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw" (click) = "submit()">Submit</button>
    <button 
      type="button" 
      class="btn btn-secondary" 
      (click)="closeModal()">
      Cancel
  </button>
  </div>
    