import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {AppComponent} from '../app.component';
import {ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-categories',
  templateUrl: './add-categories.component.html',
  styleUrls: ['./add-categories.component.scss']
})
export class AddCategoriesComponent implements OnInit {

  form: FormGroup;
  formMode = 'Add';

  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService, 
    private appComponent: AppComponent, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appComponet: AppComponent,

    ) {
   
  }

  ngOnInit() {
    const id = this.activatedRoute.snapshot.params['id'];
    if(id) {
      this.formMode =  'Edit';
      this.getCategoryDetail(id);
    } else {
      this.createForm();
    }
  }

  getCategoryDetail(id) {
    this.appComponet.showLoader();
    this.apiService.getDataFromServer(`/item_categories/${id}`).subscribe(
      (res:any) => {
        this.appComponent.hideLoader();
        this.createForm(res.data);
      },
      err => {
        console.log(err);
      }

    );
  }


  createForm(data?) {
    this.form = this._formBuilder.group({
      name: [data ?  data.name : '', [Validators.required,this.removeSpaces]],
      id:[data ?  data.id : '']
    });
   
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
  


  saveData() {
    const formObj = this.form.getRawValue();

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postDataToServer('/item_categories', formObj).subscribe(
        res => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.createForm();
          this.close();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
     else {
      this.appComponent.showLoader();
      this.apiService.putDataToServer(`/item_categories/${formObj.id}`, formObj).subscribe(
        res => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.close();
          this.createForm();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }

  close() {
    this.router.navigate(['/item-categories']);

  }

}