import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { ConfirmationPopoverComponent } from '../confirmation-popover/confirmation-popover.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-permissions-role',
  templateUrl: './permissions-role.component.html',
  styleUrls: ['./permissions-role.component.scss']
})
export class PermissionsRoleComponent implements OnInit {

  public allData: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public onChangeSearch = new Subject<string>();
  public perPage = 20;
  public totalCount = 0;
  public page = 1;


  constructor(
    private apiService: ApiService,
    private appComponet: AppComponent,
    private modalService: NgbModal,
    public permissionsService: PermissionsService


    ) { }

  ngOnInit() {
    this.getList('');
  }

  getList(_type) {
    this.appComponet.showLoader();
    this.apiService.getDataFromServer(`/roles`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.allData = res;
      },
      err => {
        console.log(err);
      }
    );
  }

}