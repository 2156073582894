import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { PermissionsService } from '../services/permissions.service';
import { SortListService } from '../services/sort-list.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  form: FormGroup;
  public allData: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public onChangeSearch = new Subject<string>();
  public perPage = 20;
  public totalCount = 0;
  public page = 1;
  public action = false;
  public allRoles = [];


  constructor(
    private apiService: ApiService,
    private appComponet: AppComponent,
    public permissionsService: PermissionsService,
    private _formBuilder: FormBuilder,
    private appComponent: AppComponent,
    private sortListService: SortListService
    ) { }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.appComponet.showLoader();
    this.apiService.getDataFromServer(`/users`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.allData = res;
      },
      err => {
        console.log(err);
      }
    );
  }

  createForm(name, id, assignRoles) {
    this.form = this._formBuilder.group({
      user_name: [name ? name : '', [Validators.required]],
      role_ids: [assignRoles ? assignRoles : '', [Validators.required]],
      user_id: [id ? id : '', [Validators.required]],
    });
    this.appComponent.hideLoader();
    this.action = true;
  }
  close() {
    this.action = false;
    this.form.reset();
  }

  onClickAssign(data) {
    this.appComponent.showLoader();
    this.getAllPermission(data.id, data.username);
  }

  getAllPermission(id, name) {
    this.apiService.getDataFromServer('/roles').subscribe(
      res => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allRoles = res;
        });
        this.getAssignPermissionRole(id , name);
      },
      err => {
        console.log(err);
      }
    );
  }

  getAssignPermissionRole(id,name) {
    this.apiService.getDataFromServer(`/roles_by_user?user_id=${id}`).subscribe(
      (res:any) => {
        let assignRoles = [];
        this.allRoles.map(role => {
         const isExist = res.data.find(obj => obj == role.slug);
         if(isExist) {
           assignRoles.push(role);
         }
        });
        this.createForm(name , id, assignRoles)
      },
      err => {
        console.log(err);
      }
    );
  }

  saveData() {
    const formObj = this.form.getRawValue();
    let role_ids = [];
    formObj.role_ids.map(res => {
      role_ids.push(res.id);
    });
    formObj.role_ids = role_ids;

    if(this.action) {
      this.appComponent.showLoader();
      this.apiService.postDataToServer('/assign_user_roles', formObj).subscribe(
        res => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.close();
          this.getList();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }


}
