<nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
    <a class="navbar-brand brand-logo">
      <img src="./../../assets/images/login_logo.jpeg" alt="logo"/>
    </a>
    <a class="navbar-brand brand-logo-mini">
      <img src="./../../assets/images/login_logo.jpeg" alt="logo"/>
    </a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-center">
    <h2 class="nav-head web_nav">Mahakal Visitor Management System </h2>
    <h2 class="nav-head mob_nav">Mahakal Visitor Management System</h2>
    <ul class="navbar-nav navbar-nav-right">

      <li class="nav-item dropdown d-none d-xl-inline-block" style="cursor: pointer;" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="UserDropdown" ngbDropdownToggle>
          <span class="profile-text"></span>
          <img class="img-xs rounded-circle" src="./../../assets/images/login_logo.jpeg" alt="Profile image">
          <b class="pl-1">{{userData.username}}</b>
        </a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" ngbDropdownMenu aria-labelledby="UserDropdown">
          <a class="dropdown-item signOut" (click)="logout()">
            Sign Out
          </a>
        </div>
      </li>
    </ul>
    <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
            (click)="toggleOffcanvas()">
      <span class="mdi mdi-menu"></span>
    </button>
  </div>
</nav>
