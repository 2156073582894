import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-popover',
  templateUrl: './confirmation-popover.component.html',
  styleUrls: ['./confirmation-popover.component.scss']
})
export class ConfirmationPopoverComponent implements OnInit {

  public massage: string;
  

  constructor(
    public activeModal: NgbActiveModal,

  ) { }

  ngOnInit() {
  }

  public closeModal() {
    this.activeModal.close('');
  }

  public delete() {
    this.activeModal.close('success');
  }

}

