import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { PermissionsService } from '../services/permissions.service';


@Component({
  selector: 'app-prasad-order-list',
  templateUrl: './prasad-order-list.component.html',
  styleUrls: ['./prasad-order-list.component.scss']
})
export class PrasadOrderListComponent implements OnInit {

  
  public prasadOrder: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public payment_status = '';
  public onChangeSearch = new Subject<string>();
  public perPage = 20;
  public totalCount = 0;
  public page = 1;
  public maxDate = moment(new Date()).format('YYYY-MM-DD');
  public prasadList = [];
  public searchValue = '';
  public orderStatusList = [];
  public orderStatusId = '';

  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent,
    public permissionsService: PermissionsService
    
    ) { }

  ngOnInit() {
    this.getList();
    this.getPrasads();
    this.onChangeSearch
    .pipe(debounceTime(1000))
    .subscribe(() => {
      this.getList();
    });
    this.getOrderStatus();
  }
  getPrasads(){
    this.apiService.getDataFromPublicServer(`/public/api/v1/prasads`).subscribe(
      (res: any) => {
        this.prasadList = res.data;
        this.appComponent.hideLoader();
      },
      err => {
        console.log(err);
      }
    );
  }

  public changeSearchValue(): void {
    this.onChangeSearch.next();
  }

  checkQuntity(row,column){
    const isExist = row.prasad_order_item_detail.find(res =>  res.prasad_id == column.id);
    if(isExist) {
      return isExist.quantity
    }else {
      return false
    }
  }

  getOrderStatus(){
    this.apiService.getDataFromServer('/order_statuses').subscribe((res:any)=> {
      this.orderStatusList = res.data;
    });
  }

  getList() {
    this.appComponent.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/prasad_orders?date=${from_date}&page=${this.page}&perpage=${this.perPage}&search=${this.searchValue}&is_paid=${this.payment_status}&order_status_id=${this.orderStatusId}`).subscribe(
      (res: any) => {
        this.prasadOrder = res;
        this.appComponent.hideLoader();
        this.prasadOrder.data.map(res => {
          res.created_at = moment.utc(res.created_at).local().format('DD-MM-YYYY');
        });
        this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }
  updateOrderStatus(event,order){
    const data = {
      prasad_order_id:order.id,
      order_status_id:order.current_status.id
    }
    this.apiService.putDataToServer('/update_order_status', data).subscribe(
      (res:any) => {
       this.getList();
        this.appComponent.showMessageAlert(res['message'], 'success');
      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }

  orderStatusMode(data){
    if(data.isModeEdit) {
      data.isModeEdit = false;
    } else {
      data.isModeEdit = true;
    }
  }

  // public export() {
  //   const from_date = moment(this.from_date).format('YYYY-MM-DD');
  //   const to_date = moment(this.to_date).format('YYYY-MM-DD');
  //   this.appComponent.showLoader();
  //   this.apiService.getDataFromServer(`/export_payment_report?date=${from_date}&end_date=${to_date}&page=1&perpage=10000`).subscribe((res: any) => {
  //     if(res.status_code === 200) {
  //       const fileArry =  res.data.split('/uploads/');
  //       this.apiService.getBlobFileFromServer(res.data).subscribe(res => {
  //         this.appComponent.hideLoader();
  //         let blob:any = new Blob([res], { type: res.type });
  //         const url = window.URL.createObjectURL(blob);
  //         let file_name = fileArry[fileArry.length - 1];
  //         saveAs(blob, file_name);
  //       }, (error) => {
  //       });
  //     } 
  //     this.appComponent.hideLoader();
  //   }, (error) => {
  //     this.appComponent.hideLoader();
  //     console.log('error' , error);
  //   });
  // }


}

