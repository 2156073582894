import { Component, OnInit } from '@angular/core';
import {ApiService} from '../services/api.service';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-registration-success',
  templateUrl: './registration-success.component.html',
  styleUrls: ['./registration-success.component.scss']
})
export class RegistrationSuccessComponent implements OnInit {

  visitor : any = {};
  visitor_id;

  constructor(private apiService: ApiService, private route: ActivatedRoute) {
    this.visitor_id = this.route.snapshot.params.id;

  }

  ngOnInit() {
    this.getRegistrationDetail();
  }

  getRegistrationDetail() {
    this.apiService.getDataFromServer('/visitors/' + this.visitor_id).subscribe(
      res => {
        this.visitor = res['data'];
        this.visitor.time_of_visit = moment(this.visitor.time_of_visit, 'HH:mm:ss').format('hh:mm A');
      },
      err => {
        console.log(err);
      }
    );
  }
}
