<div class="modal-content modal-content">
    <div class="modal-header py-1" style="margin-top: 15px; ">
      <h4 class="modal-title">Confirm Action</h4>
      <button 
        type="button" 
        class="close" 
        aria-label="Close" 
        (click)="activeModal.close('Close click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pb-0">
      <label class="deleteMsg">
          {{massage}}
        <!-- Are you sure you want to delete this record? -->
      </label>
    </div>
    <div class="modal-footer">
      <button 
        type="button" 
        class="btn btn-secondary" 
        (click)="closeModal()">
        Cancel
      </button>
      <button 
        type="button" 
        class="btn btn-danger" 
        id="confirmDeleteButtonModal" 
        (click)="delete()">
        <span>Ok</span>
      </button>
    </div>
  </div>
  