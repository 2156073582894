<div class="row" *ngIf="action">
  <div class="col-lg-12 grid-margin">
    <div class="card">
      <div class="card-body upper-header">

        <div class="filter-wrapper" *ngIf="form">
          <form [formGroup]="form" novalidate (ngSubmit)="saveData()">
            <div class="form-group col-12-div">

              <div class="form-group col-3-div">
                <label>User Name*</label>
                <input readonly class="form-control border-primary" formControlName="user_name" name="user_name"
                  required>
              </div>

              <div class="form-group col-3-div">
                <label>Roles*</label>
                <angular2-multiselect [data]="allRoles" formControlName="role_ids" [settings]="{singleSelection: false,
                    text:'Select Permission' ,
                    enableSearchFilter: true,
                    labelKey:'name',
                    primaryKey:'id'}">
                  <c-badge>
                    <ng-template let-item="item">
                      <label>{{item.name}}</label>
                    </ng-template>
                  </c-badge>
                </angular2-multiselect>
              </div>
            </div>


            ​<div class="button-wrap">
              <button type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw">Submit</button>
              <button type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12 grid-margin">
    <div class="card">
      <div class="card-body upper-header">
        <h4 class="card-title">
          <div class="d-flex bd-highlight">
            <div class="p-2 flex-grow-1 bd-highlight">Users</div>
          </div>
        </h4>

        <div class="table-responsive" *ngIf="allData && allData.data && allData.data.length">
          <table class="table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>User Name</th>
                <th>assigned Roles</th>
                <th>Assign Roles</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of allData?.data; let i = index">
                <td>
                  <span *ngIf="page == 1">{{i+1}}</span>
                  <span *ngIf="page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>
                  <span *ngIf="data?.username">{{data?.username}}</span>
                </td>
                <td>
                  <span *ngFor = "let role of data?.roles; let in = index">
                    {{role.name}} <span *ngIf = "data.roles && data.roles.length && ((data.roles.length - 1) != in)">, </span>
                  </span>
                </td>
                <td class="fixed-side">
                  <a title="Assign Roles" *ngIf="permissionsService.checkPermission('create_assign_user_roles')"
                    (click)="onClickAssign(data)"><i class="fa fa-user-plus" aria-hidden="true"></i></a>
                  <!-- [routerLink]="['/assign-role-to-user/', data.id, data.username]" -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="p-12" *ngIf="allData && allData.data && !allData.data.length">
          <span class="ng-star-inserted no_data">No Data found.</span>
        </div>
      </div>
    </div>
  </div>
</div>