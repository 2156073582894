
  <div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
          <div class="row">
            <div class="form-group col-2">
              <label>Date of Booking</label>
              <input class="form-control border-primary" [owlDateTime]="dt1" [max]="maxDate" placeholder="Date" name="from_date"
                [(ngModel)]="from_date" readonly>
              <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
              <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
            </div>
          <div class="form-group col-2">
            <label >Payment Status</label>
            <select class="form-control border-primary" name="payment_status" [(ngModel)]="payment_status">
              <option [value]="''">All</option>
              <option [value]="true">Paid</option>
              <option [value]="false">Unpaid</option>
            </select>
          </div>
          <div class="form-group col-2">
            <label>Order Status</label>
            <select class="form-control border-primary" name="orderStatusId" [(ngModel)]="orderStatusId">
              <option [value]="''">All</option>
              <option [value]="order.id" *ngFor = "let order of orderStatusList">{{order.name}}</option>
            </select>
          </div>
            <div class="form-group">
              <button type="button" style="margin-top: 30px;" class="btn btn-inverse-primary btn-fw top-btn-head" (click)="getList()">Load</button>
            </div>
          </div>
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Prasad Order List</div>
              <div class="p-2 flex-shrink-1 bd-highlight searchValue"><input  class="form-control border-primary margin-top-search" [(ngModel)] = "searchValue" placeholder="Search By First Name, Mobile No. " (ngModelChange) = "changeSearchValue()" name="name" ></div>

              <!-- <div class="p-2 bd-highlight" *ngIf = "permissionsService.checkPermission('read_export_payment_report')"><button  type="btn"  class="btn btn-primary btn-fw" (click) = "export()">Export</button>
              </div> -->
            </div>
          </h4>
          <div class="table-responsive" *ngIf="prasadOrder && prasadOrder.data && prasadOrder.data.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Mobile No.</th>
                <th>City</th>
                <th>Address</th>
                <th>
                  Number Of Box
                </th>
                <th>Amount</th>
                <th>Payment Status</th>
                <th>Action</th>
                <th>Booking Status</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of prasadOrder?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>{{data?.created_at}}</td>
                <td>{{data?.first_name}}</td>
                <td>{{data?.last_name}}</td>
                <td>{{data?.mobile_no}}</td>
                <td>{{data?.city.name}}</td>
                <td>{{data?.address_line_1}}</td>
                <td>
                  <span *ngFor = "let item of prasadList">
                    <p style="margin-bottom: 0px;padding: 10px 0px;" *ngIf = "checkQuntity(data,item)">
                     {{item.name}} - <b>{{checkQuntity(data,item)}}</b>
                    </p>
                  </span>
                </td>
                <td>{{data?.total_amount}}</td>
                <td>{{data?.payment_process_completed ? 'Paid' : 'Pending'}}</td>
                <td>
                  <a title="Update Order Status" *ngIf = "data?.payment_process_completed" (click) = "orderStatusMode(data)"><i class="fa fa-pencil-square" aria-hidden="true"></i></a>
                </td>
                <td>
                  <span *ngIf = "!data?.payment_process_completed || !data.isModeEdit">
                    {{data?.current_status?.name}}
                  </span>
                  <div class="form-group" *ngIf = "data?.payment_process_completed && data.isModeEdit">
                    <select class="form-control border-primary" name = "id" (change)="updateOrderStatus($event,data)" [(ngModel)]="data.current_status.id">
                      <option [value]="order.id"  *ngFor = "let order of orderStatusList">{{order.name}}</option>
                    </select>
                  </div>
                </td>
              </tr> 
              </tbody>
            </table>
          </div>

          <div class="p-12" *ngIf="prasadOrder && prasadOrder.data && !prasadOrder.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
          <ngb-pagination *ngIf="prasadOrder && prasadOrder.data && prasadOrder.data.length" [collectionSize]="totalCount" [pageSize]="perPage"
          [(page)]="page" (pageChange)="getList('pagination')" [maxSize]="3"
          class="pull-right justify-content-end page-mt-10"
          aria-label="Default pagination"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>

