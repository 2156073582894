<div style="display: inline-block;width: 100%;height: auto;">
  <div style="width: 100%; float: left; padding-left: 20px;box-sizing: border-box;">
    <ul style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;">
      <div class="form-group col-4-div wrapper_label" style="padding-right: 10px;box-sizing: border-box;">
        <label>Qr Code*</label>
        <textarea #autofocus class="form-control border-primary" rows="1" minlength="6" maxlength="26" type="text"
                  (ngModelChange)="changeSearchValue()" [(ngModel)]="registrationNo" name="registrationNo"></textarea>
        <div class="inaild_no" *ngIf="errorMsg">{{errorMsg}}</div>
      </div>
    </ul>
    <div class="card"
         *ngIf="registraionDetail">
      <div class="card-body upper-header">
        <div class="table-responsive">
          <ul
            style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;margin-top: 50px;">
            <li class="wrapper_label">
              <label>Registration No.</label>
              <small>{{registraionDetail?.id}}</small>
            </li>


            <li class="wrapper_label">
              <label>Name</label>
              <small>{{registraionDetail?.name}}</small>
            </li>

            <li class="wrapper_label">
              <label>Mobile Number</label>
              <small>{{registraionDetail?.mobile_no}}</small>
            </li>
            <li class="wrapper_label">
              <label>Locker Location</label>
              <small>{{registraionDetail?.locker_location.name}}</small>
            </li>

            <li class="wrapper_label">
              <label>Locker No.</label>
              <small>{{registraionDetail?.locker_no}}</small>
            </li>
            <li class="wrapper_label">
              <label>Item Quantity</label>
              <small>{{registraionDetail?.item_qty}}</small>
            </li>
            <li class="wrapper_label">
              <label>Item Submit At</label>
              <small>{{registraionDetail?.item_submit_at}}</small>
            </li>
            <li class="wrapper_label">
              <label>Collected By</label>
              <small>{{registraionDetail?.collect_by_user?.username}}</small>
            </li>
            <li class="wrapper_label">
              <label>Return By</label>
              <small>{{registraionDetail?.return_by?.username}}</small>
            </li>
            <li class="wrapper_label">
              <label>Person's Image</label>
              <img style="cursor: pointer;" width="100" (click) = "viewImage(registraionDetail?.person_image_url)" src="{{registraionDetail?.person_image_url}}">
            </li>
            <li class="wrapper_label">
              <label>Item's Image</label>
              <img style="cursor: pointer;" width="100" (click) = "viewImage(registraionDetail?.items_image_url)" src="{{registraionDetail?.items_image_url}}">
            </li>

          </ul>
        </div>


      </div>
    </div>

    <div class="p-12"
         *ngIf="registrationNo && !registraionDetail && !loading && !errorMsg">
      <span class="ng-star-inserted no_data">No Data found.</span>
    </div>
    <div  class="mt-4" *ngIf="registraionDetail">
      <button [disabled] = "!registraionDetail || registraionDetail?.is_return" class="btn btn-primary btn-fw top-btn-head" (click)="saveData()">Return Item</button>
    </div>
  </div>
</div>
