
  <div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header" *ngIf="roles && roles.data && roles.data.length">
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-fill bd-highlight">Roles</div>
              <div class="p-2 flex-shrink-1 bd-highlight">
                <a routerLink="/add-roles" *ngIf = "permissionsService.checkPermission('create_roles')"> <i class="fa fa-plus-circle" style="font-size: 35px;cursor: pointer;" aria-hidden="true"></i></a>
               </div>
            </div>
          </h4>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Description</th>
                <!-- <th>Assign User</th> -->
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of roles?.data; let i = index">
                <td>{{i+1}}</td>
                <td>{{data?.name}}</td>
                <td>{{data?.description}}</td>
                <!-- <td>
                 <a (click)="assignRoles(data)"><i class="fa fa-user-plus" aria-hidden="true"></i></a>
                </td> -->
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="p-12" *ngIf="roles && roles.data && !roles.data.length">
          <span class="ng-star-inserted no_data" >No Data found.</span>
        </div>
      </div>
    </div>
  </div>

