<div style="display: inline-block;width: 100%;height: auto;">
    <div style="width: 100%; float: left; padding-left: 20px;box-sizing: border-box;" >
        <div class="d-flex">
            <div class="p-2 w-100"></div>
            <div class="p-2 flex-shrink-1"><button type="button" routerLink = "/bhasmaarti-requests-list" class="btn btn-inverse-primary btn-fw">Back</button></div>
        </div>
        <div class="card"
            *ngIf="visitorDetails">
            <div class="card-body upper-header">
                <div class="table-responsive">
                    <ul
                        style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;margin-top: 50px;">
                        <li class="wrapper_label">
                            <label>Name</label>
                            <small>{{visitorDetails?.first_name}}
                                {{visitorDetails?.last_name}}
                            </small>
                        </li>

                        <li class="wrapper_label">
                            <label>Mobile Number</label>
                            <small>{{visitorDetails?.mobile_no}}</small>
                        </li>
                      
                        <li class="wrapper_label">
                            <label>Created Date</label>
                            <small>{{visitorDetails?.created_at | date:'dd-MM-yyyy'}}</small>
                        </li>

                        <li class="wrapper_label">
                            <label>Request For Date</label>
                            <small>{{visitorDetails?.request_for_date | date:'dd-MM-yyyy'}}</small>
                        </li>
                        
                        <!-- <li class="wrapper_label">
                            <label>City</label>
                            <small>{{visitorDetails?.city?.name}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>State</label>
                            <small>{{visitorDetails?.state?.name}}</small>
                        </li>
                     -->


                    </ul>
                    <h4 class="card-title mt-4">
                        <div class="d-flex bd-highlight">
                            <div class="p-2 flex-fill bd-highlight" style="font-size: 20px;">Visitor Details</div>
                        </div>
                    </h4>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Image</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of visitorDetails.visitor_basic_details; let i = index">
                                <td>
                                    <span>{{i+1}}</span>
                                </td>
                                <td>{{data?.name}}</td>
                                <td>
                                    <span *ngIf = "data?.image_url"><img style="cursor: pointer;" (click) = "viewImage(data?.image_url)" src = "{{data?.image_url}}"></span>
                                    <span *ngIf = "!data?.image_url">NA</span>
                                </td>
                            </tr> 
                        </tbody>
                    </table>

                </div>


            </div>
        </div>

        <!-- <div class="p-12"
        *ngIf="registraionNo && !registraionDetail && !visitorDetails && !loading && !errorMsg">
           <span class="ng-star-inserted no_data">No Data found.</span>
         </div> -->
    </div>
</div>
