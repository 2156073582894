import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { ConfirmationPopoverComponent } from '../confirmation-popover/confirmation-popover.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-user-to-darshan-type-mapping',
  templateUrl: './user-to-darshan-type-mapping.component.html',
  styleUrls: ['./user-to-darshan-type-mapping.component.scss']
})
export class UserToDarshanTypeMappingComponent implements OnInit {
  public darshanTypeList: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public onChangeSearch = new Subject<string>();
  public perPage = 20;
  public totalCount = 0;
  public page = 1;


  constructor(
    private apiService: ApiService,
    private appComponet: AppComponent,
    private modalService: NgbModal,
    public permissionsService: PermissionsService


    ) { }

  ngOnInit() {
    this.getList('');
  }

  getList(_type) {
    this.appComponet.showLoader();
    this.apiService.getDataFromServer(`/darshan_type_user`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.darshanTypeList = res;
        // this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }

  
  deleteDepartment(_item){
    const modalRef = this.modalService.open(ConfirmationPopoverComponent,
      {
        centered: false, size: 'sm', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const deleteComponent: ConfirmationPopoverComponent = modalRef.componentInstance;
    deleteComponent.massage = 'Are you sure you want to delete this?';
    modalRef.result.then((result) => {
      if (result === 'success') {
        this.apiService.deleteDataFromServer('/darshan_type_user/' + _item.id).subscribe(
          res => {
            this.appComponet.showMessageAlert(res['message'], 'success');
            this.getList('');
          },
          err => {
            console.log(err);
          }
        );
      }
    });
  
  }



}