import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from '../app.component';
import { AssingRolesComponent } from '../assing-roles/assing-roles.component';
import { ApiService } from '../services/api.service';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  public roles: any;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private appComponent: AppComponent,
    public permissionsService: PermissionsService

    ) { }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.appComponent.showLoader();
    this.apiService.getDataFromServer(`/roles`).subscribe(
      (res: any) => {
        this.roles = res;
        this.appComponent.hideLoader();
      },
      err => {
        console.log(err);
      }
    );
  }

  public assignRoles(data) {
    const modalRef = this.modalService.open(AssingRolesComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const deleteComponent: AssingRolesComponent = modalRef.componentInstance;
    deleteComponent.roleData = data;
    modalRef.result.then((result) => {
      if (result === 'success') {
        this.appComponent.showMessageAlert('Roles successfully assigned to user.', 'success');
        this.getList();
      }
    });
  }


}

