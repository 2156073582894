
<div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Item Categories</div>
              <div class="form-group  text-center pt-4 mt-2 ml-4" style="margin-left: auto !important;">
                <a title="Add Item Categories" *ngIf = "permissionsService.checkPermission('create_item_categories')" routerLink="/add-item-categories"> <i class="fa fa-plus-circle" style="font-size: 35px;cursor: pointer;" aria-hidden="true"></i></a>
              </div>
            </div>
          </h4>

          
          <ng-container>
          <div class="table-responsive"  *ngIf="itemCategories && itemCategories.data && itemCategories.data.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of itemCategories?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>
                  <span *ngIf = "data?.name">{{data?.name}}</span>
                </td>
                <td class="fixed-side">
                  <a title="Edit" *ngIf = "permissionsService.checkPermission('update_item_categories')"   [routerLink]="['/add-item-categories/', data.id]"><i class="fa fa-pencil" ></i></a>
                  <a title="Delete" *ngIf = "permissionsService.checkPermission('delete_item_categories')" class="ml-4" (click)="deleteItemCategories(data)"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="itemCategories && itemCategories.data && !itemCategories.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
        </ng-container>
        </div>
      </div>
    </div>
  </div>

