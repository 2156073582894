import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { ConfirmationPopoverComponent } from '../confirmation-popover/confirmation-popover.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionsService } from '../services/permissions.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { SortListService } from '../services/sort-list.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {

  items = [];
  form: FormGroup;
  formMode = 'Add';
  page = 1;
  itemCategoryList = [];
  itemUnitList = [];


  constructor(
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    private appComponet: AppComponent,
    private modalService: NgbModal,
    public permissionsService: PermissionsService,
    private appComponent: AppComponent, 
    private sortListService: SortListService
    ) { }

  ngOnInit() {
    this.getList();
    this.getAllItemCategoryList();
    this.getAllItemUnitList();
  }

  getList() {
    this.appComponet.showLoader();
    this.apiService.getDataFromServer(`/items`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.items = res.data;
        // this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllItemCategoryList() {
    this.apiService.getDataFromServer('/item_categories').subscribe(
      res => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.itemCategoryList = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllItemUnitList() {
    this.apiService.getDataFromServer('/item_units').subscribe(
      res => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.itemUnitList = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  
  deleteItemUnits(_item){
    const modalRef = this.modalService.open(ConfirmationPopoverComponent,
      {
        centered: false, size: 'sm', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const deleteComponent: ConfirmationPopoverComponent = modalRef.componentInstance;
    deleteComponent.massage = 'Are you sure you want to delete this?';
    modalRef.result.then((result) => {
      if (result === 'success') {
        this.apiService.deleteDataFromServer('/items/' + _item.id).subscribe(
          res => {
            this.appComponet.showMessageAlert(res['message'], 'success');
            this.getList();
          },
          err => {
            console.log(err);
          }
        );
      }
    });
  
  }

  createForm(data?) {
    this.form = this._formBuilder.group({
      name: [data ?  data.name : '', [Validators.required]],
      quantity_in_stock: [data ? data.quantity_in_stock : '', [Validators.required]],
      item_category_id: [data ? data.item_category_id : '', [Validators.required]],
      item_unit_id: [data ? data.item_unit_id : '', [Validators.required]],
      id:[data ?  data.id : '']
    });
   
  }

  close(){
    this.form = null;
  }

  addNewItems(){
    this.createForm();
    this.formMode = 'Add';
  } 

  editItem(_data){
    this.formMode = 'Edit';
    this.createForm(_data);
  }

  saveData() {
    let formObj = this.form.getRawValue();

      this.appComponent.showLoader();
      if(this.formMode == 'Add'){
        this.apiService.postDataToServer('/items' , formObj).subscribe(
          res => {
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(res['message'], 'success');
            this.createForm();
            this.close();
            this.getList();
          },
          err => {
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
            console.log(err);
          }
        );
      } else {
        this.apiService.putDataToServer('/items/' + formObj.id , formObj).subscribe(
          res => {
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(res['message'], 'success');
            this.createForm();
            this.close();
            this.getList();
          },
          err => {
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
            console.log(err);
          }
        );
      }
      
  }

}
