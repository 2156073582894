import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-re-assignments-of-the-permissions',
  templateUrl: './re-assignments-of-the-permissions.component.html',
  styleUrls: ['./re-assignments-of-the-permissions.component.scss']
})
export class ReAssignmentsOfThePermissionsComponent implements OnInit {
  public reAssignmentPermission: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  public payment_status = 'Pending';
  public onChangeSearch = new Subject<string>();
  public perPage = 20;
  public totalCount = 0;
  public page = 1;
  public maxDate =  ''; //moment(new Date()).format('YYYY-MM-DD');
  public entry_type = '';

  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent,
    public permissionsService: PermissionsService
    
    ) { }

  ngOnInit() {
    this.getList('');
  }

  getList(_type) {
    this.appComponent.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/re_assign_permissions?date=${from_date}&end_date=${to_date}&page=${this.page}&perpage=${this.perPage}&entry_type=${this.entry_type}`).subscribe(
      (res: any) => {
        this.reAssignmentPermission = res;
        this.appComponent.hideLoader();
        this.reAssignmentPermission.data.map(res => {
          res.date = moment.utc(res.date).local().format('DD-MM-YYYY');
        });
        // this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }

  // public export() {
  //   const from_date = moment(this.from_date).format('YYYY-MM-DD');
  //   const to_date = moment(this.to_date).format('YYYY-MM-DD');
  //   this.appComponent.showLoader();
  //   this.apiService.getDataFromServer(`/export_payment_report?date=${from_date}&end_date=${to_date}&page=1&perpage=10000`).subscribe((res: any) => {
  //     if(res.status_code === 200) {
  //       const fileArry =  res.data.split('/uploads/');
  //       this.apiService.getBlobFileFromServer(res.data).subscribe(res => {
  //         this.appComponent.hideLoader();
  //         let blob:any = new Blob([res], { type: res.type });
  //         const url = window.URL.createObjectURL(blob);
  //         let file_name = fileArry[fileArry.length - 1];
  //         saveAs(blob, file_name);
  //       }, (error) => {
  //       });
  //     } 
  //     this.appComponent.hideLoader();
  //   }, (error) => {
  //     this.appComponent.hideLoader();
  //     console.log('error' , error);
  //   });
  // }


}

