import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { ApiService } from '../services/api.service';
import { AppComponent } from '../app.component';
import { PermissionsService } from '../services/permissions.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-bhasmaarti-requests-list',
  templateUrl: './bhasmaarti-requests-list.component.html',
  styleUrls: ['./bhasmaarti-requests-list.component.scss']
})
export class BhasmaartiRequestsListComponent implements OnInit {

  allBhasmaartiRequests: any = [];
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  public searchValue = '';
  public onChangeSearch = new Subject<string>();
  public perPage = 20;
  public totalCount = 0;
  public page = 1;
  public maxDate = ''; // moment(new Date()).format('YYYY-MM-DD');

  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent,
    public permissionsService: PermissionsService

    ) { }

  ngOnInit() {
    this.onChangeSearch
    .pipe(debounceTime(1000))
    .subscribe(() => {
      this.getList('');
    });

    this.getList('');
  }

  public changeSearchValue(): void {
    this.onChangeSearch.next();
  }


  getList(_type) {
    this.appComponent.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/bhasmarti_requests?page=${this.page}&perpage=${this.perPage}&from_date=${from_date}&to_date=${to_date}&search=${this.searchValue}`).subscribe(
      (res: any) => { 
        this.appComponent.hideLoader();
        this.allBhasmaartiRequests = res;
        // 
        this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }

}