<div class="row" *ngIf="!addPayment">
  <div class="col-lg-12 grid-margin">
    <div class="card">
      <div class="card-body upper-header">

        <div class="filter-wrapper" *ngIf="form">
          <form [formGroup]="form" novalidate (ngSubmit)="saveData()">
            <h5>Add Personal Details</h5>
            <div class="form-group col-12-div">
              <div class="form-group col-4-div">
                <label>First Name*</label>
                <input class="form-control border-primary" formControlName="first_name" name="first_name" required>
                <div class="" *ngIf="form.controls.first_name.touched && !form.controls.first_name.valid">
                  <div class="inaild_no" *ngIf=" form.controls.first_name.errors.number">Numbers Are Not Allowed!</div>
                </div>

                <div class="" *ngIf="form.controls.first_name.touched && !form.controls.first_name.valid">
                  <div class="inaild_no" *ngIf=" form.controls.first_name.errors.required">First Name is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label>Last Name*</label>
                <input class="form-control border-primary" formControlName="last_name" name="last_name" required>
                <div class="" *ngIf="form.controls.last_name.touched && !form.controls.last_name.valid">
                  <div class="inaild_no" *ngIf=" form.controls.last_name.errors.number">Numbers Are Not Allowed!</div>
                </div>
                <div class="" *ngIf="form.controls.last_name.touched && !form.controls.last_name.valid">
                  <div class="inaild_no" *ngIf=" form.controls.last_name.errors.required">Last Name is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label>Father Name</label>
                <input class="form-control border-primary" formControlName="father_name" name="father_name">
                <div class="" *ngIf="form.controls.father_name.touched && !form.controls.father_name.valid">
                  <div class="inaild_no" *ngIf=" form.controls.father_name.errors.number">Numbers Are Not Allowed!</div>
                </div>

                <div class="" *ngIf="form.controls.father_name.touched && !form.controls.father_name.valid">
                  <div class="inaild_no" *ngIf=" form.controls.father_name.errors.required">Father Name is required!
                  </div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label>Contact No.*</label>
                <input class="form-control border-primary" type="text" (keypress)="keyPressNumbers($event)"
                  maxlength="10" minlength="10" formControlName="mobile_no" name="mobile_no" required>
                <div class="" *ngIf="form.controls.mobile_no.touched && !form.controls.mobile_no.valid">
                  <div class="inaild_no" *ngIf=" form.controls.mobile_no.errors.pattern">Invalid Mobile Number</div>
                </div>
                <div class="" *ngIf="form.controls.mobile_no.touched && !form.controls.mobile_no.valid">
                  <div class="inaild_no" *ngIf=" form.controls.mobile_no.errors.required">Contact Number is required!
                  </div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label>Email ID</label>
                <input class="form-control border-primary" type="email" formControlName="email" name="email">
                <div class="" *ngIf="form.controls.email.touched && !form.controls.email.valid">
                  <div class="inaild_no" *ngIf=" form.controls.email.errors.required">Email is required!</div>
                </div>
              </div>
            </div>
            <h5>Add Address Details</h5>
            <div class="form-group col-12-div">

              <div class="form-group col-4-div">
                <label>Address</label>
                <textarea class="form-control border-primary" id="exampleFormControlTextarea1" rows="2"
                  formControlName="address_line_1" name="address_line_1"></textarea>

                <!-- <input   class="form-control border-primary"   formControlName="address" name="address" required> -->
                <div class="" *ngIf="form.controls.address_line_1.touched && !form.controls.address_line_1.valid">
                  <div class="inaild_no" *ngIf=" form.controls.address_line_1.errors.required">Address is required!
                  </div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label>Country*</label>
                <select class="form-control border-primary" (change)="onCountryChange($event)" name="country_id"
                  formControlName="country_id">
                  <option value="">select</option>
                  <option *ngFor="let data of countriesData" [value]="data.id">{{data.name}}</option>
                </select>
                <div class="" *ngIf="form.controls.country_id.touched && !form.controls.country_id.valid">
                  <div class="inaild_no" *ngIf=" form.controls.country_id.errors.required">Country is required!</div>
                </div>
              </div>


              <div class="form-group col-4-div">
                <label>State*</label>
                <select class="form-control border-primary" (change)="onStateChange($event)" name="state_id"
                  formControlName="state_id">
                  <option value="">select</option>
                  <option *ngFor="let data of allStates" [value]="data.id">{{data.name}}</option>
                </select>
                <div class="" *ngIf="form.controls.state_id.touched && !form.controls.state_id.valid">
                  <div class="inaild_no" *ngIf=" form.controls.state_id.errors.required">State is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label>City*</label>
                <select class="form-control border-primary" name="city_id" formControlName="city_id">
                  <option value="">select</option>
                  <option *ngFor="let data of allCities" [value]="data.id">{{data.name}}</option>
                </select>
                <div class="" *ngIf="form.controls.city_id.touched && !form.controls.city_id.valid">
                  <div class="inaild_no" *ngIf=" form.controls.city_id.errors.required">City is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label>Zip/Pin Code</label>
                <input class="form-control border-primary" type="text" (keypress)="keyPressNumbers($event)"
                  maxlength="7" minlength="5" formControlName="zip_code" name="zip_code">
                <div class="" *ngIf="form.controls.zip_code.touched && !form.controls.zip_code.valid">
                  <div class="inaild_no"
                    *ngIf="form.controls.zip_code.errors && form.controls.zip_code.errors.minlength &&  5 > form.controls.zip_code.errors.minlength.actualLength">
                    Zip/Pin Code Minimum Length Is 5!</div>
                </div>
                <div class="" *ngIf="form.controls.zip_code.touched && !form.controls.zip_code.valid">
                  <div class="inaild_no" *ngIf="form.controls.zip_code.errors.required">Zip/Pin Code is required!!</div>
                </div>
              </div>
            </div>
            <h5>Add Other Details</h5>

            <div class="form-group col-12-div">

              <div class="form-group col-4-div">
                <label>Donation Purpose*</label>
                <select class="form-control border-primary" name="donation_purpose_id"
                  formControlName="donation_purpose_id">
                  <option value="">select</option>
                  <option *ngFor="let data of donatePurposeData" [value]="data.id">{{data.title}}</option>
                </select>
                <div class=""
                  *ngIf="form.controls.donation_purpose_id.touched && !form.controls.donation_purpose_id.valid">
                  <div class="inaild_no" *ngIf=" form.controls.donation_purpose_id.errors.required">Donation Purpose is
                    required!</div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label>Donation Amount*</label>
                <input class="form-control border-primary" type="text" formControlName="amount" name="amount" required>

                <div class="" *ngIf="form.controls.amount.touched && !form.controls.amount.valid">
                  <div class="inaild_no" *ngIf=" form.controls.amount.errors.required">Donation Amount is required!
                  </div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label>ID Proof Type</label>
                <select class="form-control border-primary" (change)="onIdProofTypeChange(i)" name="id_proof_type_id"
                  formControlName="id_proof_type_id">
                  <option value="">select</option>
                  <option [value]="type.id" *ngFor="let type of id_proof_type_list">{{type.name}}</option>
                </select>
                <div class="" *ngIf="form.controls.id_proof_type_id.touched && !form.controls.id_proof_type_id.valid">
                  <div class="inaild_no" *ngIf=" form.controls.id_proof_type_id.errors.required">ID Proof Type is
                    required!</div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label>ID Proof Number</label>
                <input class="form-control border-primary" [maxlength]="checkLengthStatus ? 14 : ''"
                  (keypress)="createSpaceProofNumber()" (change)="createSpaceProofNumber()" type="text"
                  formControlName="id_proof_number" name="id_proof_number">
                <div class="" *ngIf="form.controls.id_proof_number.touched && !form.controls.id_proof_number.valid">
                  <div class="inaild_no" *ngIf=" form.controls.id_proof_number.errors.required">ID Proof Number is
                    required!</div>
                </div>
                <div class=""
                  *ngIf="form?.controls?.id_proof_number?.touched && !form?.controls?.id_proof_number?.valid">
                  <div class="inaild_no" *ngIf="form?.controls?.id_proof_number?.errors?.pattern">Please Enter Valid ID
                    Proof Number!</div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label>Pan Card No.</label>
                <input class="form-control border-primary" type="text" formControlName="pan_card_no" name="pan_card_no">
                <div class="" *ngIf="form.controls.pan_card_no.touched && !form.controls.pan_card_no.valid">
                  <div class="inaild_no" *ngIf=" form.controls.pan_card_no.errors.required">Pan Card No. is required!
                  </div>
                </div>
                <div class="" *ngIf="form?.controls?.pan_card_no?.touched && !form?.controls?.pan_card_no?.valid">
                  <div class="inaild_no" *ngIf="form?.controls?.pan_card_no?.errors?.pattern">Please Enter Valid Pan
                    Card Number</div>
                </div>
                <div class="" *ngIf="!form?.value?.pan_card_no">
                  <div class="inaild_no">Note : Enter Pan Card Number If You Need Income Tax Receipt.</div>
                </div>
              </div>

              <div class="form-group  col-4-div">
                <label>User ID Proof</label>
                <div class="upload-btn-wrapper">
                  <button class="btn" [ngClass]="id_proof_name ? 'upload_file': ''">Upload ID Proof</button>
                  <input type="file" name="document" accept="image/*" class="form-control"
                    (change)="getIdProofFileDetails($event)" style="padding: 0 !important;" />
                  OR
                </div>

                <div class="upload-btn-wrapper">

                  <button class="btn" type="button" (click)="onClickCapturePhoto('id_proof_image_url')"
                    [ngClass]="id_proof_name ? 'upload_file': ''">Capture ID Proof</button>
                </div>

                <img class="mt-2" *ngIf="id_proof_name" src="{{id_proof_name}}" height="125" />
                <!-- <span class="file_name" *ngIf = "id_proof_name">{{id_proof_name}}</span> -->
                <div class="lds-ellipsis" style="margin-top: 80px;" *ngIf="idProofLoading">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>


              </div>


              <div class="form-group col-4-div">
                <label>User Picture</label>
                <div class="upload-btn-wrapper">
                  <button class="btn" [ngClass]="user_picture_file_name ? 'upload_file': ''">Upload a photo</button>
                  <input type="file" name="document" accept="image/*" class="form-control"
                    (change)="onFileChange($event)" style="padding: 0 !important;" />
                </div>
                OR
                <div class="upload-btn-wrapper">
                <button class="btn" type="button" (click)="onClickCapturePhoto('image_url')"
                  [ngClass]="user_picture_file_name ? 'upload_file': ''">Capture Photo</button>
              </div>
              <!-- <span class="file_name" *ngIf = "user_picture_file_name">{{user_picture_file_name}}</span> -->
              <img class="mt-2" *ngIf="user_picture_file_name" style="border: 1px solid #c1bdbd;"
                src="{{user_picture_file_name}}" height="125" />

              <div class="lds-ellipsis" style="margin-top: 80px;" *ngIf="imageUrlLoading">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>


        </div>

        ​<div class="button-wrap">
          <button type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw">Submit</button>
          <button type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button>
        </div>

        </form>
      </div>
    </div>
  </div>
</div>
</div>
<form [formGroup]="paymentForm" *ngIf="addPayment">
  <div class="row">

    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">

          <div class="filter-wrapper">
            <h5>Add Payment Detail</h5>
            <div class="form-group col-12-div">
              <div class="form-group col-4-div">
                <label>Payment Mode*</label>
                <select class="form-control border-primary" (change)="onPaymentModeChange()"
                  formControlName="payment_type_id" name="payment_type_id" required>
                  <option [value]="''">select</option>
                  <ng-container *ngFor="let data of allPaymentTypes">
                    <option *ngIf="data.slug != 'card' && data.slug != 'online'" [value]="data.id">{{data.type}}
                    </option>
                  </ng-container>
                </select>
                <div class=""
                  *ngIf="paymentForm.controls.payment_type_id.touched && !paymentForm.controls.payment_type_id.valid">
                  <div class="inaild_no" *ngIf="paymentForm.controls.payment_type_id.errors.required">Payment Mode is
                    required!</div>
                </div>
              </div>
              <div class="form-group col-4-div" *ngIf="paymentType == 'cheque'">
                <label>Bank Name*</label>
                <input class="form-control border-primary" formControlName="bankName" name="bankName" required>
                <div class="" *ngIf="paymentForm.controls.bankName.touched && !paymentForm.controls.bankName.valid">
                  <div class="inaild_no" *ngIf=" paymentForm.controls.bankName.errors.number">Numbers Are Not Allowed!
                  </div>
                </div>

                <div class="" *ngIf="paymentForm.controls.bankName.touched && !paymentForm.controls.bankName.valid">
                  <div class="inaild_no" *ngIf=" paymentForm.controls.bankName.errors.required">Bank Name is required!
                  </div>
                </div>
              </div>
              <div class="form-group col-4-div" *ngIf="paymentType == 'cheque'">
                <label>Bank Branch Name*</label>
                <input class="form-control border-primary" formControlName="bankBranchName" name="bankBranchName"
                  required>
                <div class=""
                  *ngIf="paymentForm.controls.bankBranchName.touched && !paymentForm.controls.bankBranchName.valid">
                  <div class="inaild_no" *ngIf="paymentForm.controls.bankBranchName.errors.number">Numbers Are Not
                    Allowed!</div>
                </div>

                <div class=""
                  *ngIf="paymentForm.controls.bankBranchName.touched && !paymentForm.controls.bankBranchName.valid">
                  <div class="inaild_no" *ngIf="paymentForm.controls.bankBranchName.errors.required">Bank Branch Name is
                    required!</div>
                </div>
              </div>
              <div class="form-group col-4-div" *ngIf="paymentType == 'cheque'">
                <label>Cheque No.*</label>
                <input type="number" class="form-control border-primary" formControlName="chequeNo" name="chequeNo"
                  required>
                <div class="" *ngIf="paymentForm.controls.chequeNo.touched && !paymentForm.controls.chequeNo.valid">
                  <div class="inaild_no" *ngIf="paymentForm.controls.chequeNo.errors.required">Cheque No. is required!
                  </div>
                </div>
              </div>
              <div class="form-group col-4-div" *ngIf="paymentType == 'cash'">
                <label>Transaction No. / Receipt No.*</label>
                <input type="text" class="form-control border-primary" formControlName="receiptNo" name="receiptNo"
                  required>
                <div class="" *ngIf="paymentForm.controls.receiptNo.touched && !paymentForm.controls.receiptNo.valid">
                  <div class="inaild_no" *ngIf="paymentForm.controls.receiptNo.errors.required">Transaction No. /
                    Receipt No. is required!</div>
                </div>
              </div>
              <div class="form-group col-4-div" *ngIf="paymentType == 'cheque'">
                <label>Receipt No.</label>
                <input type="text" class="form-control border-primary" formControlName="receiptNo" name="receiptNo">
              </div>

              <div class="form-group visitor_info_list" style="width: 165px;text-align: center;"
                *ngIf="paymentType == 'cheque'">
                <!-- <label >Cheque Picture*</label> -->
                <button type="button" style="margin-top: 30px;float: left;"
                  class="btn btn-inverse-primary btn-fw top-btn-head" (click)="onClickCapturePhoto()">Capture
                  Photo</button><br>
                OR
                <br>
                <div class="upload-btn-wrapper">
                  <button class="btn" [ngClass]="cheque_picture_file_name ? 'upload_file': ''">Upload a cheque photo
                    *</button>
                  <input type="file" name="document" accept="image/*" class="form-control"
                    (change)="onFileChangeCheque($event)" style="padding: 0 !important;" />
                  <img class="mt-2" *ngIf="cheque_picture_file_name" style="border: 1px solid #c1bdbd;"
                    [src]="cheque_picture_file_name" height="125" />
                </div>
              </div>

            </div>
            ​<div class="button-wrap">
              <button type="submit" [disabled]="!paymentForm.valid" (click)="saveDataOfPayment()"
                class="btn btn-primary btn-fw">Submit</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>