
<div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
            <div class="row">
                <div class="form-group col-1.5 ml-2">
                  <label>From Date</label>
                  <input class="form-control border-primary" [max] = "to_date" [owlDateTime]="dt" placeholder="Date" name="from_date"
                    [(ngModel)]="from_date" readonly>
                  <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                  <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-1.5 ml-2">
                    <label>To Date</label>
                    <input class="form-control border-primary" [min]="from_date" [owlDateTime]="dt1" placeholder="Date" name="to_date"
                      [(ngModel)]="to_date" readonly>
                    <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                    <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>

                    <div class="form-group col-2">
                        <label>Item Category</label>
                        <select class="form-control border-primary" name="item_id"  (change)="ChangeItemCategory(item_category_filter_id)" [(ngModel)]="item_category_filter_id">
                        <option [value]="''">All</option>
                        <option [value]="item.id" *ngFor = "let item of itemCategoryList">{{item.name}}</option>
                        </select>
                    </div>

                  <div class="form-group col-2">
                    <label>Item</label>
                    <select class="form-control border-primary" name="item_id" [(ngModel)]="item_id">
                      <option [value]="''">All</option>
                      <option [value]="item.id" *ngFor = "let item of itemList">{{item.name}}</option>
                    </select>
                  </div>


                <div class="form-group col-1.5">
                  <button type="button" style="margin-top: 30px;" class="btn btn-inverse-primary btn-fw top-btn-head" (click)="getList($event)">Load</button>
                </div>
                <div class="form-group col-1.5 ml-2" *ngIf="items.length != 0">
                    <button type="button" style="margin-top: 30px;" class="btn btn-inverse-primary btn-fw top-btn-head" (click)="exportData()">Export</button>
            </div>
              </div>
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Item Transactions</div>
              <div class="form-group  text-center pt-4 mt-2 ml-4" style="margin-left: auto !important;">
                <a title="Add Item Transaction" *ngIf = "permissionsService.checkPermission('create_item_transactions')" (click)="addNewItems()"> <i class="fa fa-plus-circle" style="font-size: 35px;cursor: pointer;" aria-hidden="true"></i></a>
              </div>
            </div>
          </h4>

          <div class="filter-wrapper field_space" *ngIf = "form">
            <form   [formGroup]="form" novalidate (ngSubmit)="saveData()">
              <div class="form-group col-12-div">

                <div class="form-group col-4-div">
                    <label >Item Category*</label>
                    <select class="form-control border-primary" name="item_cat_id"  (change)="ChangeItemCategory(item_category_id)" [(ngModel)]="item_category_id" [ngModelOptions]="{standalone: true}">
                        <option [value]="''">All</option>
                        <option [value]="item.id" *ngFor = "let item of itemCategoryList">{{item.name}}</option>
                    </select>
                  </div>
                
                <div class="form-group col-4-div">
                    <label >Item*</label>
                    <select class="form-control border-primary"   name="item_id" formControlName="item_id"
                   (change)="onItemChange(form.controls.item_id.value)">
                      <option [value]="''">select</option>
                      <option [value] ="item.id" *ngFor = "let item of itemList">{{item.name}}</option>
                    </select>
                    <div class="" *ngIf="form.controls.item_id.touched && !form.controls.item_id.valid">
                      <div  class="inaild_no" *ngIf="form.controls.item_id.errors.required">Item is required!</div>
                    </div>
        
                  </div>

                <div class="form-group col-4-div">
                  <label >Quantity*</label>
                  <input  class="form-control border-primary"  formControlName="quantity" name="quantity" required>
                 
                  <div class="" *ngIf="form.controls.quantity.touched && !form.controls.quantity.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.quantity.errors.required">Quantity is required!</div>
                  </div>
                  <div class="" *ngIf="form.controls.quantity.touched && !form.controls.quantity.valid">
                    <div  class="inaild_no" *ngIf="form.controls.quantity.errors.pattern">Only Numeric Value Allowed!</div>
                  </div>
              </div>
              
              <div class="form-group col-4-div">
                <label >Unit</label>
                <input  class="form-control border-primary" disabled [(ngModel)]="item_unit" 
                [ngModelOptions]="{standalone: true}">
              </div> 

                <div class="form-group col-4-div">
                    <label >Transaction Type*</label>
                    <div style="display: inline-block; width: 100%;">
                        <div style="padding-left: 45px;float: left;">
                            <input type="radio"  formControlName="transaction_type" value="in" style="zoom: 1.5;" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label" style="padding-top: 8px;" for="exampleCheck1">Received</label>
                        </div>
                        <div style="padding-left: 65px;float: left;">
                            <input type="radio"  formControlName="transaction_type" value="out" style="zoom: 1.5;" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label" style="padding-top: 8px;" for="exampleCheck1">Consume</label>
                        </div>
                    </div>
                </div>

      
              </div>
  
             <div class="button-wrap">
              <button  type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw">Submit</button>
              <button  type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button>
            </div>
  
            </form>
          </div>
          
          <ng-container *ngIf="!form">
          <div class="table-responsive"  *ngIf="items.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
               <th>Name</th>
               <th>Quantity</th>
               <th>Transaction Type</th>
               <th>Date</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of items; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>
                  <span *ngIf = "data?.item?.name">{{data?.item?.name}}</span>
                </td>
                <td>
                  <span *ngIf = "data?.quantity">{{data?.quantity}} {{data?.unit?.name}}</span>
                </td>
                <td>
                    <span>{{(data?.transaction_type == 'in') ? 'Received' : 'Consume'}}</span>
                </td>
                <td>
                  <span>{{data?.created_at | date : 'dd-MM-yyyy'}}</span>
              </td>
                <td class="fixed-side">
                  <a title="Edit" *ngIf = "permissionsService.checkPermission('update_item_transactions')"   (click)="editItem(data)"><i class="fa fa-pencil" ></i></a>
                  <a title="Delete" *ngIf = "permissionsService.checkPermission('delete_item_transactions')" class="ml-4" (click)="deleteItemUnits(data)"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="itemUnits && !itemUnits.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
        </ng-container>
        </div>
      </div>
    </div>
  </div>

