import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guards/auth.guards';
import {RegistrationFormComponent} from './registration-form/registration-form.component';
import {RegistrationSuccessComponent} from './registration-success/registration-success.component';
import {PaymentComponent} from './payment/payment.component';
import { RegistrationListComponent } from './registration-list/registration-list.component';
import { PaymentReportComponent } from './payment-report/payment-report.component';
import { FootFallReportComponent } from './foot-fall-report/foot-fall-report.component';
import { RolesComponent } from './roles/roles.component';
import { AddRolesComponent } from './add-roles/add-roles.component';
import { ReferenceRegCountReportComponent } from './reference-reg-count-report/reference-reg-count-report.component';
import { ResetUserPasswordComponent } from './reset-user-password/reset-user-password.component';
import { VisitorsDetailByRefPersonComponent } from './visitors-detail-by-ref-person/visitors-detail-by-ref-person.component';
import { DailyQuotaComponent } from './daily-quota/daily-quota.component';
import { LateRegistrationsRefCountsReportComponent } from './late-registrations-ref-counts-report/late-registrations-ref-counts-report.component';
import { LateRegVisitorsDetailsComponent } from './late-reg-visitors-details/late-reg-visitors-details.component';
import { AssignDepartmentComponent } from './assign-department/assign-department.component';
import { AssignedUserComponent } from './assigned-user/assigned-user.component';
import { AssignDarshanTypeComponent } from './assign-darshan-type/assign-darshan-type.component';
import { AddDarshanTypeComponent } from './add-darshan-type/add-darshan-type.component';
import { DepartmentComponent } from './department/department.component';
import { AddDepartmentComponent } from './add-department/add-department.component';
import { DarshanTypeComponent } from './darshan-type/darshan-type.component';
import { UserToDarshanTypeMappingComponent } from './user-to-darshan-type-mapping/user-to-darshan-type-mapping.component';
import { PermissionsRoleComponent } from './permissions-role/permissions-role.component';
import { AssignPermissionComponent } from './assign-permission/assign-permission.component';
import { UserListComponent } from './user-list/user-list.component';
import { AssignRoleToUserComponent } from './assign-role-to-user/assign-role-to-user.component';
import { ReAssignmentsOfThePermissionsComponent } from './re-assignments-of-the-permissions/re-assignments-of-the-permissions.component';
import { AddDailyAssignmentComponent } from './add-daily-assignment/add-daily-assignment.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { LiveDarshanComponent } from './live-darshan/live-darshan.component';
import { Live } from '@ng-bootstrap/ng-bootstrap/util/accessibility/live';
import { DonationListComponent } from './donation-management/donation-list/donation-list.component';
import { QrScanComponent } from './qr-scan/qr-scan.component';
import { PrasadOrderListComponent } from './prasad-order-list/prasad-order-list.component';
import { AddDonationComponent } from './add-donation/add-donation.component';
import { PaymentSummaryReportComponent } from './payment-summary-report/payment-summary-report.component';
import { RegistrationsSummaryReportComponent } from './registrations-summary-report/registrations-summary-report.component';
import { VisitorsDetailsComponent } from './visitors-details/visitors-details.component';
import { DharmshalaBookingListComponent } from './dharmshala-booking-list/dharmshala-booking-list.component';
import { LockerBookingComponent } from './locker-booking/locker-booking.component';
import { LockerBookingListComponent } from './locker-booking-list/locker-booking-list.component';
import { DharmshalaBookingDetailComponent } from './dharmshala-booking-detail/dharmshala-booking-detail.component';
import {LockerQrScanComponent} from './locker-qr-scan/locker-qr-scan.component';
import { MobileLockerReportComponent } from './mobile-locker-report/mobile-locker-report.component';
import { VisitorsFeedbackComponent } from './visitors-feedback/visitors-feedback.component';
import { PujanBookingsComponent } from './pujan-bookings/pujan-bookings.component';
import { AddLocalDarshanarthisComponent } from './add-local-darshanarthis/add-local-darshanarthis.component';
import { LocalDarshanarthisListComponent } from './local-darshanarthis-list/local-darshanarthis-list.component';
import { LocalDarshanarthisFaceRegistrationComponent } from './local-darshanarthis-face-registration/local-darshanarthis-face-registration.component';
import { ItemCategoriesComponent } from './item-categories/item-categories.component';
import { AddCategoriesComponent } from './add-categories/add-categories.component';
import { ItemsUnitsComponent } from './items-units/items-units.component';
import { ItemsComponent } from './items/items.component';
import { ItemTransactionsComponent } from './item-transactions/item-transactions.component';
import { NewsAndEventsComponent } from './news-and-events/news-and-events.component';
import { DharmshalaReserveRoomComponent } from './dharmshala-reserve-room/dharmshala-reserve-room.component';
import { DharmshalaReservationsListComponent } from './dharmshala-reservations-list/dharmshala-reservations-list.component';
import { NewsAndEventsListComponent } from './news-and-events-list/news-and-events-list.component';
import { BhasmaartiRequestsListComponent } from './bhasmaarti-requests-list/bhasmaarti-requests-list.component';
import { BhasmaartiRequestDetailsComponent } from './bhasmaarti-request-details/bhasmaarti-request-details.component';
import { LaunchingComponent } from './launching/launching.component';

const routes: Routes = [

  { path: '', redirectTo: 'login', pathMatch: 'full' },

  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: 'registration', component: RegistrationFormComponent, canActivate: [AuthGuard] },
      { path: 'dharmshala-booking', component: DharmshalaBookingListComponent},
      { path: 'locker-booking', component: LockerBookingComponent},
      { path: 'locker-bookings-list', component: LockerBookingListComponent},
      { path: 'dharmshala-booking-detail/:id', component: DharmshalaBookingDetailComponent},
      { path: 'roles', component: RolesComponent, canActivate: [AuthGuard] },
      { path: 'prasad-order-list', component: PrasadOrderListComponent, canActivate: [AuthGuard] },
      { path: 'reference-reg-count-report', component: ReferenceRegCountReportComponent, canActivate: [AuthGuard] },
      { path: 'reference-reg-count-report-7pm', component: LateRegistrationsRefCountsReportComponent, canActivate: [AuthGuard] },
      { path: 're-assignments-of-permissions-7pm', component: ReAssignmentsOfThePermissionsComponent, canActivate: [AuthGuard] },

      { path: 'add-roles', component: AddRolesComponent, canActivate: [AuthGuard] },
      { path: 'payment-report', component: PaymentReportComponent, canActivate: [AuthGuard] },
      { path: 'foot-fall-report', component: FootFallReportComponent, canActivate: [AuthGuard] },
      { path: 'mobile-locker-report', component: MobileLockerReportComponent, canActivate: [AuthGuard] },
      { path: 'reset-user-password', component: ResetUserPasswordComponent, canActivate: [AuthGuard] },
      { path: 'registration/:id', component: RegistrationFormComponent, canActivate: [AuthGuard] },
      { path: 'visitors-detail-by-ref-person', component: VisitorsDetailByRefPersonComponent, canActivate: [AuthGuard] },
      { path: 'visitors-detail-by-ref-person-7pm', component: LateRegVisitorsDetailsComponent, canActivate: [AuthGuard] },

      { path: 'daily-quota', component: DailyQuotaComponent, canActivate: [AuthGuard] },
      { path: 'registration-list', component: RegistrationListComponent, canActivate: [AuthGuard] },
      { path: 'user-to-department-mapping', component: AssignDepartmentComponent, canActivate: [AuthGuard] },
      { path: 'assign-department', component: AssignedUserComponent, canActivate: [AuthGuard] },
      { path: 'user-to-darshan-type-mapping', component: UserToDarshanTypeMappingComponent, canActivate: [AuthGuard] },
      { path: 'assign-darshan-type', component: AssignDarshanTypeComponent, canActivate: [AuthGuard] },
      { path: 'department', component: DepartmentComponent, canActivate: [AuthGuard] },
      { path: 'add-department', component: AddDepartmentComponent, canActivate: [AuthGuard] },
      { path: 'add-department/:id', component: AddDepartmentComponent, canActivate: [AuthGuard] },
      { path: 'darshan-type', component: DarshanTypeComponent, canActivate: [AuthGuard] },
      { path: 'add-darshan-type', component: AddDarshanTypeComponent, canActivate: [AuthGuard] },
      { path: 'registration-success/:id', component: RegistrationSuccessComponent, canActivate: [AuthGuard] },
      { path: 'payment/:id', component: PaymentComponent, canActivate: [AuthGuard] },
      { path: 'permissions-role', component: PermissionsRoleComponent, canActivate: [AuthGuard] },
      { path: 'assign-permission/:id', component: AssignPermissionComponent, canActivate: [AuthGuard] },
      { path: 'user-list', component: UserListComponent, canActivate: [AuthGuard] },
      // { path: 'daily-assignments', component: DailyAssignmentsComponent, canActivate: [AuthGuard] },
      { path: 'add-daily-assignments', component: AddDailyAssignmentComponent, canActivate: [AuthGuard] },
      { path: 'add-daily-assignments/:id', component: AddDailyAssignmentComponent, canActivate: [AuthGuard] },
      { path: 'payment-status', component: PaymentStatusComponent},
      { path: 'live-darshan', component: LiveDarshanComponent},
      { path: 'donation-list', component: DonationListComponent},
      { path: 'add-donation', component: AddDonationComponent},
      { path: 'qr-scanner', component: QrScanComponent},
      { path: 'payment-summary-report', component: PaymentSummaryReportComponent},
      { path: 'registrations-summary-report', component: RegistrationsSummaryReportComponent},
      { path: 'visitors-details', component: VisitorsDetailsComponent},
      { path: 'visitors-details/:id', component: VisitorsDetailsComponent},
      { path: 'visitors-feedback', component: VisitorsFeedbackComponent},
      { path: 'add-local-darshanarthis', component: AddLocalDarshanarthisComponent},
      { path: 'local-darshanarthis-list', component: LocalDarshanarthisListComponent},
      { path: 'local-darshanarthis-face-registration/:id', component: LocalDarshanarthisFaceRegistrationComponent},
      {
        path: 'restricted-date',
        canActivate: [AuthGuard],
        loadChildren: './restricted-date/restricted-date.module#RestrictedDateModule',
      },
      {
        path: 'generate-id-card',
        canActivate: [AuthGuard],
        loadChildren: './generate-id-card/generate-id-card.module#GenerateIdCardModule',
      },
      {
        path: 'locker-qr-scan/:id',
        component: LockerQrScanComponent
      },
      {
        path: 'locker-qr-scan',
        component: LockerQrScanComponent
      },
      {
        path: 'pujan-bookings',
        component: PujanBookingsComponent
      },
      { path: 'item-categories', component: ItemCategoriesComponent, canActivate: [AuthGuard] },
      { path: 'add-item-categories', component: AddCategoriesComponent, canActivate: [AuthGuard] },
      { path: 'add-item-categories/:id', component: AddCategoriesComponent, canActivate: [AuthGuard] },
      { path: 'item-units', component: ItemsUnitsComponent, canActivate: [AuthGuard] },
      { path: 'items', component: ItemsComponent, canActivate: [AuthGuard] },
      { path: 'item-transactions', component: ItemTransactionsComponent, canActivate: [AuthGuard] },
      { path: 'news-and-events-list', component: NewsAndEventsListComponent, canActivate: [AuthGuard] },
      { path: 'news-and-events', component: NewsAndEventsComponent, canActivate: [AuthGuard] },
      { path: 'dharmshala-reserve-room', component: DharmshalaReserveRoomComponent},
      { path: 'dharmshala-reservations-list', component: DharmshalaReservationsListComponent},
      { path: 'bhasmaarti-requests-list', component: BhasmaartiRequestsListComponent},
      { path: 'bhasmaarti-requests-detail/:id', component: BhasmaartiRequestDetailsComponent},
      { path: 'bhasmarti-waiting-list', component: LaunchingComponent, canActivate: [AuthGuard] },


      // { path: 'assign-role-to-user/:id/:name', component: AssignRoleToUserComponent, canActivate: [AuthGuard] },

    ]
  },
  {
    path: 'login',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0],
    onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
