import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { PermissionsService } from '../services/permissions.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';


@Component({
  selector: 'app-launching',
  templateUrl: './launching.component.html',
  styleUrls: ['./launching.component.scss']
})
export class LaunchingComponent implements OnInit {

  allWaitingRequests: any = [];
  public from_date = moment(new Date(2024,6,1)).format('YYYY-MM-DD');
  public perPage = 20;
  public totalCount = 0;
  public page = 1;
  ribbonShow = true;

  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent,
    public permissionsService: PermissionsService,
    private modalService: NgbModal

    ) { }

  ngOnInit() {
    let lcl = localStorage.getItem('ribbonCut') || 0;
    this.ribbonShow = lcl == 2 ? false : true;
  
    this.getList('');
  }


  getList(_type) {
    this.appComponent.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/bhasmarti_waiting_requests?date=${from_date}`).subscribe(
      (res: any) => { 
        this.appComponent.hideLoader();
        this.allWaitingRequests = res.data;
        this.totalCount = this.allWaitingRequests.length;
      },
      err => {
        console.log(err);
      }
    );
  }

  viewImage(imageUrl){
    const modalRef = this.modalService.open(ImageDialogComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const imageDialogComponent: ImageDialogComponent = modalRef.componentInstance;
    imageDialogComponent.imageUrl = `${imageUrl}`;
  }


}
