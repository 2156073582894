import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {AppComponent} from '../app.component';
import {Router } from '@angular/router';
import { SortListService } from '../services/sort-list.service';
import * as moment from 'moment';

@Component({
  selector: 'app-add-daily-assignment',
  templateUrl: './add-daily-assignment.component.html',
  styleUrls: ['./add-daily-assignment.component.scss']
})
export class AddDailyAssignmentComponent implements OnInit {

  form: FormGroup;
  formMode = 'Add';
  allDarshanTypes = [];
  userList = [];
  allDepartments = [];
  minDate:any;

  constructor(
    private _formBuilder: FormBuilder, 
    private apiService : ApiService, 
    private appComponent: AppComponent, 
    private router: Router,
    private sortListService: SortListService

    ) {}

  ngOnInit() {
    this.minDate = new Date();
    this.minDate.setDate( this.minDate.getDate() + 1 );
    this.minDate = moment(this.minDate).format('YYYY-MM-DD');
    this.getAllDarshanTypes();
    this.getAllDepartment();
    this.createForm();
  }


  createForm() {
    this.form = this._formBuilder.group({
      date: ['', [Validators.required]],
      darshan_type_id: ['', [Validators.required]],
      department_id: ['', [Validators.required]],
      per_day_limit: ['', [Validators.required]],
    });
   
  }

  
  getAllDepartment() {
    this.apiService.getDataFromServer('/departments').subscribe(
      res => {
        // this.allDepartments = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allDepartments = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }
  

  getAllDarshanTypes() {
    this.apiService.getDataFromServer('/darshan_types').subscribe(
      res => {
        // this.allDarshanTypes = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allDarshanTypes = res;
        });
        
      },
      err => {
        console.log(err);
      }
    );
  }



  saveData() {
    const formObj = this.form.getRawValue();
    formObj.date = moment(formObj.date).format('YYYY-MM-DD');

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postDataToServer('/re_assign_permissions', formObj).subscribe(
        res => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.createForm();
          this.close();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
    //  else {
    //   this.appComponent.showLoader();
    //   this.apiService.putDataToServer(`/visitors/${formObj.id}`, formObj).subscribe(
    //     res => {
    //       this.appComponent.hideLoader();
    //       this.appComponent.showMessageAlert(res['message'], 'success');
    //       this.router.navigate(['/registration-list']);
    //       this.createForm();
    //     },
    //     err => {
    //       this.appComponent.hideLoader();
    //       this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
    //       console.log(err);
    //     }
    //   );
    // }
  }

  close() {
    this.router.navigate(['/re-assignments-of-permissions-7pm']);

  }

}

