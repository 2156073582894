
  <div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
            <div class="row">
                <div class="form-group col-1.5 ml-2">
                  <label>From Date</label>
                  <input class="form-control border-primary" [max] = "to_date" [owlDateTime]="dt" placeholder="Date" name="from_date"
                    [(ngModel)]="from_date" readonly>
                  <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                  <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-1.5 ml-2 mr-2">
                    <label>To Date</label>
                    <input class="form-control border-primary" [min]="from_date" [max] = "maxDate" [owlDateTime]="dt1" placeholder="Date" name="to_date"
                      [(ngModel)]="to_date" readonly>
                    <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                    <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-1.5">
                  <button type="button" style="margin-top: 30px;" class="btn btn-inverse-primary btn-fw top-btn-head" (click)="getList($event)">Load</button>
                </div>
              </div>
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Reference Reg Count Report - 7.00 PM</div>
              <div class="p-2 bd-highlight"   *ngIf = "permissionsService.checkPermission('read_export_late_registrations_visitors_detail')"><button  type="btn"  class="btn btn-primary btn-fw" (click) = "export()">Export</button>
              </div>
            </div>
          </h4>
          <div class="table-responsive"  *ngIf="payment_report && payment_report.data && payment_report.data.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Name</th>
                <th>Darshan Type</th>
                <th>Reference Count</th>
                <th>Visitors Count</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of payment_report?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>{{data?.date}}</td>
                <td>
                  <span *ngIf = "data?.name">{{data?.name}}</span>
                  <span *ngIf = "!data?.name">{{data?.reference_person_name}}</span>
                </td>
                <td>{{data?.darshan_type}}</td>
                <td>{{data?.reference_count}}</td>
                <td>{{data?.visitors_count}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="payment_report && payment_report.data && !payment_report.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
          <ngb-pagination  *ngIf="payment_report && payment_report.data && payment_report.data.length" [collectionSize]="totalCount" [pageSize]="perPage"
          [(page)]="page" (pageChange)="getList('pagination')" [maxSize]="3"
          class="pull-right justify-content-end page-mt-10"
          aria-label="Default pagination"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>

