import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ImageDialogComponent} from '../image-dialog/image-dialog.component';
import {ApiService} from '../services/api.service';
import {debounceTime} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AppComponent} from '../app.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-locker-qr-scan',
  templateUrl: './locker-qr-scan.component.html',
  styleUrls: ['./locker-qr-scan.component.scss']
})
export class LockerQrScanComponent implements OnInit {

  registraionDetail:any;
  // param_qr_id = '';

  registrationNo = '' ;
  public onChangeSearch = new Subject<string>();
  public errorMsg = '';
  public successMsg = '';
  public loading = false;

  constructor(
    private appComponent: AppComponent,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) { 
    this.registrationNo  = this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.onChangeSearch
      .pipe(debounceTime(1500))
      .subscribe(() => {
        if(this.registrationNo){
          // if (this.registraionNo && this.registraionNo.length > 8 && this.registraionNo.substring(0, 18) == 'Registration ID : ') {
          //   this.registraionNo = this.registraionNo.substring(18);
          // }
          this.getVisitorDetail();
        }
      });

      if(this.route.snapshot.params.id){
        this.getVisitorDetail();
      }
  }


  viewImage(imageUrl){
    const modalRef = this.modalService.open(ImageDialogComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const imageDialogComponent: ImageDialogComponent = modalRef.componentInstance;
    imageDialogComponent.imageUrl = `${imageUrl}`;
  }


  getVisitorDetail() {
    this.appComponent.showLoader();
    this.apiService.getDataFromServer(`/locker_assignments/${this.registrationNo}`).subscribe(
      (res: any) => {
        this.registraionDetail = res && res.data ;
        this.registraionDetail.item_submit_at = moment.utc(this.registraionDetail.item_submit_at).local().format('DD-MM-YYYY hh:mm a');

        this.appComponent.hideLoader();
        this.loading = false;
      },
      err => {
        this.errorMsg = err.error.error[0].message;
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        this.loading = false;
      }
    );
  }


  saveData() {

      const formObj = {
        "locker_assignment_id": this.registrationNo
      }
      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/mark_return', formObj).subscribe(
        (res:any) => {
          // this.router.navigate(['/payment', res['data']['id']]);
          this.registraionDetail.is_return  = res['data'].is_return;
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.appComponent.hideLoader();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
  }

  public changeSearchValue(): void {
    this.successMsg = '';
    this.loading = true;
    this.registraionDetail = null;
    this.errorMsg = '';
    this.onChangeSearch.next();
  }

}
