import { Component,OnInit,NgZone, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

import * as moment from 'moment';
declare var Razorpay: any;


@Component({
  selector: 'app-dharmshala-booking-detail',
  templateUrl: './dharmshala-booking-detail.component.html',
  styleUrls: ['./dharmshala-booking-detail.component.scss']
})
export class DharmshalaBookingDetailComponent implements OnInit {
  dharmshalaDetail:any;

  public onChangeSearch = new Subject<string>();
  public param_dharmshala_id = '';
  public errorMsg = '';
  public successMsg = '';
  public loading = false;
  public env: any = environment;
  public bookingDetails;
  public assignRoomsDetails;
  form : FormGroup;
  paymentForm: FormGroup;
  addPayment = false;
  allPaymentTypes = [];
  allPaymentGateways = [];
  extra_bed_charge;

  constructor(
  private appComponent: AppComponent,
  private apiService: ApiService,
  private route: ActivatedRoute,
  private _formBuilder: FormBuilder,
  private router: Router,
  private _ngZone: NgZone
  ) {
    this.param_dharmshala_id = this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.createForm();
    this.getList();
    this.getAllPaymentTypes();
    this.getAllPaymentGateways();
  }

  createForm() {
    this.form = this._formBuilder.group({
      booking_id: [this.param_dharmshala_id, [Validators.required]],
      rooms: this._formBuilder.array([]),
    });
  }

  createPaymentForm() {
    this.paymentForm = this._formBuilder.group({
      booking_id: [this.param_dharmshala_id, [Validators.required]],
      payment_type_id: ['', Validators.required],
      trx_id: ['', Validators.required],
      payment_gateway_id: ['', Validators.required]
    });
  }

  numSequence(n): Array<number> {
    let arr = [];
    for(let i = 1; i <= n; i++){
      arr.push(i);
    }
    return arr;
  }

  getRoomTitle(room_id){
    return this.dharmshalaDetail.dharmashala_details.find((val) => val.room_type_id == room_id);
  }

  getRooms(room_id, i, j){
    let roomsArray = this.dharmshalaDetail.dharmashala_details.find((val) => val.room_type_id == room_id).room_numbers;
    roomsArray = roomsArray.filter((item) => item.is_available);

    const results = roomsArray.filter((element) => !this.form.getRawValue().rooms[i][room_id].some((val, ind) =>
    { return val.room_id == element.id && ind != j }));

    return results;
  }

  getExtraRooms(room_id, i, j){
    let room = this.bookingDetails.booking_rooms.find((val) => val.dharmshala_room_id == room_id).dharmshala_room;
     let extraBed = room.max_guest_capacity - room.room_type.total_beds;
    return this.numSequence(extraBed);
  }


  getList() {
    this.appComponent.showLoader();
    this.apiService.getDataFromServer(`/dharmashalas/${this.param_dharmshala_id}`).subscribe(
      (res: any) => {
        this.dharmshalaDetail = res && res.data && res.data.length ? res.data[0] : null;
        this.appComponent.showLoader();
        this.apiService.getDataFromServer(`/dharmshala_booking/${this.param_dharmshala_id}`).subscribe(
          (res: any) => {
            this.appComponent.hideLoader();
            this.bookingDetails = res.data;
            this.assignRoomsDetails = this.bookingDetails.assign_rooms;
            this.bookingDetails.from_date = moment.utc(this.bookingDetails.from_date).local().format('DD-MM-YYYY');
            this.bookingDetails.to_date = moment.utc(this.bookingDetails.to_date).local().format('DD-MM-YYYY');

            let ind = 0;
            for (let data of this.bookingDetails.booking_rooms) {
              let room_ids = this.form.get('rooms') as FormArray;
              room_ids.push(this._formBuilder.group({
                [data.dharmshala_room_id] : this._formBuilder.array([])
              }));
              for (let room of this.numSequence(data['no_of_rooms'])) {
                let subControl = room_ids['controls'][this.form.controls.rooms['controls'].length - 1]['controls'][data.dharmshala_room_id] as FormArray;
                let assigned_room = this.assignRoomsDetails && this.assignRoomsDetails.length && this.assignRoomsDetails[ind].room_detail_id;
                subControl.push(
                  this._formBuilder.group({
                    room_id: [(assigned_room || ''), Validators.required],
                    no_of_extra_bed: 0
                  })
                );
                ind++;
              }

              
            } 

            // let ind = 0;
            // for(let i=0 ; i< this.assignRoomsDetails.length; i++){
            //   this.form.controls.rooms['controls'][ind]['controls'][this.assignRoomsDetails[i].dharamshala_room.room_type_id]['controls']
            // }

          },
          err => {
            this.errorMsg = err.error.error[0].message;
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          }
        );
      },
      err => {
        this.errorMsg = err.error.error[0].message;
         this.appComponent.hideLoader();
         this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
      }
    );

  }

  getAllPaymentTypes() {
    this.apiService.getDataFromServer('/payment_types').subscribe(
      res => {
        this.allPaymentTypes = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllPaymentGateways() {
    this.apiService.getPublicDataFromServer('/payment_gateways').subscribe(
      (res:any) => {
        this.allPaymentGateways = res['data'].filter((val : any) => val.is_enable);
      },
      err => {
        console.log(err);
      }
    );
  }

  onPaymentModeChange() {
    const paymentType = this.allPaymentTypes.find(res => res.id == this.paymentForm.value.payment_type_id);
    if(paymentType){
      if(paymentType.slug == 'online'){
        this.paymentForm.controls['payment_gateway_id'].setValidators([Validators.required]);
        this.paymentForm.controls['trx_id'].clearValidators();
      } else {
        this.paymentForm.controls['trx_id'].setValidators([Validators.required]);
        this.paymentForm.controls['payment_gateway_id'].clearValidators();
      }
      this.paymentForm.controls['payment_gateway_id'].updateValueAndValidity();
      this.paymentForm.controls['trx_id'].updateValueAndValidity();

    }
  }

  saveData(){

    let form_obj = this.form.getRawValue();
    let rooms = [];
    form_obj.rooms.forEach((val, index) => {
      for (const prop in val) {
        val[prop].forEach((val1, ind1) =>{
          rooms.push(val1);
        });
      }
      });
    form_obj.rooms = rooms;
    this.appComponent.showLoader();
    this.apiService.postDataToServer('/room_assignments', form_obj).subscribe(
      (res:any) => {
        this.extra_bed_charge = res.data.extra_bed_total_amount;
        if(res.data.extra_bed_total_amount && res.data.extra_bed_total_amount > 0){
          this.createPaymentForm();
          this.addPayment = true;
        } else {
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.router.navigate(['/dharmshala-booking']);
        }
        
        setTimeout(() => {
          this.appComponent.hideLoader();
        },200);
      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }

  checkout(room_detail_id){
    let room_obj = {
      "booking_id":this.param_dharmshala_id,
      "room_detail_id":room_detail_id
    };
    this.apiService.postDataToServer('/mark_checkout', room_obj).subscribe(
      (res:any) => {
        this.appComponent.showMessageAlert(res['message'], 'success');
        if(!room_detail_id){
          this.router.navigate(['/dharmshala-booking']);
        }
        this.getList();
        setTimeout(() => {
          this.appComponent.hideLoader();
        },200);
      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }

  savePaymentFormData() {
    let form_obj = this.paymentForm.getRawValue();


   this.appComponent.showLoader();
   this.apiService.postDataToServer('/checkin_payment', form_obj).subscribe(
     (res : any) => {
      if(this.paymentForm.value.payment_type_id == '1' || this.paymentForm.value.payment_type_id == '2' ||
      this.paymentForm.value.payment_type_id == '4'){
        // window.open(res['data'][0]);
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.router.navigate(['/dharmshala-booking']);
      } else {
      if(this.paymentForm.value.payment_gateway_id == 2){
        let _data = res.data;
        let options = {
          "key": this.env.razorpay_key,
          "amount": (_data.amount * 100),
          "currency": _data.currency,
          "name": "Mahakaleshwar Temple Prabhand Samiti Ujjain",
          "order_id": _data.trx_id,
          "handler": (response: any) => {
            this.verifyPayment(response);
          },
          "prefill": {
            "name": _data.firstName,
            "email": _data.email,
            "contact": _data.mobileNo
          }
        };
        var rzp1 = new Razorpay(options);

        rzp1.open();
      } else {
        window.location.replace(res.url);
      }
    }
     },
     err => {
       this.appComponent.hideLoader();
       this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
       console.log(err);
     }
   );
 }

 verifyPayment(_data : any) {
  this.appComponent.showLoader();
  let formObj = {
    razorpay_payment_id: _data.razorpay_payment_id,
    razorpay_order_id: _data.razorpay_order_id,
    razorpay_signature: _data.razorpay_signature
  };

  this.apiService.postPublicDataToServer('/verify_payment', formObj).subscribe(
    (res : any) => {
      this.appComponent.hideLoader();
      this.appComponent.showMessageAlert(res['message'], 'success');
      this._ngZone.run(()=>{
        this.router.navigate(['/payment-status'], {queryParams: {redirect: true , clientTxnId: res['data']['clientTxnId'] }});
      });

    },
    err => {
      this.appComponent.hideLoader();
      this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
      console.log(err);
    }
  );
}

}
