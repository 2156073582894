
  <div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Department</div>
              <div class="form-group  text-center pt-4 mt-2 ml-4" style="margin-left: auto !important;">
                <a title="Add Department" *ngIf = "permissionsService.checkPermission('create_department_user')" routerLink="/add-department"> <i class="fa fa-plus-circle" style="font-size: 35px;cursor: pointer;" aria-hidden="true"></i></a>
              </div>
            </div>
          </h4>

          <div class="filter-wrapper field_space" *ngIf = "form">
            <form   [formGroup]="form" novalidate (ngSubmit)="saveData()">
              <div class="form-group col-12-div">

                <div class="form-group col-4-div">
                    <label >Name*</label>
                    <input disabled class="form-control border-primary"  formControlName="name" name="name" required>
                    <div class="" *ngIf="form.controls.name.touched && !form.controls.name.valid">
                        <div  class="inaild_no" *ngIf=" form.controls.name.errors.pattern">Only Alphabet Allowed</div>
                      </div>
                    <div class="" *ngIf="form.controls.name.touched && !form.controls.name.valid">
                        <div  class="inaild_no" *ngIf=" form.controls.name.errors.required">Name is required!</div>
                    </div>
                </div>
  
      
               <div class="form-group col-4-div">
                <label>Department Max Time*</label>
                <input class="form-control border-primary" [owlDateTime]="dt1" placeholder="Time" name="eod_time" formControlName="eod_time"
                       readonly [owlDateTimeFilter]="myFilter">
                <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                <owl-date-time #dt1 [pickerType]="'timer'" [pickerMode]="'dialog'"></owl-date-time>
                <div class="" *ngIf="form.controls.eod_time.touched && !form.controls.eod_time.valid">
                  <div  class="inaild_no" *ngIf="form.controls.eod_time.errors.required">Department Max Time is required!</div>
                </div>
              </div>
              </div>
  
             <div class="button-wrap">
              <button  type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw">Submit</button>
              <button  type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button>
            </div>
  
            </form>
          </div>
          
          <ng-container *ngIf="!form">
          <div class="table-responsive"  *ngIf="department && department.data && department.data.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Department</th>
                <th>Bhasmarti Limit</th>
                <th>Protocol Darshan Limit</th>
                <th>EOD Time</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of department?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>
                  <span *ngIf = "data?.name">{{data?.name}}</span>
                </td>
                <td>{{data?.dept_per_day_permission_limit}}</td>
                <td>{{data?.per_day_protocol_darshan_limit}}</td>
                <td>{{data?.max_time_allowed?.eod_time}}</td>
                <td class="fixed-side">
                  <a title="Edit" *ngIf = "permissionsService.checkPermission('update_departments')"   [routerLink]="['/add-department/', data.id]"><i class="fa fa-pencil" ></i></a>
                  <a title="Update Time" *ngIf = "permissionsService.checkPermission('update_departments')" class="ml-4" (click)="createForm(data)"><i class="fa fa-clock-o" ></i></a>
                  <a title="Delete" *ngIf = "permissionsService.checkPermission('delete_departments')" class="ml-4" (click)="deleteDepartment(data)"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="department && department.data && !department.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
        </ng-container>
        </div>
      </div>
    </div>
  </div>

