import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { SortListService } from 'src/app/services/sort-list.service';
import { CapturePhotoPopoverComponent } from 'src/app/capture-photo-popover/capture-photo-popover.component';

@Component({
  selector: 'app-add-donation',
  templateUrl: './add-donation.component.html',
  styleUrls: ['./add-donation.component.scss']
})
export class AddDonationComponent implements OnInit {

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";
  
  
  form!: FormGroup;
  paymentForm!: FormGroup;
  allDarshanTypes = [];
  allStates = [];
  allCities = [];
  minDate = moment(new Date()).format('YYYY-MM-DD');
  maxDate = ''
  timeDisable = false;
  formMode = 'Add';
  visitors_basic_details: any;
  dateErrorMsg = '';
  visitor_type = [];
  id_proof_type_list = []
  user_roles:any;
  darshan_type_id = '';
  donatePurposeData = [];
  countriesData = [];
  user_picture_file_name = '';
  cheque_picture_file_name = '';

  id_proof_name = '';
  payment_type_id = '';
  checkLengthStatus = false;
  imageUrlLoading = false;
  idProofLoading = false;
  receiptNo = '';
  donationDetail:any;
  addPayment = false;
  paymentType = '';
  bankName = '';
  bankBranchName = '';
  chequeNo = '';

  public env: any = environment;

  allRelations = [];
  allPaymentTypes:any = [];

  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private appComponent: AppComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sortListService: SortListService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getAllCountries();
    this.getIdProofTypesList();
    this.getAllDonatePurpose();
    this.getAllPaymentTypes();
  }


  createForm() {
    this.form = this._formBuilder.group({
      first_name: ['', [Validators.required,this.removeSpaces,this.textValidation()]],
      last_name: ['', [Validators.required,this.removeSpaces,this.textValidation()]],
      father_name: ['', [this.removeSpaces,this.textValidation()]],
      mobile_no: ['', [Validators.pattern('[5-9]\\d{9}')]],
      email: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      id_proof_type_id : [''],
      id_proof_number  : ['', [this.removeSpaces]],
      pan_card_no  : ['',[Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]{1}$')]],
      image_url: ['', []],
      id_proof_image_url: ['', []],
      state_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      zip_code: ['', []],
      country_id: ['', [Validators.required]],
      address_line_1:  ['', []],
      donation_purpose_id: ['', [Validators.required]],
      amount: ['', [Validators.required]],
    });
  }

  
  onChangePeopleProofNumber() {
    // const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if (this.form.controls['id_proof_number'] && this.form.controls['id_proof_number'].value && !this.form.controls['id_proof_number'].value.replace(/\s/g, '').length) {
      this.form.controls['id_proof_number'].setValue('');
    }
  }

  textValidation() {  
    return (control: AbstractControl): { [key: string]: any } | null =>  
       !this.extractNumber(control.value)
            ? null : {number: control.value};
  }

  extractNumber(string) {
    let numArray = string.split('').map(item => {
      if (typeof +item === 'number' && !isNaN(+item)) return +item
    })
    return +numArray.join('')
  }



 
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }

  getAllPaymentTypes() {
    this.apiService.getDataFromServer('/payment_types').subscribe(
      res => {
        this.allPaymentTypes = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }


  onIdProofTypeChange() {
    this.checkLengthStatus = false;
    this.form.controls['id_proof_number'].clearValidators();
    let aadharCardRgx = '^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$';
    let drivingLicenseRgx = '^([A-Z]{2})(\\d{2}|\\d{3})[a-zA-Z]{0,1}-(\\d{4})-(\\d{7})$';
    let electionCommissionIDRgx = '^([a-zA-Z]){3}([0-9]){7}?$';
    let passportRgx = '^[A-Z][0-9]{8}$';
    this.form.controls['id_proof_number'].setValue('');

    if(this.form.controls['id_proof_type_id'].value == 1){
      this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(aadharCardRgx)]);
      this.checkLengthStatus = true;
      }
      if(this.form.controls['id_proof_type_id'].value == 2){
        this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(passportRgx)]);
      }
      if(this.form.controls['id_proof_type_id'].value == 3){
        this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(drivingLicenseRgx)]);
      }
      if(this.form.controls['id_proof_type_id'].value == 4){
        this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(electionCommissionIDRgx)]);
      }
      this.form.controls['id_proof_number'].updateValueAndValidity();
  }

    createSpaceProofNumber(){
      if(this.form.controls['id_proof_type_id'].value == 1) {
        let id_proof_number = this.form.controls['id_proof_number'].value;
        id_proof_number = id_proof_number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
         this.form.controls['id_proof_number'].setValue(id_proof_number);
      }
  }



  getAllStates(id:any) {
    this.apiService.getDataFromPublicServer(`/public/api/v1/states?country_id=${id}`).subscribe(
      (res:any) => {
        // this.allStates = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allStates = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllDonatePurpose() {
    this.apiService.getDataFromPublicServer('/public/api/v1/donation_purposes').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'title').then(res => {
          this.donatePurposeData = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllCountries() {
    this.apiService.getDataFromPublicServer('/public/api/v1/countries').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.countriesData = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  createPaymentForm(){
    this.paymentForm = this._formBuilder.group({
      bankBranchName: [''],
      bankName: [''],
      receiptNo:  ['', []],
      chequeNo:  ['', []],
      payment_type_id: ['', [Validators.required]],
      chequeFile: ['', []],
    });
    this.addPayment = true;
  }

  onPaymentModeChange() {
    const paymentType = this.allPaymentTypes.find(res => res.id == this.paymentForm.value.payment_type_id);
    if(paymentType){
      this.paymentType = paymentType.slug;
      switch (this.paymentType) {
        case 'cheque':
          this.paymentForm.controls['receiptNo'].setValue('');

          this.paymentForm.controls['bankBranchName'].setValidators([Validators.required,this.removeSpaces,this.textValidation()]);
          this.paymentForm.controls['bankName'].setValidators([Validators.required,this.removeSpaces,this.textValidation()]);
          this.paymentForm.controls['chequeNo'].setValidators([Validators.required]);
          this.paymentForm.controls['chequeFile'].setValidators([Validators.required]);
          this.paymentForm.controls['receiptNo'].setValidators([]);

          this.paymentForm.controls['bankName'].updateValueAndValidity();
          this.paymentForm.controls['chequeNo'].updateValueAndValidity();
          this.paymentForm.controls['chequeFile'].updateValueAndValidity();
          this.paymentForm.controls['bankBranchName'].updateValueAndValidity();
          this.paymentForm.controls['receiptNo'].updateValueAndValidity();

          break;
          case 'online':
          
          break;
          case 'cash':
            this.paymentForm.controls['bankBranchName'].setValue('');
            this.paymentForm.controls['bankName'].setValue('');
            this.paymentForm.controls['chequeNo'].setValue('');
            this.paymentForm.controls['chequeFile'].setValue('');
            this.cheque_picture_file_name = '';

            this.paymentForm.controls['bankBranchName'].setValidators([]);
            this.paymentForm.controls['bankName'].setValidators([]);
            this.paymentForm.controls['chequeNo'].setValidators([]);
            this.paymentForm.controls['chequeFile'].setValidators([]);
            this.paymentForm.controls['receiptNo'].setValidators([Validators.required]);
  
            this.paymentForm.controls['bankName'].updateValueAndValidity();
            this.paymentForm.controls['chequeNo'].updateValueAndValidity();
            this.paymentForm.controls['chequeFile'].updateValueAndValidity();
            this.paymentForm.controls['bankBranchName'].updateValueAndValidity();
            this.paymentForm.controls['receiptNo'].updateValueAndValidity();
          break;
      }
    }
  }

  getAllCities(_id:any) {
    this.apiService.getDataFromPublicServer('/public/api/v1/cities/?state_id=' + _id).subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allCities = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getIdProofTypesList() {
    this.apiService.getPublicDataFromServer('/id_proof_types').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.id_proof_type_list = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }


  saveData() {
    const formObj = this.form.getRawValue();

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/donations', formObj).subscribe(
        (res:any) => {
          // this.router.navigate(['/payment', res['data']['id']]);
          this.id_proof_name = '';
          this.user_picture_file_name = '';
          this.createPaymentForm();
          this.donationDetail = res.data;
          this.appComponent.hideLoader();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }


  onChangeDate() {
    const date = moment(this.form.value.date_of_visit).format('YYYY/MM/DD');
    const currentDate = moment(new Date()).format('YYYY/MM/DD');
    if(this.form.value.darshan_type_id == 1 && date == currentDate && this.user_roles != 'general_bhasmaarti') {
      this.dateErrorMsg = "You can't select today date for bhasma aarti"
    } else {
      this.dateErrorMsg = '';
    }
  }


  onStateChange(_value:any){
    this.getAllCities(_value.target.value);
  }

  onCountryChange(_value:any) {
    this.form.controls['state_id'].setValue('');
    this.form.controls['city_id'].setValue('');

    this.getAllStates(_value.target.value);
  }



  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onFileChange(event:any) {
    this.imageUrlLoading = true;

   this.form.value.image_url

    let document_id = this.form.value.image_url;
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      // this.user_picture_file_name = file.name;

      this.postDocumentToServer(event.target.files[0], document_id , 'image_url');

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }

  onFileChangeCheque(event:any) {
    // this.imageUrlLoading = true;
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.paymentForm.controls['chequeFile'].setValue(event.target.files[0]);
      reader.onload = (e:any) => {
        this.cd.markForCheck();
        const image = new Image();
        this.cheque_picture_file_name = e.target.result;
      };
    }
  }

  

  
  getIdProofFileDetails(event:any) {
    this.idProofLoading = true;
    this.form.value.id_proof_image_url
    let document_id = this.form.value.id_proof_image_url;

    let reader = new FileReader();

    if (event.target['files'] && event.target['files'].length) {
      const [file] = event.target['files'];
      reader.readAsDataURL(file);
      // this.id_proof_name = file.name;

      this.postDocumentToServer(event.target['files'][0], document_id, 'id_proof_image_url');

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }

  onClickCapturePhoto() {
    const modalRef = this.modalService.open(CapturePhotoPopoverComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const capturePhotoPopoverComponent: CapturePhotoPopoverComponent = modalRef.componentInstance;
    capturePhotoPopoverComponent.data = null;
    
    modalRef.result.then((result:any) => {
      if (result) {
        this.apiService.getBlobFileFromServer(result).subscribe(res => {
          let blob:any = new Blob([res], { type: res.type.toString() });
          const imageFile = new File([blob], `cheque-image`, { type: 'image/png' });
          this.paymentForm.controls['chequeFile'].setValue(imageFile);
          this.cheque_picture_file_name = result;
        });
    
      }
    });
  }

  saveDataOfPayment() {
    const paymentData = {
      service_id: this.donationDetail.id,
      payment_type_id: this.paymentForm.value.payment_type_id,
      cheque_no: this.paymentForm.value.chequeNo,
      receiptNo: this.paymentForm.value.receiptNo,
      bank_name: this.paymentForm.value.bankName,
      bank_branch_name: this.paymentForm.value.bankBranchName,
      file: this.paymentForm.value.chequeFile,
      service_type: 'Donation', 
      is_from_mobile: 'false'   
    }
    const formData = new FormData();
    formData.append('service_id', paymentData.service_id);
    formData.append('payment_type_id', paymentData.payment_type_id);
    formData.append('cheque_no', paymentData.cheque_no);
    formData.append('bank_name', paymentData.bank_name);
    formData.append('trx_id', paymentData.receiptNo);
    formData.append('bank_branch_name', paymentData.bank_branch_name);
    formData.append('file', paymentData.file);
    formData.append('service_type', paymentData.service_type);
    formData.append('is_from_mobile', paymentData.is_from_mobile);

    this.appComponent.showLoader();
    this.apiService.postPublicDataToServer('/donations_payment', formData).subscribe(
      (res:any) => {
        this.appComponent.showMessageAlert(res['message'], 'success');
        this.appComponent.hideLoader();
        this.close();
      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }




  postDocumentToServer(file_data:any, document_id:any,keyName?:any) {
    const formData = new FormData();
    formData.append('file', file_data);

    this.apiService.postPublicDataToServer('/add_image', formData).subscribe(res => {
      let responsedata :  any = res;
      if(responsedata){
        this.form.controls[keyName].setValue(responsedata.data);
        this.user_picture_file_name = keyName == 'image_url' ? responsedata.data : this.user_picture_file_name;
        this.imageUrlLoading = false;
        this.id_proof_name = keyName != 'image_url' ? responsedata.data : this.id_proof_name;
        this.idProofLoading = false;
      }
    }, (error) => {
      this.imageUrlLoading = false;
      this.idProofLoading = false;

    });
  }

  close() {
    this.router.navigate(['donation-list']);
  }

}
