<div class="content-wrapper login-wrapper">
  <div class="card login-popup">
    <div class="card-body">
      <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
        <a class="navbar-brand brand-logo" >
          <img src="./../../assets/images/login_logo.jpeg" style="max-width: 160px;width: 100%;" alt="logo" />
        </a>
      </div>

      <h4 class="text-center login_head">Mahakal Visitor's Management System </h4>

      <hr>

      <h4 class="card-title">Login</h4>
      <form class="forms-sample" [formGroup]="loginForm" novalidate (ngSubmit)="login()">
        <div class="form-group">
          <label for="exampleInputEmail1">Login Id</label>
          <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Login ID"
                  formControlName="email">
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Password</label>
          <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"
                  formControlName="password">
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-success w-100" [disabled]="!loginForm.valid">Login</button>
        </div>
      </form>
    </div>
</div>
</div>
