import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {AppComponent} from '../app.component';
import {ActivatedRoute, Router } from '@angular/router';
import { SortListService } from '../services/sort-list.service';


@Component({
  selector: 'app-assign-permission',
  templateUrl: './assign-permission.component.html',
  styleUrls: ['./assign-permission.component.scss']
})
export class AssignPermissionComponent implements OnInit {

  form: FormGroup;
  formMode = 'Add';
  allPermission = [];
  userList = [];

  constructor(
    private _formBuilder: FormBuilder, 
    private apiService : ApiService, 
    private appComponent: AppComponent, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sortListService: SortListService

    ) {}

  ngOnInit() {
    const id = this.activatedRoute.snapshot.params['id'];
    if(id) {
      this.appComponent.showLoader();
      this.getAssignPermissionRole(id);
    }
    this.getAllPermission();
  }

  createForm(data, id) {
    this.form = this._formBuilder.group({
      role_name: [data.name, [Validators.required]],
      permission_ids: [data.permissions ? data.permissions : '', [Validators.required]],
      role_id: [id ? id : '', [Validators.required]],
    });
    this.appComponent.hideLoader();
  }
  

  getAssignPermissionRole(id) {
    this.apiService.getDataFromServer(`/role_permissions/${id}`).subscribe(
      (res:any) => {
        if(res.data) {
          res.data.permissions.map(res => {
            res.childs = [];
            delete res.pivot;
          })
        }
        this.createForm(res.data , id)
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllPermission() {
    this.apiService.getDataFromServer('/get_all_permissions').subscribe(
      res => {
        this.allPermission = [];
        res['data'].map(permission => {
          this.allPermission.push(permission);
          permission.childs.map(child => {
            this.allPermission.push(child);
          });
        });

        this.sortListService.sortList(this.allPermission,'name').then(res => {
          this.allPermission = res;
        });
        // this.allPermission = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }

  saveData() {
    const formObj = this.form.getRawValue();
    let permission_ids = [];
    formObj.permission_ids.map(res => {
      permission_ids.push(res.id);
    });
    formObj.permission_ids = permission_ids;

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postDataToServer('/assign_role_permissions', formObj).subscribe(
        res => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.close();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }

  close() {
    this.router.navigate(['/permissions-role']);

  }

}
