import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';


@Component({
  selector: 'app-verfiy-payment-status',
  templateUrl: './verfiy-payment-status.component.html',
  styleUrls: ['./verfiy-payment-status.component.scss']
})
export class VerfiyPaymentStatusComponent implements OnInit {
  WIDTH = 440;
  HEIGHT = 340;
  clientTxnId:any;
  public paymentDetail:any;
  public loader = false;
  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.loader = true;
    this.getPaymentDetail();
  }

  public closeModal() {
    this.activeModal.close('');
  }
  getPaymentDetail() {
    this.apiService.getPublicDataFromServer(`/payment_status?redirect=true&clientTxnId=${this.clientTxnId}`).subscribe(
      (res:any) => {
        this.paymentDetail = res['data'];
        this.loader = false;
      },
      err => {
        this.loader = false;
        this.paymentDetail = {
          spRespStatus: 'FAILED'
        }
        console.log(err);
      }
    );
  }

}

