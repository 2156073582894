import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  permission_load = false;
  userPermissionsArr: any = [];
  userAllDetails: any;
  permissionsobj;
  permissionsUrl = {
    'registration_list': 'manage_visitors',
    'registration': 'create_visitors',
    'payment': 'create_visitor_payment',
    'registration_success': 'manage_visitors',
    'daily_quota': 'read_daily_quota',
    'foot_fall_report': 'read_foot_fall_report',
    'reference_reg_count_report': 'read_reference_reg_count_report',
    'reference_reg_count_report_7pm': 'read_late_registrations_ref_counts',
    'visitors_detail_by_ref_person': 'read_visitors_detail_by_ref_person',
    'visitors_detail_by_ref_person_7pm': 'read_late_reg_visitors_details',
    'payment_report': 'read_payment_report',
    're_assignments_of_permissions_7pm': 'read_re_assign_permissions',
    'department': 'manage_departments',
    'add_department': 'create_departments',
    'user_to_department_mapping': 'manage_department_user',
    'assign_department': 'create_department_user',
    'darshan_type': 'manage_darshan_types',
    'add_darshan_type': 'create_darshan_types',
    'user_to_darshan_type_mapping': 'manage_darshan_type_user',
    'assign_darshan_type': 'create_darshan_type_user',
    'reset_user_password': 'update_reset_user_password',
    'roles': 'manage_roles',
    'add_roles': 'manage_roles',
    'permissions-role': 'manage_role_permissions',
    'assign_permission': 'create_assign_role_permissions',
    'user_list': 'manage_users',
    'assign_role_to_user': 'create_assign_user_roles',
    // 'daily_assignments': 'read_re_assign_permissions',
    'add_daily_assignments': 'create_re_assign_permissions',
    'restricted_date': 'manage_restricted_dates',
    'generate_id_card': 'read_id_cards',
    'item_categories' : 'manage_item_categories',
    'add_item_categories': 'create_item_categories',
    'item_units': 'manage_item_units',
    'items': 'manage_items',
    'item_transactions': 'manage_item_transactions',
    'donation_list': 'read_donations'
  }

  constructor(private getApiService: ApiService) {
  }


  setPermissions(_prmsn) {
    this.userPermissionsArr = _prmsn;
  }

  getUrlPermission(_url) {
    return this.permissionsobj[_url];
  }


  
  getUserDetailsSrvr(_page) {
    _page = _page.split('/');
    _page = _page[0];

    let permission = _page.replace(/-/gi, '_');
    const url = this.permissionsUrl[permission] || permission;
    let promise = new Promise((resolve, reject) => {
      this.getApiService.getUserDetail('/user_detail')
        .toPromise()
        .then(
          (res:any) => {
            this.userAllDetails = res;
            if (res.permissions) {
              this.userPermissionsArr = res.permissions;
            }
            if (this.userPermissionsArr.includes(url) || this.userPermissionsArr.includes('do_everything')) {
              resolve(true);
            } else {
              resolve(false);
            }
          },
          msg => {
            reject(msg);
          }
        );
    });
    return promise;
  }

 async isAccessPage(_page) {
    return await this.getUserDetailsSrvr(_page).then(res => {
      console.log(res)
      return res;
    });
  }

  public checkPermission(permission) {
    if(this.userPermissionsArr) {
      const isExist = this.userPermissionsArr.find(res => res == permission ||  res == 'do_everything');
      if(isExist) {
        return  true;
      } else {
        return false
      }
    }
  }

  hasAnyRights() {
    let key = '';
    try {

      this.userPermissionsArr.some((val) => {
        key = Object.keys(this.permissionsobj).find(key => this.permissionsobj[key] === val);
        if (key != null || key != undefined) {
          return key;
        }
      });
      return key;

    } catch (e) {
      console.log('Error Exception UserService isAccessPage ' + e);
    }
  }
}




