import {AfterViewChecked, ChangeDetectorRef, Component} from '@angular/core';
import {environment} from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked{

  loader;

  message = '';
  alert_type = 'success';
  isAlertShow = false;
  urlName:any = '';

constructor(
  private changeDetector : ChangeDetectorRef, 
  private titleService: Title,
  private router : Router,
  private location: Location,
  ){
    this.router.events.subscribe((val) => {
      this.urlName = this.location.path();
    });
    this.titleService.setTitle("Mahakal Visitor's Monitoring System");
}

  ngAfterViewChecked(){
  this.changeDetector.detectChanges();
}


  showLoader(){
    this.loader =  true;
  }

  hideLoader(){
    this.loader = false;
  }


  showMessageAlert(_message, _type){
  this.message = _message;
  this.alert_type = _type;
  this.isAlertShow = true;
    setTimeout(() => this.isAlertShow = false, 3000);

  }


}

