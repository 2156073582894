<form   [formGroup]="form" novalidate  (ngSubmit)="saveData()" style="display: inline-block;width: 100%;height: auto;">
    <div style="width: 100%; float: left; padding-left: 20px;box-sizing: border-box;">
        <div class="card" >
            <div class="card-body upper-header">
                <div class="table-responsive">
                    <h4 class="card-title card_heading">
                        <div class="d-flex bd-highlight">
                            <div class="p-2 flex-fill bd-highlight">Dharmshala Details</div>
                        </div>
                    </h4>
                    <ul style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;">
                        <li class="wrapper_label">
                            <label>Dharmshala Name</label>
                            <small>{{dharmshalaDetail?.name}}</small>
                        </li>

                        <li class="wrapper_label">
                            <label>Phone Number</label>
                            <small>{{dharmshalaDetail?.phone_no}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>Address</label>
                            <small>{{dharmshalaDetail?.address}}</small>
                        </li>
                    </ul>

                    <h4 class="card-title card_heading">
                        <div class="d-flex bd-highlight">
                            <div class="p-2 flex-fill bd-highlight">Visitor Details</div>
                        </div>
                    </h4>
                    <ul style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;">
                        <li class="wrapper_label">
                            <label>Name</label>
                            <small>{{bookingDetails?.first_name}} {{bookingDetails?.last_name}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>Mobile Number</label>
                            <small>{{bookingDetails?.mobile_no}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>State</label>
                            <small>{{bookingDetails?.state?.name}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>City</label>
                            <small>{{bookingDetails?.city?.name}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>From Date</label>
                            <small>{{bookingDetails?.from_date}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>To Date</label>
                            <small>{{bookingDetails?.to_date}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>Number of Days</label>
                            <small>{{bookingDetails?.no_of_days}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>Address</label>
                            <small>{{bookingDetails?.address}}</small>
                        </li>
                    </ul>
                    

                    <ng-container *ngIf="bookingDetails?.status != 'Checked-Out' ">
                        <h4 class="card-title card_heading">
                            <div class="d-flex bd-highlight">
                                <div class="p-2 flex-fill bd-highlight">Room Distribution</div>
                            </div>
                        </h4>
    
                        <div class="room_distribution_wrap" formArrayName="rooms">
                            <ul>
                                <ng-container *ngFor="let data of bookingDetails?.booking_rooms; let i = index" [formGroupName]="i" >
                                    <ng-container *ngIf="bookingDetails?.status == 'Pending' ">
                                        <li [formGroupName]="data.dharmshala_room_id" *ngFor="let row of numSequence(data.no_of_rooms); let j = index">
                                          <label>{{getRoomTitle(data?.dharmshala_room_id)?.room_type?.title}}</label>
                                          <ng-container [formGroupName]="j">
                                          <select formControlName="room_id" class="room_sel">
                                              <option [value]="''">Select Room Number</option>
                                              <option [value]="rooms.id" *ngFor="let rooms of getRooms(data?.dharmshala_room_id, i, j)">{{rooms?.room_no}}</option>
                                          </select>
                                          <select formControlName="no_of_extra_bed" class="room_sel">
                                            <option [value]="0">NO Extra Bed</option>
                                            <option [value]="rooms" *ngFor="let rooms of getExtraRooms(data?.dharmshala_room_id, i, j)">{{rooms}}</option>
                                          </select>
                                          <span *ngIf="form.value.rooms[i][data.dharmshala_room_id][j].no_of_extra_bed">
                                            <label>{{data.dharmshala_room.extra_bed_charges}} x {{form.value.rooms[i][data.dharmshala_room_id][j].no_of_extra_bed}} =
                                            {{data.dharmshala_room.extra_bed_charges * form.value.rooms[i][data.dharmshala_room_id][j].no_of_extra_bed}}rs</label>
                                        </span>
                                         </ng-container>
                        

                                        </li>
    
                                    </ng-container>
                                </ng-container>
                                    <ng-container *ngIf="bookingDetails?.status == 'Checked-In' ">
                                        <li class="heading_lbls">
                                            <label>Room Type</label>
                                            <label>Room Number</label>
                                          </li>
                                        <li *ngFor="let row of assignRoomsDetails; let i = index">
                                          <label><b>{{row?.room_detail?.dharamshala_room?.room_type?.type}}</b></label>
                                          <label><b>{{row?.room_detail?.room_no}}</b></label>
                                          <button [disabled]="row?.check_out_date_time"  type="button"  class="btn btn-primary btn-fw"
                                          (click)="checkout(row?.room_detail_id)">Check Out</button>
                                        </li>
    
                                    </ng-container>
                            </ul>
                        </div>
                        
                        
                        ​<div class="button-wrap" *ngIf="!addPayment">
                            <button *ngIf="bookingDetails?.status != 'Checked-In'" type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw mr-2">Submit</button>
                            <button *ngIf="bookingDetails?.status == 'Checked-In'"  type="button"  class="btn btn-primary btn-fw"
                            (click)="checkout()">Booking Check Out</button>
                        </div>
                    </ng-container>

                    <form   [formGroup]="paymentForm" *ngIf = "addPayment">
                    <ng-container >
                        <h4 class="card-title card_heading">
                            <div class="d-flex bd-highlight">
                                <div class="p-2 flex-fill bd-highlight">Add Payment Detail</div>
                            </div>
                        </h4>
    
                        <div class="room_distribution_wrap" >
                            <div>
                                <label *ngIf="extra_bed_charge > 0" class="total_charge"><span style="color: red;">*</span>Total Charges Of Extra Bed Is <span>{{extra_bed_charge}} rs</span></label>
                            </div>
                            <div class="form-group col-4-div">
                                <label >Payment Mode*</label>
                                <select class="form-control border-primary" (change)="onPaymentModeChange()"  formControlName="payment_type_id" name="payment_type_id">
                                  <option [value]="''">select</option>
                                  <ng-container *ngFor="let data of allPaymentTypes">
                                    <option [value]="data.id">{{data.type}}</option>
                                  </ng-container>
                                </select>
                                <div class="" *ngIf="paymentForm.controls.payment_type_id.touched && !paymentForm.controls.payment_type_id.valid">
                                  <div  class="inaild_no" *ngIf="paymentForm.controls.payment_type_id.errors.required">Payment Mode is required!</div>
                                </div>
                              </div>
                          
                              <div class="form-group col-4-div" *ngIf="paymentForm.controls.payment_type_id.value != '3'">
                                <label >Transaction No. / Receipt No.*</label>
                                <input type="text" class="form-control border-primary" formControlName="trx_id" name="trx_id">
                                <div class="" *ngIf="paymentForm.controls.trx_id.touched && !paymentForm.controls.trx_id.valid">
                                  <div  class="inaild_no" *ngIf="paymentForm.controls.trx_id.errors.required">Transaction No. / Receipt No. is required!</div>
                                </div>
                              </div>
                        </div>
                        <div class="room_distribution_wrap" >
                              <div class="form-group col-4-div" *ngIf="paymentForm.controls.payment_type_id.value == '3'">
                                <label >Payment Gateway*</label>
                                <select class="form-control border-primary"  formControlName="payment_gateway_id" name="payment_gateway_id" >
                                  <option [value]="''">select</option>
                                  <option *ngFor="let data of allPaymentGateways" [value]="data.id">{{data.name}}</option>
                                </select>
                                <div class="" *ngIf="form?.controls?.payment_gateway_id?.touched && !form?.controls?.payment_gateway_id?.valid">
                                  <div class="inaild_no" *ngIf=" form?.controls?.payment_gateway_id?.errors?.required">Payment Gateway is
                                    required!</div>
                                </div>
                              </div>
                        </div>
                        
                        
                        ​<div class="button-wrap">
                            <button  *ngIf="addPayment" type="submit" (click)="savePaymentFormData()" [disabled]="!paymentForm.valid" class="btn btn-primary btn-fw mr-2">Submit</button>
                     </div>
                    </ng-container>
                    </form>

                    <ng-container *ngIf="bookingDetails?.status == 'Checked-Out' ">
                        <p class="note">Room was checked out by Visitor.</p>
                    </ng-container>
                    
                </div>
            </div>
        </div>
    </div>
</form>
                        
                            
    
<!--        
      ​<div class="button-wrap">
      <button  type="submit" [disabled]="!paymentForm.valid" (click) = "saveDataOfPayment()" class="btn btn-primary btn-fw">Submit</button>

   -->
