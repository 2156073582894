
<div class="modal-header py-1">
    <!-- <h5 class="modal-title"></h5> -->
    <button type="button" 
      class="close" 
      aria-label="Close" 
      (click)="activeModal.close('Close click')" 
      [disabled]="loading">
      <span aria-hidden="true">&times;</span>
    </button> 
  </div>
  <div class="modal-body" style="padding: 0px 20px !important;">
  <form [formGroup]="form">
    <h2 class="text-center"><i style="font-size: 75px; color: white;" class="fa fa-check-circle"></i></h2>
    <h2 class="text-center" style="color: #666464;">Verification</h2>
    <p class="text-center" style="color: #979393; font-weight: 600;font-size: 13px;">
      Code sent to the registered mobile number
    </p>
    <div class="form-group" style="margin-top: 1rem;">
        <label>Enter OTP</label>
        <input type="text" (keypress)="keyPressNumbers($event)" minlength="4" maxlength="4" class="form-control form-control-sm" formControlName="otp">
      </div>
  </form>
  </div>
  <div class="modal-footer">
    <button  type="submit" [disabled]="!form.valid" class="btn btn-success w-100" (click) = "submit()">Verify</button>
    <p style = "font-size: 12px;">Don't receive the verification OTP? <a style="color: #00ce68 !important; cursor: pointer;" (click) = "reSendOTP()">Resend again</a></p>
    <p *ngIf = "errorMsg" class="error_msg">{{errorMsg}}</p>
    <p class="success_msg text-center"> {{reSendOtpMsg}}</p>
  </div>
    