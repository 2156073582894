import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { ApiService } from '../services/api.service';
import {PermissionsService} from '../services/permissions.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public user;
  public roles;
  public permissions;


  constructor(
    private router: Router,
    public _prmsn: PermissionsService,
    public apiService: ApiService
    ) {
    this.getUserDetail();
  }

  ngOnInit() {
  }

  getUserDetail() {
    this.apiService.getUserDetail('/user_detail').subscribe((res:any) => {
      this.roles = res.roles;
      this.permissions = res.permissions;
    });
  }

  logout(){
    localStorage.clear();
    this.router.navigate(['login']);
  }

  public checkPermission(permission) {
    if(this.permissions) {
      const isExist = this.permissions.find(res => res == permission ||  res == 'do_everything');
      if(isExist) {
        return  true;
      } else {
        return false
      }
    }
  }

  checkRoles(role) {
   const result = this.roles.find(res => res == role || res == 'report_admin' || res == 'special_protocol');
   return result ? true : false
  }

}
