
<div class="row" >
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header" >

          <div class="filter-wrapper" *ngIf = "form">
            <form   [formGroup]="form" novalidate (ngSubmit)="saveData()">
              <h5>Locker Booking Details</h5>
              <div class="form-group col-12-div">
                <div class="form-group col-4-div">
                  <label >Name*</label>
                  <input  class="form-control border-primary"   formControlName="name" name="name" required>
                  <div class="" *ngIf="form.controls.name.touched && !form.controls.name.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.name.errors.number">Numbers Are Not Allowed!</div>
                  </div>

                  <div class="" *ngIf="form.controls.name.touched && !form.controls.name.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.name.errors.required">Name is required!</div>
                  </div>
                </div>

                <div class="form-group col-4-div">
                  <label >Contact No.*</label>
                  <input class="form-control border-primary" type="text" (keypress)="keyPressNumbers($event)" maxlength="10" minlength="10" formControlName="mobile_no" name="mobile_no" required>
                  <div class="" *ngIf="form.controls.mobile_no.touched && !form.controls.mobile_no.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.mobile_no.errors.pattern">Invalid Mobile Number</div>
                  </div>
                  <div class="" *ngIf="form.controls.mobile_no.touched && !form.controls.mobile_no.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.mobile_no.errors.required">Contact Number is required!</div>
                  </div>
                </div>

                <div class="form-group col-4-div">
                    <label >Locker Location*</label>
                    <select class="form-control border-primary"  name="locker_location_id" formControlName="locker_location_id"
                    >
                      <option  value="">select</option>
                      <option [value] ="type.id" *ngFor = "let type of locker_list">{{type.name}}</option>
                    </select>
                    <div class="" *ngIf="form.controls.locker_location_id.touched && !form.controls.locker_location_id.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.locker_location_id.errors.required">Locker is required!</div>
                    </div>
                  </div>

                <div class="form-group col-4-div">
                    <label >Locker No.*</label>
                    <input class="form-control border-primary" type="text" maxlength="4" formControlName="locker_no" name="locker_no" (keypress)="keyPressNumbers($event)">
                    <div class="" *ngIf="form.controls.locker_no.touched && !form.controls.locker_no.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.locker_no.errors.required">Locker Number is required!</div>
                    </div>
                  </div>

                  <div class="form-group col-4-div">
                    <label >Item Qty*</label>
                    <input class="form-control border-primary" type="text" maxlength="10" formControlName="item_qty" name="item_qty" required>
                    <div class="" *ngIf="form.controls.item_qty.touched && !form.controls.item_qty.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.item_qty.errors.required">Item Qty is required!</div>
                    </div>
                  </div>

                <div class="form-group col-4-div">
                    <label >Person's Image*</label>

                    <div class="upload-btn-wrapper" >
                      <button class="btn" type="button"  (click)="onClickCapturePhoto('person_image_url')" [ngClass] = "visitor_picture_file_name ? 'upload_file': ''">Take Person's Photo</button>

                        <img class="mt-2"  *ngIf = "visitor_picture_file_name" style="border: 1px solid #c1bdbd;"  src="{{visitor_picture_file_name}}" height="125"/>

                      <div class="lds-ellipsis" style="margin-top: 80px;" *ngIf = "imageUrlLoading">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    </div>
                  </div>

                <div class="form-group  col-4-div">
                <label >Item's Image*</label>
                <div class="upload-btn-wrapper" >
                    <button class="btn" type="button" (click)="onClickCapturePhoto('items_image_url')" [ngClass] = "id_proof_name ? 'upload_file': ''">Take Item's photo</button>

                    <img class="mt-2" *ngIf = "id_proof_name"  src="{{id_proof_name}}" height="125"/>
                    <div class="lds-ellipsis" style="margin-top: 80px;" *ngIf = "idProofLoading">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                </div>
                </div>


              </div>

              ​<div class="button-wrap">
              <button  type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw">Submit</button>
              <!-- <button  type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button> -->
            </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
