
<div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header" >
  
          <div class="filter-wrapper field_space" *ngIf = "form">
            <form   [formGroup]="form" novalidate (ngSubmit)="saveData()">
              <div class="form-group col-12-div">

                <div class="form-group col-4-div">
                    <label>Date*</label>
                    <input class="form-control border-primary" [owlDateTime]="dt2" [min]="minDate" placeholder="Date" name="date" formControlName="date"
                           readonly>
                    <span [owlDateTimeTrigger]="dt2" class="date-ico"><i class="fa fa-calendar"></i></span>
                    <owl-date-time #dt2 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
  
                <div class="form-group col-4-div">
                    <label >Darshan Type*</label>
                    <select class="form-control border-primary"  name="darshan_type_id" formControlName="darshan_type_id">
                      <option [value]="''">select</option>
                      <option *ngFor="let data of allDarshanTypes" [value]="data.id">{{data.name}}</option>
                    </select>
                    <div class="" *ngIf="form.controls.darshan_type_id.touched && !form.controls.darshan_type_id.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.darshan_type_id.errors.required">Darshan Type is required!</div>
                    </div>
                </div>

                <div class="form-group col-4-div">
                    <label >Department*</label>
                    <select class="form-control border-primary"  name="department_id" formControlName="department_id">
                      <option [value]="''">select</option>
                      <option *ngFor="let data of allDepartments" [value]="data.id">{{data.name}}</option>
                    </select>
                    <div class="" *ngIf="form.controls.department_id.touched && !form.controls.department_id.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.department_id.errors.required">Department is required!</div>
                    </div>
                </div>

                <div class="form-group col-4-div">
                    <label >Limit*</label>
                    <input class="form-control border-primary" type="number"  formControlName="per_day_limit" name="per_day_limit" required>
                  </div>
              </div>
  
              ​<div class="button-wrap">
              <button  type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw">Submit</button>
              <button  type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button>
            </div>
  
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  