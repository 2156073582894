<div class="modal-content modal-content">
    <div class="modal-header py-1" style="margin-top: 15px; ">
        <h4 class="modal-title" *ngIf = "!data">Capture Photo</h4>
        <h4 class="modal-title" *ngIf = "data">View Photo</h4>

        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-0">
        <div class="video-container">

            <img style="cursor: pointer;max-width: 450px;" *ngIf = "data" [src]="data" height="340"/>

            <video  *ngIf = "!data"
              [class.show]="!isCaptured"
              [class.hide]="isCaptured"
              #video
              id="video"
              [width]="WIDTH"
              [height]="HEIGHT"
              autoplay
            ></video>
            <canvas  *ngIf = "!data"
              [class.show]="isCaptured"
              [class.hide]="!isCaptured"
              #canvas
              id="canvas"
              [width]="WIDTH"
              [height]="HEIGHT"
            ></canvas>
          </div>
          
          <div class="snap-container">
          
          </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">
            Cancel
        </button>
        <button class="btn btn-primary" *ngIf="!isCaptured && !data" (click)="capture()">
           Capture
        </button>
        <button class="btn btn-primary" *ngIf="!isCaptured && data" (click)="editImage()">
            Edit
         </button>
    </div>
</div>