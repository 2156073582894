<div style="display: inline-block;width: 100%;height: auto;" *ngIf ="!addPayment">
  <div style="width: 100%; float: left; padding-left: 20px;box-sizing: border-box;">
    <ul style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;">
      <li class="wrapper_label">
        <label>Registration No</label>
        <small>{{visitorDetails?.data?.pnr_no}}</small>
      </li>
      <li class="wrapper_label">
        <label style="">Name</label>
        <small style="">{{visitorDetails?.data?.first_name}} {{visitorDetails?.data?.last_name}}</small>
      </li>
      <li class="wrapper_label">
        <label>Mobile Number</label>
        <small>{{visitorDetails?.data?.mobile_no}}</small>
      </li>
      <li class="wrapper_label">
        <label>Number of People</label>
        <small>{{visitorDetails?.data?.no_of_persons}}</small>
      </li>
      <li class="wrapper_label">
        <label>Type of visit</label>
        <small>{{visitorDetails?.data?.darshan_type.name}}</small>
      </li>
      <li class="wrapper_label">
        <label>Date of visit</label>
        <small>{{visitorDetails?.data?.date_of_visit}}</small>
      </li>
      <li class="wrapper_label">
        <label>Time of visit</label>
        <small>{{visitorDetails?.data?.time_of_visit}}</small>
      </li>
      <li class="wrapper_label">
        <label>City</label>
        <small>{{visitorDetails?.data?.city?.name}}</small>
      </li>
      <li class="wrapper_label">
        <label>State</label>
        <small>{{visitorDetails?.data?.state.name}}</small>
      </li>
      <li class="wrapper_label" *ngIf = "visitorDetails?.data?.payment_status == 'Paid'">
        <label>Payment Status</label>
        <small>Already {{visitorDetails?.data?.payment_status}}</small>
      </li>
      <li class="wrapper_label">
        <button  *ngIf = "visitorDetails?.data?.payment_status == 'Paid'" style="margin-top: 50px;"
        class="btn btn-primary btn-fw" (click)="viewPass()">VIEW ENTRY PASS</button>
      </li>
    </ul>
    <ul style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;" *ngIf = "visitorDetails?.data?.payment_status != 'Paid'">
      <div class="form-group col-4-div wrapper_label" style="padding-right: 10px;box-sizing: border-box;">
        <label >Payment Mode*</label>
        <select class="form-control border-primary"  name="payment_type_id" [(ngModel)]="payment_type_id" required>
          <option [value]="''">select</option>
          <option *ngFor="let data of allPaymentTypes" [value]="data.id">{{data.type}}</option>
        </select>
      </div>

      <div *ngIf = "payment_type_id != 2 && payment_type_id != 3" class="form-group col-4-div wrapper_label" style="padding-right: 10px;box-sizing: border-box;">
        <label >Transaction No. / Receipt No.*</label>
        <input class="form-control border-primary" type="text" (ngModelChange)= "removeSpaces()"  [(ngModel)]="transaction_no" name="transaction_no" required>
        <div  class="inaild_no" *ngIf="transaction_validation_msg">{{transaction_validation_msg}}</div>
      </div>

      <div class="form-group col-4-div wrapper_label">
        <label >Amount*</label>
        <small>{{visitorDetails?.data?.slot_configuration.per_person_charges}}*{{visitorDetails?.payment_details?.total_payable_person}} = {{visitorDetails?.payment_details?.total_amount}}</small>
      </div>

      <div class="button-wrap">
        <!-- <button  type="submit"  [disabled]="payment_type_id == '' || transaction_no == '' || transaction_validation_msg" class="btn btn-primary btn-fw" style="margin-right: 10px;">Print</button> -->
        <button  type="submit" (click) = "saveData()" *ngIf = "payment_type_id != 2 && payment_type_id != 3"  [disabled]="payment_type_id == '' || transaction_no == '' || transaction_validation_msg" class="btn btn-primary btn-fw" style="margin-right: 10px;">Print</button>
        <button  type="submit" (click) = "addPaymentDetail()" *ngIf = "payment_type_id == 2 || payment_type_id == 3"  [disabled]="payment_type_id == ''" class="btn btn-primary btn-fw" style="margin-right: 10px;">Next</button>
        <button  type="button" class="btn btn-secondary btn-fw" routerLink="/registration-list">Close</button>
      </div>
    </ul>
  </div>
</div>

<div class="row" *ngIf = "addPayment">
  <div class="col-lg-12 grid-margin">
    <div class="card">
      <h4 class="card-title">
        <div class="d-flex bd-highlight">
          <div  class="p-2 flex-fill bd-highlight">Add Payment Details</div>
        </div>
      </h4>
      <div class="card-body upper-header" >

        <div class="filter-wrapper" *ngIf = "form">
          <form   [formGroup]="form" (ngSubmit)="saveDataOfPayment()">
            <div class="form-group col-12-div">

              <div class="form-group col-3-div">
                  <label >First Name*</label>
                  <input [pattern]="'^[a-zA-Z \-\']+'" class="form-control border-primary"  formControlName="payer_first_name" name="payer_first_name" required>
                  <div class="" *ngIf="form.controls.payer_first_name.touched && !form.controls.payer_first_name.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.payer_first_name.errors.pattern">Only Alphabet Allowed</div>
                    </div>
                  <div class="" *ngIf="form.controls.payer_first_name.touched && !form.controls.payer_first_name.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.payer_first_name.errors.required">First Name is required!</div>
                  </div>
              </div>
              <div class="form-group col-3-div">
                <label >Last Name*</label>
                <input [pattern]="'^[a-zA-Z \-\']+'" class="form-control border-primary"  formControlName="payer_last_name" name="payer_last_name" required>
                <div class="" *ngIf="form.controls.payer_last_name.touched && !form.controls.payer_last_name.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.payer_last_name.errors.pattern">Only Alphabet Allowed</div>
                  </div>
                <div class="" *ngIf="form.controls.payer_last_name.touched && !form.controls.payer_last_name.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.payer_last_name.errors.required">Last Name is required!</div>
                </div>
              </div>

              <div class="form-group col-3-div">
                <label >Contact No.*</label>
                <input class="form-control border-primary" type="text" (keypress)="keyPressNumbers($event)" maxlength="10" minlength="10" formControlName="payer_contact" name="payer_contact" required>
                <div class="" *ngIf="form.controls.payer_contact.touched && !form.controls.payer_contact.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.payer_contact.errors.pattern">Invalid Mobile Number</div>
                </div>
                <div class="" *ngIf="form.controls.payer_contact.touched && !form.controls.payer_contact.valid">
                  <div  class="inaild_no" *ngIf="form.controls.payer_contact.errors.required">Contact Number is required!</div>
                </div>
              </div>

              <div class="form-group col-3-div">
                <label >Email*</label>
                <input  class="form-control border-primary" type="email" formControlName="payer_email" name="payer_email" required>
                <div class="" *ngIf="form.controls.payer_email.touched && !form.controls.payer_email.valid">
                    <div  class="inaild_no" *ngIf="form.controls.payer_email.errors.required">Email is required!</div>
                </div>
                <div class=""  *ngIf="form.controls.payer_email.errors && form.controls.payer_email.errors.pattern && form.controls.payer_email.touched && !form.controls.payer_email.errors.required">
                  <div  class="inaild_no">Email must be a valid email address!</div>
                </div>
              </div>

              <div class="form-group col-3-div">
                <label >Address*</label>
                <textarea class="form-control border-primary" formControlName="payer_address" name="payer_address"  rows="4" required></textarea>
                <div class="" *ngIf="form.controls.payer_address.touched && !form.controls.payer_address.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.payer_address.errors.required">Address is required!</div>
                </div>
              </div>

              <div class="form-group col-3-div">
                <label >Payment Gateway*</label>
                <select class="form-control border-primary"  formControlName="payment_gateway_id" name="payment_gateway_id" >
                  <option [value]="''">select</option>
                  <option *ngFor="let data of allPaymentGateways" [value]="data.id">{{data.name}}</option>
                </select>
                <div class="" *ngIf="form?.controls?.payment_gateway_id?.touched && !form?.controls?.payment_gateway_id?.valid">
                  <div class="inaild_no" *ngIf=" form?.controls?.payment_gateway_id?.errors?.required">Payment Gateway is
                    required!</div>
                </div>
              </div>
            </div>

            ​<div class="button-wrap">
            <button  type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw">Next</button>
            <button  type="button" class="btn btn-secondary btn-fw" (click)="close()">Previous</button>
          </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>

