import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-assing-roles',
  templateUrl: './assing-roles.component.html',
  styleUrls: ['./assing-roles.component.scss']
})
export class AssingRolesComponent implements OnInit {

  form?: FormGroup | any;
  loading =  false;
  roleData: any;
  userList = [];

  constructor(
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    public activeModal: NgbActiveModal,
    ) { }

  ngOnInit(): void {
    this.getUser();
    this.CreateForm();

  }

  CreateForm() {
    this.form = this._formBuilder.group({
      user_id: ['', Validators.required],
      role_ids: [[this.roleData.id], Validators.required],
      role_name: [this.roleData.name, Validators.required],
    });
  }

  getUser() {
    this.apiService.getDataFromServer(`/users`).subscribe(
      (res: any) => {
        this.userList = res.data;
      },
      err => {
        console.log(err);
      }
    );
  }

  
  public closeModal() {
    this.activeModal.close('');
  }
  

  submit() {
    this.apiService.postDataToServer('/assign_user_roles',this.form.value).subscribe((res: any) => {
      this.activeModal.close('success');
    }, (error) => {
      console.log(error , 'error');
    });
  }
}
