import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortListService {

  constructor() { }

  async sortList(data, key) {
   return await data.sort((a, b) =>  a[key].localeCompare(b[key], 'en'))
  }
}
