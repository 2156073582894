
<div class="row" *ngIf = "!addPayment">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header" >
  
          <div class="filter-wrapper" *ngIf = "form">
            <form   [formGroup]="form" novalidate (ngSubmit)="saveData()">
              <h5>Add Personal Details</h5>
              <div class="form-group col-12-div">
                <div class="form-group col-4-div">
                  <label >Name*</label>
                  <input  class="form-control border-primary"   formControlName="name" name="name" required>
                  <div class="" *ngIf="form.controls.name.touched && !form.controls.name.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.name.errors.number">Numbers Are Not Allowed!</div>
                  </div>
  
                  <div class="" *ngIf="form.controls.name.touched && !form.controls.name.valid">
                    <div class="inaild_no" *ngIf=" form.controls.name.errors.required">Name is required!</div>
                  </div>
                </div>
  
                <div class="form-group col-4-div">
                  <label >Age*</label>
                  <input class="form-control border-primary" type="text" (keypress)="keyPressNumbers($event)" maxlength="3" minlength="1"  formControlName="age" name="age" required>
                  <div class="" *ngIf="form.controls.age.touched && !form.controls.age.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.age.errors.number">Invalid Age!</div>
                  </div>
                  <div class="" *ngIf="form.controls.age.touched && !form.controls.age.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.age.errors.required">Age is required!</div>
                  </div>
                </div>

                <div class="form-group col-4-div">
                    <label >Gender*</label>
                    <select class="form-control border-primary" name="gender" formControlName="gender">
                      <option value="">select</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    <div class="" *ngIf="form.controls.gender.touched && !form.controls.gender.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.gender.errors.required">Gender is required!</div>
                    </div>
                  </div>
                  
                  <div class="form-group col-4-div">
                    <label >Aadhaar No.*</label>
                    <input class="form-control border-primary" [maxlength]="checkLengthStatus ? 14 : ''" (keypress) = "createSpaceProofNumber()" (change) = "createSpaceProofNumber()"   type="text" formControlName="aadhaar_no" name="aadhaar_no" required>
                    <div class="" *ngIf="form.controls.aadhaar_no.touched && !form.controls.aadhaar_no.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.aadhaar_no.errors.pattern">Invalid Aadhaar Number</div>
                    </div>
                    <div class="" *ngIf="form.controls.aadhaar_no.touched && !form.controls.aadhaar_no.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.aadhaar_no.errors.required">Aadhaar Number is required!</div>
                    </div>
                  </div>

  
                <div class="form-group col-4-div">
                  <label >Mobile No.*</label>
                  <input class="form-control border-primary" type="text" (keypress)="keyPressNumbers($event)" maxlength="10" minlength="10" formControlName="mobile_no" name="mobile_no" required>
                  <div class="" *ngIf="form.controls.mobile_no.touched && !form.controls.mobile_no.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.mobile_no.errors.pattern">Invalid Mobile Number</div>
                  </div>
                  <div class="" *ngIf="form.controls.mobile_no.touched && !form.controls.mobile_no.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.mobile_no.errors.required">Mobile Number is required!</div>
                  </div>
                </div>
              </div>
              <h5>Add Address Details</h5>
              <div class="form-group col-12-div">

                <div class="form-group col-4-div">
                  <label >Address Line 1*</label>
                  <textarea class="form-control border-primary" id="exampleFormControlTextarea1" rows="2" formControlName="address_line_1" name="address_line_1"></textarea>
                  <div class="" *ngIf="form.controls.address_line_1.touched && !form.controls.address_line_1.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.address_line_1.errors.required">Address line 1 is required!</div>
                  </div>
                </div>

                <div class="form-group col-4-div">
                    <label >Address Line 2</label>
                    <textarea class="form-control border-primary" id="exampleFormControlTextarea1" rows="2" formControlName="address_line_2" name="address_line_2"></textarea>
  
                    <!-- <div class="" *ngIf="form.controls.address_line_2.touched && !form.controls.address_line_2.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.address_line_2.errors.required">Address line 2 is required!</div>
                    </div> -->
                  </div>
  
                <div class="form-group col-4-div">
                  <label >State*</label>
                  <select class="form-control border-primary"  (change)="onStateChange($event)" name="state_id" formControlName="state_id">
                    <option value="">select</option>
                    <option *ngFor="let data of allStates" [value]="data.id">{{data.name}}</option>
                  </select>
                  <div class="" *ngIf="form.controls.state_id.touched && !form.controls.state_id.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.state_id.errors.required">State is required!</div>
                  </div>
                </div>
  
                <div class="form-group col-4-div">
                  <label >City*</label>
                  <select class="form-control border-primary" name="city_id" formControlName="city_id">
                    <option value="">select</option>
                    <option *ngFor="let data of allCities" [value]="data.id">{{data.name}}</option>
                  </select>
                  <div class="" *ngIf="form.controls.city_id.touched && !form.controls.city_id.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.city_id.errors.required">City is required!</div>
                  </div>
                </div>

                <div class="form-group col-4-div">
                  <label >Zip/Pin Code*</label>
                  <input class="form-control border-primary" type="text" (keypress)="keyPressNumbers($event)" maxlength="7" minlength="5" formControlName="pin_code" name="pin_code" required>
                  <div class="" *ngIf="form.controls.pin_code.touched && !form.controls.pin_code.valid">
                    <div  class="inaild_no" *ngIf="form.controls.pin_code.errors && form.controls.pin_code.errors.minlength &&  5 > form.controls.pin_code.errors.minlength.actualLength">Zip/Pin Code Minimum Length Is 5!</div>
                  </div>
                  <div class="" *ngIf="form.controls.pin_code.touched && !form.controls.pin_code.valid">
                    <div  class="inaild_no" *ngIf="form.controls.pin_code.errors.required">Zip/Pin Code is required!!</div>
                  </div>
                </div>
              </div>
              <h5>Upload Aadhaar Picture</h5>

              <div class="form-group col-12-div">

                <div class="form-group  col-4-div">
                    <label >Aadhaar Front Photo*</label>
                    <div class="upload-btn-wrapper" >
                      <button class="btn" [ngClass] = "aadhar_img.aadhaar_front ? 'upload_file': ''">Aadhaar Front</button>
                      <input type="file"  name="document" accept="image/*" class="form-control"
                      (change)="onFileChange($event, 'aadhaar_front')" style="padding: 0 !important;" />
                      <img *ngIf="aadhar_img.aadhaar_front" [src]="aadhar_img.aadhaar_front" height="125" />
                      <!-- <span class="file_name" *ngIf = "id_proof_name">{{id_proof_name}}</span> -->
                      <div class="lds-ellipsis" style="margin-top: 80px;" *ngIf = "idProofLoading">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                    </div>
                </div>

                <div class="form-group  col-4-div">
                    <label >Aadhaar Back Photo*</label>
                    <div class="upload-btn-wrapper" >
                        <button class="btn" [ngClass] = "aadhar_img.aadhaar_back ? 'upload_file': ''">Aadhaar Back</button>
                        <input type="file"  name="document" accept="image/*" class="form-control"
                        (change)="onFileChange($event, 'aadhaar_back')" style="padding: 0 !important;" />
                        <img *ngIf="aadhar_img.aadhaar_back" [src]="aadhar_img.aadhaar_back" height="125" />
                        <!-- <span class="file_name" *ngIf = "id_proof_name">{{id_proof_name}}</span> -->
                        <div class="lds-ellipsis" style="margin-top: 80px;" *ngIf = "idProofLoading">
                          <span></span>
                          <span></span>
                          <span></span>
                      </div>

                    </div>
                </div>

            
              </div>
  
              ​<div class="button-wrap">
              <button  type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw">Submit</button>
              <button  type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button>
            </div>
  
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>