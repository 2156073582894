
  <div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">User To Department Mapping</div>
              <div class="form-group  text-center pt-4 mt-2 ml-4" style="margin-left: auto !important;">
                <a title="Assign Department" *ngIf = "permissionsService.checkPermission('create_department_user')" routerLink="/assign-department"> <i class="fa fa-plus-circle" style="font-size: 35px;cursor: pointer;" aria-hidden="true"></i></a>
              </div>
            </div>
          </h4>
          <div class="table-responsive"  *ngIf="department && department.data && department.data.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>User</th>
                <th>Department</th>
                <th>Action</th>

              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of department?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>
                    <span *ngIf = "data?.user">{{data?.user?.username}}</span>
                </td>
                <td>
                  <span *ngIf = "data?.department">{{data?.department?.name}}</span>
                </td>
                <td class="fixed-side">
                    <a title="Delete" *ngIf = "permissionsService.checkPermission('delete_department_user')" class="ml-4" (click)="deleteDepartment(data)"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                  </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="department && department.data && !department.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
        </div>
      </div>
    </div>
  </div>

