import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { PermissionsService } from '../services/permissions.service';
import { saveAs } from 'file-saver';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-locker-booking-list',
  templateUrl: './locker-booking-list.component.html',
  styleUrls: ['./locker-booking-list.component.scss']
})
export class LockerBookingListComponent implements OnInit {

  public payment_report: any;
  allLockerBooking: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  public payment_status = 'Pending';
  public status_booking = '';
  public searchValue = '';
  locker_location_id = '';
  locker_list = [];
  public onChangeSearch = new Subject<string>();
  public perPage = 20;
  public totalCount = 0;
  public page = 1;
  public maxDate = ''; // moment(new Date()).format('YYYY-MM-DD');

  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent,
    public permissionsService: PermissionsService

    ) { }

  ngOnInit() {
    this.onChangeSearch
    .pipe(debounceTime(1000))
    .subscribe(() => {
      this.getList('');
    });

    this.getList('');
    this.getLockerLocationsList();
  }

  public changeSearchValue(): void {
    this.onChangeSearch.next();
  }

  getLockerLocationsList() {
    this.apiService.getPublicDataFromServer('/locker_locations').subscribe(
      (res:any) => {
          this.locker_list = res.data;
          console.log(this.locker_list);
      },
      err => {
        console.log(err);
      }
    );
  }

  getList(_type) {
    this.appComponent.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/locker_assignments?locker_location_id=${this.locker_location_id}&page=${this.page}&perpage=${this.perPage}&from_date=${from_date}&to_date=${to_date}&status=${this.status_booking}&search=${this.searchValue}`).subscribe(
      (res: any) => { 
        this.appComponent.hideLoader();
        this.allLockerBooking = res;
        this.allLockerBooking.data.map(res => {
          res.from_date = moment.utc(res.from_date).local().format('DD-MM-YYYY');
          res.to_date = moment.utc(res.to_date).local().format('DD-MM-YYYY');
        });
        this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }

  pdfDownload(_url){
    window.open(_url);
  }


}