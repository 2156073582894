
  <div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
            <div class="row">
                <div class="form-group col-1.5 ml-2">
                  <label>From Date</label>
                  <input class="form-control border-primary" [max] = "to_date" [owlDateTime]="dt" placeholder="Date" name="from_date"
                    [(ngModel)]="from_date" readonly>
                  <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                  <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-1.5 ml-2 mr-2">
                    <label>To Date</label>
                    <input class="form-control border-primary" [min]="from_date" [max] = "maxDate" [owlDateTime]="dt1" placeholder="Date" name="to_date"
                      [(ngModel)]="to_date" readonly>
                    <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                    <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-2 mr-2">
                  <label >Entry Type</label>
                  <select class="form-control border-primary"  name="entry_type"
                  [(ngModel)]="entry_type">
                    <option [value]="''">select</option>
                    <option [value]="'Manual'">Manual</option>
                    <option [value]="'Scheduler'">Scheduler</option>
                  </select>
                </div>
                <div class="form-group col-1.5">
                  <button type="button" style="margin-top: 30px;" class="btn btn-inverse-primary btn-fw top-btn-head" (click)="getList($event)">Load</button>
                </div>
              </div>
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Re Assignments Permissions 7.00 PM</div>
              <div class="form-group  text-center pt-4 mt-2 ml-4" style="margin-left: auto !important;">
                <a title="Add Daily Assignment" *ngIf = "permissionsService.checkPermission('create_re_assign_permissions')" routerLink="/add-daily-assignments"> <i class="fa fa-plus-circle" style="font-size: 35px;cursor: pointer;" aria-hidden="true"></i></a>
              </div>
            </div>
          </h4>
          <div class="table-responsive" *ngIf="reAssignmentPermission && reAssignmentPermission.data && reAssignmentPermission.data.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Department</th>
                <th>Entry Type</th>
                <th>Re Assign Limits</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of reAssignmentPermission?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>{{data?.date}}</td>
                <td>{{data?.department?.name}}</td>
                <td>{{data?.entry_type}}</td>
                <td>{{data?.re_assign_limits}}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="p-12" *ngIf="reAssignmentPermission && reAssignmentPermission.data && !reAssignmentPermission.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
        </div>
      </div>
    </div>
  </div>

