<div class="row">
    <div class="col-lg-12 grid-margin">
        <div class="card">
            <div class="card-body upper-header">
                <div class="row">
                    <div class="form-group col-2 ">
                        <label>From Date</label>
                        <input class="form-control border-primary" [max]="to_date" [owlDateTime]="dt" placeholder="Date"
                            name="from_date" [(ngModel)]="from_date" readonly>
                        <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                        <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                    </div>
                    <div class="form-group col-2 ">
                        <label>To Date</label>
                        <input class="form-control border-primary" [min]="from_date" [max]="maxDate" [owlDateTime]="dt1"
                            placeholder="Date" name="to_date" [(ngModel)]="to_date" readonly>
                        <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                        <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                    </div>

                    <div class="form-group col-2">
                        <label >Payment Status</label>
                        <select class="form-control border-primary" name="payment_status" [(ngModel)]="payment_status">
                            <option [value]="''">All</option>
                            <option value="Paid">Paid</option>
                            <option value="Pending">Pending</option>
                          </select>
                    </div>

                    <div class="form-group col-1.5">
                        <button type="button" style="margin-top: 30px;"
                            class="btn btn-primary btn-fw top-btn-head" (click)="getList($event)">Load</button>
                    </div>
                </div>
                <h4 class="card-title">
                    <div style="display: inline-block; width: 100%;" class="bd-highlight">
                        <div style="float: left;align-items: center; min-width: 70%;" class="p-2 flex-grow-1 bd-highlight">Pujan Bookings</div>
                        <div style="float: right; " class="bd-highlight searchValue">
                            <button type="button" class="btn btn-primary btn-fw top-btn-head" (click)="exportList()">Export</button>
                        </div>

                    </div>
                </h4>
                <div class="table-responsive"
                    *ngIf="pujanBookingList && pujanBookingList.data && pujanBookingList.data.length">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Father Name</th>
                                <th>Puja Type</th>
                                <th>Mobile Number</th>
                                <th>Puja Amount</th>
                                <th>Date of Puja </th>
                                <th>Payment Status </th>
                                <th>Transaction ID </th>
                                <th>Pujari Name</th>
                                <th>Pujari Mobile No.</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Id Proof </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of pujanBookingList?.data; let i = index">
                                <td>
                                    <span *ngIf="page == 1">{{i+1}}</span>
                                    <span *ngIf="page > 1">{{((page - 1 )*15) + (i+1)}}</span>
                                </td>
                                <td>{{data?.first_name}} {{data?.last_name}}</td>
                                <td>{{data?.father_name}}</td>
                                <td>{{data?.puja_type?.puja_name}}</td>
                                <td>{{data?.mobile_no}}</td>
                                <td>{{data?.amount}}</td>
                                <td>{{data?.date_of_puja}}</td>
                                <td>{{data?.payment_status}}</td>
                                <td>
                                    <span *ngIf="data?.payment_status == 'Paid'">{{data?.payment_details[0].trx_id}}</span>
                                    <span *ngIf="data?.payment_status != 'Paid'">Unpaid</span>
                                    <!-- <span *ngIf="data?.payment_status == 'Free'">Free</span> -->
                                </td>
                                <td>{{data?.priest?.name}}</td>
                                <td>{{data?.priest?.mobile_no}}</td>
                                <td>{{data?.city?.name}}</td>
                                <td>{{data?.state?.name}}</td>
                                <td>{{data?.id_proof_type?.name}} ({{data?.id_proof_number}})</td>
                                <td><a  title="Print"><i class="fa fa-print ml-4" aria-hidden="true"
                                    (click)="onPrintClick(data)"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="p-12" *ngIf="pujanBookingList && pujanBookingList.data && !pujanBookingList.data.length">
                    <span class="ng-star-inserted no_data">No Data found.</span>
                </div>
                <ngb-pagination *ngIf="pujanBookingList && pujanBookingList.data && pujanBookingList.data.length" [collectionSize]="totalCount" [pageSize]="perPage"
                [(page)]="page" (pageChange)="getList('pagination')" class="pull-right justify-content-end page-mt-10"
          aria-label="Default pagination"></ngb-pagination>
            </div>
        </div>
    </div>
</div>