<div class="text-center" style="width: 100%">
    <i *ngIf = "paymentDetail?.spRespStatus == 'SUCCESS'"  style="font-size: 100px;margin: 20px" class="mdi mdi-check-circle-outline"></i>
    <i *ngIf = "paymentDetail?.spRespStatus == 'PENDING'" style="font-size: 100px;margin: 20px" class="fa fa-exclamation-circle"></i>
    <i *ngIf = "paymentDetail?.spRespStatus == 'FAILED'" style="font-size: 100px;margin: 20px" class="fa fa-times-circle"></i>
  
      <h1 style="margin: 20px">PAYMENT {{paymentDetail?.spRespStatus}}!</h1>
      <!-- <p style="margin: 20px" *ngIf = "paymentDetail?.spRespStatus == 'SUCCESS'">Thank you for your transaction!</p>
      <p style="margin: 20px" *ngIf = "paymentDetail?.spRespStatus == 'PENDING'">Your transaction is pending!</p>
      <p style="margin: 20px" *ngIf = "paymentDetail?.spRespStatus == 'FAILED'">Your transaction is failed!</p> -->
  
  
      <p *ngIf = "paymentDetail?.spRespStatus == 'SUCCESS'"
       style="border: 1px solid; width: 41%;margin: 0 auto; padding: 30px">
       Thank you for your transaction {{paymentDetail?.firstName}} {{paymentDetail?.lastName}}. We have received your payment of ₹{{paymentDetail?.amount}}.
      </p>
  
      <p *ngIf = "paymentDetail?.spRespStatus == 'PENDING'"
       style="border: 1px solid; width: 41%;margin: 0 auto; padding: 30px">
       Your transaction is in progress. <br>
       Please <b> do not refresh </b> the page and wait while we are
       processing your payment. This can take a few minutes.
      </p>
  
      <p *ngIf = "paymentDetail?.spRespStatus == 'FAILED'"
       style="border: 1px solid; width: 41%;margin: 0 auto; padding: 30px">
       Your transaction is failed. <br>
       Please try again
      </p>
      <button *ngIf = "paymentDetail?.spRespStatus == 'SUCCESS' && e_pass" style="margin-top: 50px;" class="btn btn-primary btn-fw" (click) = "viewPass()">VIEW ENTRY PASS</button>
    </div>
  
  <div class="mt-5"  *ngIf = "paymentDetail?.spRespStatus == 'SUCCESS'" style="display: inline-block;width: 100%;height: auto;">
      <h4 class="card-title">
        <div class="d-flex bd-highlight">
          <div  class="p-2 pb-4 flex-fill bd-highlight">Payment Details</div>
        </div>
      </h4>
      <div style="width: 100%; float: left; padding-left: 20px;box-sizing: border-box;">
        <ul style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;">
          <li class="wrapper_label">
            <label>First Name</label>
            <small>{{paymentDetail?.firstName}}</small>
          </li>
          <li class="wrapper_label">
            <label >Last Name</label>
            <small >{{paymentDetail?.lastName}}</small>
          </li>
          <li class="wrapper_label">
            <label>Mobile Number</label>
            <small>{{paymentDetail?.mobileNo}}</small>
          </li>
          <li class="wrapper_label">
            <label>Email</label>
            <small>{{paymentDetail?.email}}</small>
          </li>
          <li class="wrapper_label">
            <label>Transaction Id</label>
            <small>{{paymentDetail?.SabPaisaTxId}}</small>
          </li>
          <li class="wrapper_label">
            <label>Amount</label>
            <small>{{paymentDetail?.amount}}</small>
          </li>
          <li class="wrapper_label">
            <label>Pay Mode</label>
            <small>{{paymentDetail?.payMode}}</small>
          </li>
          <li class="wrapper_label">
            <label>Payment Status</label>
            <small>{{paymentDetail?.spRespStatus}}</small>
          </li>
        </ul>
      </div>
    </div>