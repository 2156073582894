
<div class="row">
  <div class="col-lg-12 grid-margin">
    <div class="card">
      <div class="card-body upper-header" >

        <div class="filter-wrapper field_space" *ngIf = "form">
          <form   [formGroup]="form" novalidate (ngSubmit)="saveImageData()">
            <div class="form-group col-12-div">
              <div class="form-group col-4-div">
                <label >First Name*</label>
                <input [pattern]="'^[a-zA-Z \-\']+'" class="form-control border-primary" (ngModelChange) = "onChangeName()"  formControlName="first_name" name="first_name" required>
                <div class="" *ngIf="form.controls.first_name.touched && !form.controls.first_name.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.first_name.errors.number">Numbers Are Not Allowed!</div>
                </div>

                <div class="" *ngIf="form.controls.first_name.touched && !form.controls.first_name.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.first_name.errors.required">First Name is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label >Last Name*</label>
                <input [pattern]="'^[a-zA-Z \-\']+'" class="form-control border-primary" (ngModelChange) = "onChangeName()"  formControlName="last_name" name="last_name" required>
                <div class="" *ngIf="form.controls.last_name.touched && !form.controls.last_name.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.last_name.errors.number">Numbers Are Not Allowed!</div>
                </div>
                <div class="" *ngIf="form.controls.last_name.touched && !form.controls.last_name.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.last_name.errors.required">Last Name is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label >Contact No.*</label>
                <input class="form-control border-primary" type="text" (keypress)="keyPressNumbers($event)" maxlength="10" minlength="10" formControlName="mobile_no" name="mobile_no" required>
                <div class="" *ngIf="form.controls.mobile_no.touched && !form.controls.mobile_no.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.mobile_no.errors.pattern">Invalid Mobile Number</div>
                </div>
                <div class="" *ngIf="form.controls.mobile_no.touched && !form.controls.mobile_no.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.mobile_no.errors.required">Contact Number is required!</div>
                </div>
              </div>

              <!-- <div class="form-group col-4-div">
                <label >Email ID*</label>
                <input class="form-control border-primary" type="email" formControlName="email" name="email" required>
              </div> -->

              <!-- <div class="form-group col-4-div">
                <label >Alternate Contact No.</label>
                <input class="form-control border-primary" type="text" (keypress)="keyPressNumbers($event)" maxlength="10" minlength="10" formControlName="alternate_mobile_no" name="alternate_mobile_no">
              </div> -->


              <div class="form-group col-4-div">
                <label >Darshan Type*</label>
                <select class="form-control border-primary" (change)="onDarshanChange($event)"  name="darshan_type_id" formControlName="darshan_type_id">
                  <option [disabled]="formMode == 'Edit' ? true: false" [value]="''">select</option>
                  <option [disabled]="formMode == 'Edit' ? true: false" *ngFor="let data of allDarshanTypes" [value]="data.id">{{data.name}}</option>
                </select>
                <div class="" *ngIf="form.controls.darshan_type_id.touched && !form.controls.darshan_type_id.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.darshan_type_id.errors.required">Darshan Type is required!</div>
                </div>
                <div *ngIf="form.controls['darshan_type_id'].value == 14 || form.controls['darshan_type_id'].value == 15"> {{garbhGrahSlot}}</div>

              </div>

              <div class="form-group col-4-div">
                <label >State<ng-container *ngIf="form.controls['darshan_type_id'].value != 3">*</ng-container></label>
                <select class="form-control border-primary"  (change)="onStateChange($event)" name="state_id" formControlName="state_id">
                  <option [value]="''">select</option>
                  <option *ngFor="let data of allStates" [value]="data.id">{{data.name}}</option>
                </select>
                <div class="" *ngIf="form.controls.state_id.touched && !form.controls.state_id.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.state_id.errors.required">State is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label >City<ng-container *ngIf="form.controls['darshan_type_id'].value != 3">*</ng-container></label>
                <select class="form-control border-primary" name="city_id" formControlName="city_id">
                  <option [value]="''">select</option>
                  <option *ngFor="let data of allCities" [value]="data.id">{{data.name}}</option>
                </select>
                <div class="" *ngIf="form.controls.city_id.touched && !form.controls.city_id.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.city_id.errors.required">City is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div" *ngIf="form.controls['darshan_type_id'].value != 14 && form.controls['darshan_type_id'].value != 15">
                <label>Date Of Visit*</label>
                <input class="form-control border-primary" (ngModelChange) = "onChangeDate()" [owlDateTime]="dt2" [min]="minDate" [max]="maxDate" placeholder="Date" name="date_of_visit" formControlName="date_of_visit"
                       readonly>
                <span [owlDateTimeTrigger]="dt2" class="date-ico"><i class="fa fa-calendar"></i></span>
                <owl-date-time #dt2 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                <div class=""  *ngIf="dateErrorMsg">
                  <div  class="inaild_no">{{dateErrorMsg}}</div>
                </div>
                <!-- <span *ngIf="dateErrorMsg"  style="color: red; font-size: 12px">{{dateErrorMsg}}</span> -->
                <div class="" *ngIf="form.controls.date_of_visit.touched && !form.controls.date_of_visit.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.date_of_visit.errors.required">Date Of Visit is required!</div>
                </div>

              </div>

              <div class="form-group col-4-div" *ngIf="form.controls['darshan_type_id'].value != 1 && form.controls['darshan_type_id'].value != 14 && form.controls['darshan_type_id'].value != 15">
                <label>Time Of Visit*</label>
                <input class="form-control border-primary" [owlDateTime]="dt1" placeholder="Time" name="time_of_visit" formControlName="time_of_visit"
                       readonly [owlDateTimeFilter]="myFilter">
                <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                <owl-date-time #dt1 [pickerType]="'timer'" [pickerMode]="'dialog'"></owl-date-time>
                <span *ngIf="timeDisable"  style="color: red; font-size: 12px">Select time between 6 AM to 11 PM</span>
                <div class="" *ngIf="form.controls.time_of_visit.touched && !form.controls.time_of_visit.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.time_of_visit.errors.required">Time Of Visit is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div" *ngIf = "form.controls['darshan_type_id'].value && form.controls['darshan_type_id'].value != 3
                 && form.controls['darshan_type_id'].value != 14">
                <label >Department*</label>
                <select class="form-control border-primary" (change)="onChangeDepartment()"  name="department_id" formControlName="department_id">
                  <option [disabled]="formMode == 'Edit' ? true: false" [value]="''">select</option>
                  <option [disabled]="formMode == 'Edit' ? true: false" *ngFor="let data of allDepartments" [value]="data.id">{{data.name}}</option>
                </select>
                <div class="" *ngIf="form.controls.department_id.touched && !form.controls.department_id.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.department_id.errors.required">Department is required!</div>
                </div>
                <!-- <div  class="inaild_no" *ngIf="show_reference_limit_msg">{{show_reference_limit_msg}}</div> -->
              </div>

              <div *ngIf="((form.controls['darshan_type_id'].value && (((form.controls['department_id'].value == 4 || form.controls['department_id'].value == 5 || !form.controls['department_id'].value) && form.controls['darshan_type_id'].value != 3) && (checkDepartmentId(form.controls['department_id'].value) != 'general_bhasmaarti_paid' && checkDepartmentId(form.controls['department_id'].value) != 'general_bhasmaarti') && checkEntryType())) || checkDepartmentBySlug('district_protocol')) && form.controls['department_id'].value" class="form-group col-4-div">
                <label >Referred By*</label>
                <select class="form-control border-primary" (change) = "check_Reference_Limit('Referred By');onSelectReferredBy()"  name="referred_by_id" formControlName="referred_by_id">
                  <option [value]="''">select</option>
                  <option *ngFor="let data of allReferences" [value]="data.id">{{data.name}}</option>
                  <option  [value]="'other'" *ngIf ="checkDepartmentBySlug('district_protocol')">Others</option>

                </select>
                <div class="" *ngIf="form.controls.referred_by_id.touched && !form.controls.referred_by_id.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.referred_by_id.errors.required">Referred By is required!</div>
                </div>
              </div>

              <div *ngIf="((form.controls['darshan_type_id'].value && form.controls['darshan_type_id'].value != 14 && ((form.controls['department_id'].value != 4 && form.controls['department_id'].value != 5 && form.controls['department_id'].value && form.controls['darshan_type_id'].value != 3) && (checkDepartmentId(form.controls['department_id'].value) != 'general_bhasmaarti_paid' && checkDepartmentId(form.controls['department_id'].value) != 'general_bhasmaarti')  && checkEntryType()) && !checkDepartmentBySlug('district_protocol')) || (checkDepartmentBySlug('district_protocol') && form.value.referred_by_id == 'other')) && form.controls['department_id'].value" class="form-group col-4-div">
                <label >Referred By Name*</label>
                <!-- <input class="form-control border-primary"  formControlName="referred_by_id" name="referred_by_id"> -->
                <input [pattern]="'^[a-zA-Z \-\']+'" class="form-control border-primary" (ngModelChange) = "onChangeName();onChangeReferredBy()"  formControlName="reference_person_name" name="reference_person_name">
                <div class="" *ngIf="form.controls.reference_person_name.touched && !form.controls.reference_person_name.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.reference_person_name.errors.number">Numbers Are Not Allowed!</div>
                </div>
                <div class="" *ngIf="form.controls.reference_person_name.touched && !form.controls.reference_person_name.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.reference_person_name.errors.required">Referred By is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div"  *ngIf = "selecedType == 'protocal_darsan' && checkEntryType()">
                <label >Protocol Point Number</label>
                <input  class="form-control border-primary" (keypress)="keyPressNumbers($event)" type="number" formControlName="token_number" name="token_number">
              </div>

              <div class="form-group col-4-div">
                <label >No. Of People*</label>
                <select *ngIf="form.controls['darshan_type_id'].value == 15" class="form-control border-primary" (change)="onPersonCountChange()" name="no_of_persons" formControlName="no_of_persons">
                  <option [value]="''">select</option>
                  <option [value]="1">1</option>
                  </select>
                <select *ngIf="form.controls['darshan_type_id'].value != 15" class="form-control border-primary" (change)="onPersonCountChange()" name="no_of_persons" formControlName="no_of_persons">
                  <option [value]="''">select</option>
                  <option [value]="1">1</option>
                  <option [value]="2">2</option>
                  <option [value]="3">3</option>
                  <option [value]="4">4</option>
                  <option [value]="5">5</option>
                  <option [value]="6">6</option>
                  <option [value]="7">7</option>
                  <option [value]="8">8</option>
                  <option [value]="9">9</option>
                  <option [value]="10">10</option>
                  <option [value]="11">11</option>
                  <option [value]="12">12</option>
                  <option [value]="13">13</option>
                  <option [value]="14">14</option>
                  <option [value]="15">15</option>
                  <option [value]="16">16</option>
                  <option [value]="17">17</option>
                  <option [value]="18">18</option>
                  <option [value]="19">19</option>
                  <option [value]="20">20</option>
                  <option [value]="21">21</option>
                  <option [value]="22">22</option>
                  <option [value]="23">23</option>
                  <option [value]="24">24</option>
                  <option [value]="25">25</option>
                  <option [value]="26">26</option>
                  <option [value]="27">27</option>
                  <option [value]="28">28</option>
                  <option [value]="29">29</option>
                  <option [value]="30">30</option>
                  <option [value]="31">31</option>
                  <option [value]="32">32</option>
                  <option [value]="33">33</option>
                  <option [value]="34">34</option>
                  <option [value]="35">35</option>
                  <option [value]="36">36</option>
                  <option [value]="37">37</option>
                  <option [value]="38">38</option>
                  <option [value]="39">39</option>
                  <option [value]="40">40</option>
                  <option [value]="41">41</option>
                  <option [value]="42">42</option>
                  <option [value]="43">43</option>
                  <option [value]="44">44</option>
                  <option [value]="45">45</option>
                  <option [value]="46">46</option>
                  <option [value]="47">47</option>
                  <option [value]="48">48</option>
                  <option [value]="49">49</option>
                  <option [value]="50">50</option>
                </select>
                <div class="" *ngIf="form.controls.no_of_persons.touched && !form.controls.no_of_persons.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.no_of_persons.errors.required">No. Of People is required!</div>
                </div>
                <div  class="inaild_no" *ngIf="show_reference_limit_msg">{{show_reference_limit_msg}}</div>

                <!-- <input class="form-control border-primary" type="number"  name="no_of_persons" required
                (change)="onPersonCountChange()"> -->
              </div>

              <div class="form-group col-4-div"  *ngIf = "selecedType == 'protocal_darsan'">
                <label >ID Proof Type*</label>
                <select class="form-control border-primary" (change)="onIdProofTypeChangeSingle()"  name="id_proof_type_id" formControlName="id_proof_type_id">
                  <option [value]="''">select</option>
                  <option [value] ="type.id" *ngFor = "let type of id_proof_type_list">{{type.name}}</option>
                </select>
                <div class="" *ngIf="form.controls.id_proof_type_id.touched && !form.controls.id_proof_type_id.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.id_proof_type_id.errors.required">ID Proof Type is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div"  *ngIf = "selecedType == 'protocal_darsan'">
                <label >ID Proof Number*</label>
                <input  class="form-control border-primary" type="text"(ngModelChange) = "onChangeProofNumber();onChangePeopleProofNumber(0)"  formControlName="id_proof_number" name="id_proof_number">
                <div class="" *ngIf="form.controls.id_proof_number.touched && !form.controls.id_proof_number.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.id_proof_number.errors.required">ID Proof Number is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div" *ngIf = "user_roles == 'special_protocol'"  style="padding-left: 34px;padding-top: 28px;">
                <input type="checkbox"  formControlName="is_exempt" name="is_exempt" style="zoom: 1.6;" class="form-check-input" id="exampleCheck1">
                <label class="form-check-label" style="padding-top: 8px;" for="exampleCheck1">Is Exempt</label>
              </div>

              <div class="form-group col-4-div" *ngIf = "selecedType == 'protocal_darsan'"  style="padding-left: 34px;padding-top: 28px;">
                <input type="checkbox"  formControlName="nandi_hall_darshan" name="nandi_hall_darshan" style="zoom: 1.6;" class="form-check-input" id="exampleCheck2">
                <label class="form-check-label" style="padding-top: 8px;" for="exampleCheck2">Nandi Hall Darshan</label>
              </div>
            </div>

            <ng-container  formArrayName="visitors_basic_details">
              <div class="form-group col-12-div visitor_info" *ngFor="let visitor of form.controls['visitors_basic_details']['controls']; let i=index">
                <ng-container [formGroupName]="i">
                  <div class="form-group visitor_info_list">
                    <label >Name*</label>
                    <input [pattern]="'^[a-zA-Z \-\']+'" (ngModelChange) = "onChangePeopleName(i)" class="form-control border-primary"  formControlName="name" name="name" required [readonly]="i == 0">
                    <div class="" *ngIf="form.controls.visitors_basic_details.controls[i].controls.name.touched && !form.controls.visitors_basic_details.controls[i].controls.name.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.visitors_basic_details.controls[i].controls.name.errors.pattern">Only Alphabet Allowed</div>
                    </div>
                    <div class="" *ngIf="form.controls.visitors_basic_details.controls[i].controls.name.touched && !form.controls.visitors_basic_details.controls[i].controls.name.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.visitors_basic_details.controls[i].controls.name.errors.required">Name is required!</div>
                    </div>
                  </div>

                  <!-- <div class="form-group" style="width: 18%;">
                    <label >Age*</label>
                    <input class="form-control border-primary" type="number" formControlName="age" name="age" required>
                    <div class="" *ngIf="form.controls.visitors_basic_details.controls[i].controls.age.touched && !form.controls.visitors_basic_details.controls[i].controls.age.valid">
                      <div  class="inaild_no" *ngIf="form.controls.visitors_basic_details.controls[i].controls.age.errors.required">Age is required!</div>
                    </div>
                    <div class="" *ngIf="form.controls.visitors_basic_details.controls[i].controls.age.touched && !form.controls.visitors_basic_details.controls[i].controls.age.valid">
                      <div  class="inaild_no" *ngIf="form.controls.visitors_basic_details.controls[i].controls.age.errors.max || form.controls.visitors_basic_details.controls[i].controls.age.errors.min">The age must be a number between 0 and 100</div>
                    </div>
                  </div> -->

                  <div class="form-group visitor_info_list">
                    <label >Gender*</label>
                    <select class="form-control border-primary"  name="gender" formControlName="gender">
                      <option [value]="''">select</option>
                      <option [value]="'Male'">Male</option>
                      <option [value]="'Female'">Female</option>
                      <option [value]="'Other'">Other</option>
                    </select>
                    <div class="" *ngIf="form.controls.visitors_basic_details.controls[i].controls.gender.touched && !form.controls.visitors_basic_details.controls[i].controls.gender.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.visitors_basic_details.controls[i].controls.gender.errors.required">Gender is required!</div>
                    </div>
                  </div>

                  <ng-container *ngIf="form.controls['darshan_type_id'].value != 3 || i == 0">
                  <div class="form-group visitor_info_list" *ngIf = "selecedType == 'bhasma_arti'">
                    <label >ID Proof Type*</label>
                    <select class="form-control border-primary" (change)="onIdProofTypeChange(i)"  name="id_proof_type_id" formControlName="id_proof_type_id">
                      <option [value]="''">select</option>
                      <option [value] ="type.id" *ngFor = "let type of id_proof_type_list">{{type.name}}</option>
                    </select>
                    <div class="" *ngIf="form.controls.visitors_basic_details.controls[i].controls.id_proof_type_id.touched && !form.controls.visitors_basic_details.controls[i].controls.id_proof_type_id.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.visitors_basic_details.controls[i].controls.id_proof_type_id.errors.required">ID Proof Type is required!</div>
                    </div>
                  </div>

                  <div class="form-group visitor_info_list" *ngIf = "selecedType == 'bhasma_arti'">
                    <label >ID Proof Number*</label>
                    <input class="form-control border-primary" (ngModelChange) = "onChangePeopleProofNumber(i)"  type="text"  formControlName="id_proof_number" name="id_proof_number" >
                    <div class="" *ngIf="form.controls.visitors_basic_details.controls[i].controls.id_proof_number.touched && !form.controls.visitors_basic_details.controls[i].controls.id_proof_number.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.visitors_basic_details.controls[i].controls.id_proof_number.errors.required">ID Proof Number is required!</div>
                    </div>
                  </div>

                  <div class="form-group visitor_info_list" style="width: 142px;text-align: center;" *ngIf = "image_required">
                    <button type="button" style="margin-top: 30px;float: left;" class="btn btn-inverse-primary btn-fw top-btn-head" (click) = "onClickCapturePhoto(i, findImageUrl(i) ? findImageUrl(i) : '')">{{findImageUrl(i) ? 'Edit' : 'Add'}} Photo</button><br>
                     <span *ngIf="form.controls['darshan_type_id'].value != 14">OR</span>
                     <br>
                     <div *ngIf="form.controls['darshan_type_id'].value != 14" class="upload-btn-wrapper">
                      <button class="btn"
                        [ngClass]="findImageUrl(i) ? 'upload_file': ''">Upload A Photo</button>
                       <input type="file" name="document" accept="image/*" class="form_control"
                        (change)="onFileChange($event, i)" style="padding: 0 !important;" />
                      </div>
                  </div>


                  <div class="form-group visitor_info_close" style="width: 10%;" *ngIf = "findImageUrl(i) && image_required">
                    <img style="border: 1px solid #c1bdbd;" [src]="findImageUrl(i)" height="80" width="100" />
                  </div>
                  </ng-container>

                  <!-- <div class="form-group" style="width: 20%;">
                    <label >Visitor Type</label> <br>
                      <angular2-multiselect [data]="visitor_type"
                      formControlName="visitor_type_ids" name="visitor_type_ids"
                      [settings]="{singleSelection: false,
                              text:'Select' ,
                              enableSearchFilter: false,
                              labelKey:'name',
                              primaryKey:'id'}">
                    <c-badge>
                      <ng-template let-item="item">
                        <label>{{item.name}}</label>
                      </ng-template>
                    </c-badge>
                   </angular2-multiselect>
                  </div> -->
                  <div class="form-group visitor_info_close" style="width: 4%;" *ngIf = "i != 0">
                    <i class="fa fa-times remove-icon" aria-hidden="true" (click) = "remove(i)"></i>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            ​<div class="button-wrap">
            <button  type="submit" [disabled]="!form.valid || dateErrorMsg || show_reference_limit_msg" class="btn btn-primary btn-fw">Submit</button>
            <button  type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button>
          </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>




