import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { AppComponent } from '../app.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionsService } from '../services/permissions.service';
import { ConfirmationPopoverComponent } from '../confirmation-popover/confirmation-popover.component';

@Component({
  selector: 'app-item-categories',
  templateUrl: './item-categories.component.html',
  styleUrls: ['./item-categories.component.scss']
})
export class ItemCategoriesComponent implements OnInit {

  public itemCategories: any;
  public perPage = 20;
  public totalCount = 0;
  public page = 1;

  constructor(
    private apiService: ApiService,
    private appComponet: AppComponent,
    private modalService: NgbModal,
    public permissionsService: PermissionsService,
    private appComponent: AppComponent, 
    ) { }

  ngOnInit() {
    this.getList('');
  }

  getList(_type) {
    this.appComponet.showLoader();
    this.apiService.getDataFromServer(`/item_categories`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.itemCategories = res;
        // this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }

  
  deleteItemCategories(_item){
    const modalRef = this.modalService.open(ConfirmationPopoverComponent,
      {
        centered: false, size: 'sm', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const deleteComponent: ConfirmationPopoverComponent = modalRef.componentInstance;
    deleteComponent.massage = 'Are you sure you want to delete this?';
    modalRef.result.then((result) => {
      if (result === 'success') {
        this.apiService.deleteDataFromServer('/item_categories/' + _item.id).subscribe(
          res => {
            this.appComponet.showMessageAlert(res['message'], 'success');
            this.getList('');
          },
          err => {
            console.log(err);
          }
        );
      }
    });
  
  }


}