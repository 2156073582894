import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { saveAs } from 'file-saver';
import { PermissionsService } from '../services/permissions.service';


@Component({
  selector: 'app-late-registrations-ref-counts-report',
  templateUrl: './late-registrations-ref-counts-report.component.html',
  styleUrls: ['./late-registrations-ref-counts-report.component.scss']
})
export class LateRegistrationsRefCountsReportComponent implements OnInit {

  public payment_report: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  public payment_status = 'Pending';
  public onChangeSearch = new Subject<string>();
  public perPage = 20;
  public totalCount = 0;
  public page = 1;
  public maxDate =  ''; //moment(new Date()).format('YYYY-MM-DD');


  constructor(
    private apiService: ApiService,
    private appComponet: AppComponent,
    public permissionsService: PermissionsService

    ) { }

  ngOnInit() {
    this.getList('');
  }

  getList(_type) {
    this.appComponet.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/late_registrations_ref_counts?date=${from_date}&end_date=${to_date}&page=${this.page}&perpage=${this.perPage}`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.payment_report = res;
        this.payment_report.data.map(res => {
          res.date = moment.utc(res.date).local().format('DD-MM-YYYY');
        });
        this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }

  public export() {
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.appComponet.showLoader();
    this.apiService.getDataFromServer(`/export_late_registrations_ref_counts?date=${from_date}&end_date=${to_date}&page=1&perpage=10000`).subscribe((res: any) => {
      if(res.status_code === 200) {
        const fileArry =  res.data.split('/uploads/');
        this.apiService.getBlobFileFromServer(res.data).subscribe(res => {
          this.appComponet.hideLoader();
          let blob:any = new Blob([res], { type: res.type });
          const url = window.URL.createObjectURL(blob);
          let file_name = fileArry[fileArry.length - 1];
          saveAs(blob, file_name);
        }, (error) => {
        });
        // fileSaver.saveAs(`https:/mybarwani-api.acceldash.com/${res.data}`);
      } 
      this.appComponet.hideLoader();
    }, (error) => {
      this.appComponet.hideLoader();
      console.log('error' , error);
    });
  }


}