
<div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Items</div>
              <div class="form-group  text-center pt-4 mt-2 ml-4" style="margin-left: auto !important;">
                <a title="Add Item" *ngIf = "permissionsService.checkPermission('create_items')" (click)="addNewItems()"> <i class="fa fa-plus-circle" style="font-size: 35px;cursor: pointer;" aria-hidden="true"></i></a>
              </div>
            </div>
          </h4>

          <div class="filter-wrapper field_space" *ngIf = "form">
            <form   [formGroup]="form" novalidate (ngSubmit)="saveData()">
              <div class="form-group col-12-div">

                <div class="form-group col-4-div">
                    <label >Name*</label>
                    <input [pattern]="'^[a-zA-Z \-\']+'" class="form-control border-primary"  formControlName="name" name="name" required>
                    <div class="" *ngIf="form.controls.name.touched && !form.controls.name.valid">
                        <div  class="inaild_no" *ngIf=" form.controls.name.errors.pattern">Only Alphabet Allowed</div>
                      </div>
                    <div class="" *ngIf="form.controls.name.touched && !form.controls.name.valid">
                        <div  class="inaild_no" *ngIf=" form.controls.name.errors.required">Name is required!</div>
                    </div>
                </div>

                <div class="form-group col-4-div">
                  <label >Quantity in stock*</label>
                  <input  class="form-control border-primary"  formControlName="quantity_in_stock" name="quantity_in_stock" required>
                 
                  <div class="" *ngIf="form.controls.quantity_in_stock.touched && !form.controls.quantity_in_stock.valid">
                      <div  class="inaild_no" *ngIf=" form.controls.quantity_in_stock.errors.required">Quantity in stock is required!</div>
                  </div>
              </div>

              <div class="form-group col-4-div">
                <label >Item Category*</label>
                <select class="form-control border-primary"   name="item_category_id" formControlName="item_category_id">
                  <option [value]="''">select</option>
                  <option [value] ="item.id" *ngFor = "let item of itemCategoryList">{{item.name}}</option>
                </select>
                <div class="" *ngIf="form.controls.item_category_id.touched && !form.controls.item_category_id.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.item_category_id.errors.required">Item Category is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div">
                <label >Item Unit*</label>
                <select class="form-control border-primary"   name="item_unit_id" formControlName="item_unit_id">
                  <option [value]="''">select</option>
                  <option [value] ="item.id" *ngFor = "let item of itemUnitList">{{item.name}}</option>
                </select>
                <div class="" *ngIf="form.controls.item_unit_id.touched && !form.controls.item_unit_id.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.item_unit_id.errors.required">Item Unit is required!</div>
                </div>
              </div>

      
              </div>
  
             <div class="button-wrap">
              <button  type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw">Submit</button>
              <button  type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button>
            </div>
  
            </form>
          </div>
          
          <ng-container *ngIf="!form">
          <div class="table-responsive"  *ngIf="items.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
               <th>Name</th>
               <th>Quantity In Stock</th>
               <th>Category</th>
               <th>Unit</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of items; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td style="white-space: normal;">
                  <span *ngIf = "data?.name">{{data?.name}}</span>
                </td>
                <td>
                  <span *ngIf = "data?.quantity_in_stock">{{data?.quantity_in_stock}}</span>
                </td>
                <td>
                    <span>{{data?.category?.name}}</span>
                </td>
                <td>
                  <span>{{data?.unit?.name}}</span>
                </td>
                <td class="fixed-side">
                  <a title="Edit" *ngIf = "permissionsService.checkPermission('update_items')"   (click)="editItem(data)"><i class="fa fa-pencil" ></i></a>
                  <a title="Delete" *ngIf = "permissionsService.checkPermission('delete_items')" class="ml-4" (click)="deleteItemUnits(data)"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="itemUnits && !itemUnits.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
        </ng-container>
        </div>
      </div>
    </div>
  </div>

