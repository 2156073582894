import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterState } from '@angular/router';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit {
  public paymentDetail:any = {
    spRespStatus: 'PENDING'
  };
  private apiLoadInterval:any = null;
  public e_pass = '';

  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.appComponent.showLoader();

  //   let url = this.router.url;
  //   url = url.replace(/-/gi, '_');
  //   if(url) {
  //     this.getPaymentDetail(url);
  //   }
  //   this.route.queryParams
  //   .subscribe(params => {
  //     if(params.e_pass_url && params.e_pass_url != null && params.e_pass_url != 'null'){
  //       this.e_pass = params.e_pass_url;
  //       window.open(this.e_pass);
  //     }
  //   }
  // );
  // let url = this.route.routeConfig?.path;
  // url = url?.replace(/-/gi, '_');

  this.route.queryParams
  .subscribe(params => {
    if(params.redirect && params.clientTxnId){
      this.getPaymentDetail(`/payment_status?redirect=${params.redirect}&clientTxnId=${params.clientTxnId}`);
    }
  }
);

   
  }

  ngOnDestroy() {
    clearTimeout(this.apiLoadInterval);
  }

  viewPass() {
    window.open(this.e_pass);

  }

  getPaymentDetail(url:any) {
    this.apiService.getPublicDataFromServer(url).subscribe(
      (res:any) => {
        this.appComponent.hideLoader();
        this.paymentDetail = res['data'];
        this.e_pass = res.e_pass_url;
        clearTimeout(this.apiLoadInterval);
        if(this.paymentDetail.spRespStatus == 'PENDING') {
          this.apiLoadInterval = setTimeout(() => {
            this.getPaymentDetail(url);
          },15000);
        } else if(this.paymentDetail.spRespStatus == 'SUCCESS'){
          setTimeout(() => {
            if(this.e_pass){
            window.open(this.e_pass);
            }
          },2000);
        }
      },
      err => {
        this.paymentDetail = {
          spRespStatus: 'FAILED'
        }
        console.log(err);
      }
    );
  }

}
