import { Component,OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';

import * as moment from 'moment';

@Component({
  selector: 'app-dharmshala-reserve-room',
  templateUrl: './dharmshala-reserve-room.component.html',
  styleUrls: ['./dharmshala-reserve-room.component.scss']
})
export class DharmshalaReserveRoomComponent implements OnInit {

  dharmshalaDetail:any;

  public param_dharmshala_id = '1';
  public errorMsg = '';
  public successMsg = '';
  public loading = false;
  public env: any = environment;
  public bookingDetails;
  public assignRoomsDetails;
  form : FormGroup;
  availableRooms = [];
  rooms_ids = [];

  constructor(
  private appComponent: AppComponent,
  private apiService: ApiService,
  private route: ActivatedRoute,
  private _formBuilder: FormBuilder,
  private router: Router,
  ) {
    // this.param_dharmshala_id = this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.getList();
    this.createForm();
  }

  createForm() {
    this.form = this._formBuilder.group({
      dharmshala_id: [this.param_dharmshala_id, [Validators.required]],
      name: ['', [Validators.required]],
      mobile_no: ['', [Validators.required]],
      from_date: ['', [Validators.required]],
      to_date: ['', [Validators.required]],
      description: ['', [Validators.required]],
      rooms: this._formBuilder.array([],
        [control => this.atLeastOne(control)])
    });
  }

  getList() {
    this.appComponent.showLoader();
    this.apiService.getDataFromServer(`/dharmashalas/${this.param_dharmshala_id}`).subscribe(
      (res: any) => {
        this.appComponent.hideLoader();
        this.dharmshalaDetail = res && res.data && res.data.length ? res.data[0] : null;
      },
      err => {
        this.errorMsg = err.error.error[0].message;
         this.appComponent.hideLoader();
         this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
      }
    );

  }

  saveData(){

    let form_obj = this.form.getRawValue();
    form_obj.from_date = moment(form_obj.from_date).format('YYYY-MM-DD');
    form_obj.to_date = moment(form_obj.to_date).format('YYYY-MM-DD');
    let rooms = [];
    form_obj.rooms.forEach((val, index) => {
      for (const prop in val) {
        val[prop].forEach((val1, ind1) =>{
          if(val1){
            rooms.push(val1);
          }
        });
      }
      });
    form_obj.rooms = rooms;
    
    this.appComponent.showLoader();
    this.apiService.postDataToServer('/reserve_rooms', form_obj).subscribe(
      (res:any) => {
        this.appComponent.showMessageAlert(res['message'], 'success');
        this.router.navigate(['/dharmshala-reservations-list']);
        setTimeout(() => {
          this.appComponent.hideLoader();
        },200);
      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }


  getAvailableRooms() {
    let form_obj =  this.form.getRawValue();
    if(!form_obj.from_date || !form_obj.to_date){
      return;
    }
    let from_date = moment(form_obj.from_date).format('YYYY-MM-DD');
    let to_date = moment(form_obj.to_date).format('YYYY-MM-DD');
    this.appComponent.showLoader();
    this.apiService.getDataFromServer(`/available_rooms?from_date=${from_date}&to_date=${to_date}&room_type_id=&dharmshala_id=1`).subscribe(
      (res: any) => {
        this.appComponent.hideLoader();
        this.availableRooms = res.data;
        let room_ids = this.form.get('rooms') as FormArray;

        room_ids.clear();

        for (let data of this.availableRooms) {
          room_ids.push(this._formBuilder.group({
            [data.room_type_id] : this._formBuilder.array([])
          }));
          for (let i=0; i < data.available_rooms; i++) {
            let subControl = room_ids['controls'][this.form.controls.rooms['controls'].length - 1]['controls'][data.room_type_id] as FormArray;
            subControl.push(
              this._formBuilder.control('')
            );
          }
        }
      },
      err => {
        this.errorMsg = err.error.error[0].message;
         this.appComponent.hideLoader();
         this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
      }
    );

  }

  numSequence(n): Array<number> {
    return Array(Number(n));
  }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  getRooms(room_id, i, j){
    let roomsArray = this.availableRooms.find((val) => val.room_type_id == room_id);
    roomsArray = roomsArray.rooms.filter((item) => item.is_available);

    const results = roomsArray.filter((element) => !this.form.getRawValue().rooms[i][room_id].some((val, ind) =>
    { return val == element.id && ind != j }));

    //  const results = roomsArray.filter((element) => !this.form.getRawValue().rooms.some((val, ind) =>
    // { return val == element.id && ind != j }));

    return results;
  }

  atLeastOne(control: AbstractControl): ValidationErrors | null{
    let selected = 0;
    control.value.forEach((val) =>{
      for (const prop in val) {
        val[prop].forEach((val1, ind1) =>{
          selected = val1 ? selected + 1 : selected;
        });
      }
    });
     return selected == 0 ? {'atLeastOneError': true} : null;
}

}
