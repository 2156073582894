import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';




@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (localStorage.getItem('loginDetails') != null) {
      let currentUser = JSON.parse(localStorage.getItem('loginDetails'));


      if (currentUser && currentUser.token && req.url.indexOf('digitalocean') === -1) {
         req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.token.token}`
          }
        });
      }
      return next.handle(req)
        .do(
          succ => { },
          err => {
            if (err.status === 401) {
              localStorage.clear();
              this.router.navigateByUrl('/login');
            }
          }
        );
    }
    else {
      if(req.headers && req.headers.get('Authorization') == 'No-auth'){

      } else {
        localStorage.clear();
        this.router.navigateByUrl('/login');
      }
      // return Observable.of();

      return next.handle(req);
    }
  }
}
