<div style="display: inline-block;width: 100%;height: auto;">
    <div style="width: 100%; float: left; padding-left: 20px;box-sizing: border-box;" >
        <ul style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;" *ngIf = "!registraionNoByParams">
            <div class="form-group col-4-div wrapper_label" style="padding-right: 10px;box-sizing: border-box;">
                <label>Registration No.*</label>
                <textarea #autofocus class="form-control border-primary" rows="1" minlength="6" maxlength="8" type="text" [(ngModel)]="registraionNo" name="registraionNo"></textarea>
                <div class="inaild_no" *ngIf="errorMsg">{{errorMsg}}</div>
            </div>
            <div class="form-group col-1-div wrapper_label">
                <button type="button" [disabled] = "!registraionNo" style="margin-top: 40px;" class="btn btn-inverse-primary btn-fw" (click)="getList()">Load</button>
            </div>
        </ul>
        <div class="d-flex" *ngIf = "registraionNoByParams">
            <div class="p-2 w-100"></div>
            <div class="p-2 flex-shrink-1"><button type="button" routerLink = "/registration-list" class="btn btn-inverse-primary btn-fw">Back</button></div>
        </div>
        <div class="card"
            *ngIf="registraionDetail && registraionDetail.visitor_basic_details && registraionDetail.visitor_basic_details.length">
            <div class="card-body upper-header">
                <div class="table-responsive">
                    <ul
                        style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;margin-top: 50px;">
                        <li class="wrapper_label">
                            <label>Registration No.</label>
                            <small>{{registraionDetail?.pnr_no}}</small>
                        </li>

                        <li class="wrapper_label">
                            <label>Mobile Number</label>
                            <small>{{registraionDetail?.mobile_no}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>Type of visit</label>
                            <small>{{registraionDetail?.darshan_type.name}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>Date of visit</label>
                            <small>{{registraionDetail?.date_of_visit | date:'dd-MM-yyyy'}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>Time of visit</label>
                            <small>{{registraionDetail?.time_of_visit}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>City</label>
                            <small>{{registraionDetail?.city?.name}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>State</label>
                            <small>{{registraionDetail?.state?.name}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>Payment Status</label>
                            <small>{{registraionDetail?.payment_status}}</small>
                        </li>


                    </ul>
                    <h4 class="card-title mt-4">
                        <div class="d-flex bd-highlight">
                            <div class="p-2 flex-fill bd-highlight" style="font-size: 20px;">Visitor Details</div>
                        </div>
                    </h4>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Relation</th>
                                <th>Image</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of registraionDetail.visitor_basic_details; let i = index">
                                <td>
                                    <span>{{i+1}}</span>
                                </td>
                                <td>{{data?.name}}</td>
                                <td>{{data?.relation?.name}}</td>
                                <td>
                                    <span *ngIf = "data?.image_url"><img style="cursor: pointer;" (click) = "viewImage(data?.image_url)" src = "{{data?.image_url}}"></span>
                                    <span *ngIf = "!data?.image_url">NA</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div *ngIf = "visitorDetails && visitorDetails.length">
                        <h4 class="card-title mt-4">
                            <div class="d-flex bd-highlight">
                                <div class="p-2 flex-fill bd-highlight" style="font-size: 20px;">Visitor Payment Details</div>
                            </div>
                        </h4>
                        <br>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Sab Paisa Trx Id</th>
                                    <th>Pay Name</th>
                                    <th>Mobile No.</th>
                                    <th>Pay Mode</th>
                                    <th>Amount</th>
                                    <th>Client Trx Id</th>
                                    <th>Transaction Date Time</th>
                                    <th>Sab Paisa Payment Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of visitorDetails; let i = index">
                                    <td>
                                        <span>{{i+1}}</span>
                                    </td>
                                    <td>{{data?.SabPaisaTxId}}</td>
                                    <td>{{data?.firstName}} {{data?.lastName}}</td>
                                    <td>{{data?.mobileNo}}</td>
                                    <td>{{data?.payment_type?.type == 'Cash' ? 'Cash' : data?.payMode}}</td>
                                    <td>{{data?.amount}}</td>
                                    <td>{{data?.trx_id}}</td>
                                    <td>{{data?.created_at}}</td>
                                    <td>{{data?.payment_type?.type == 'Cash' ? 'Paid' : data?.spRespStatus}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>


            </div>
        </div>

        <!-- <div class="p-12"
        *ngIf="registraionNo && !registraionDetail && !visitorDetails && !loading && !errorMsg">
           <span class="ng-star-inserted no_data">No Data found.</span>
         </div> -->
    </div>
</div>
