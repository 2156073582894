import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from '../app.component';
import { AssingRolesComponent } from '../assing-roles/assing-roles.component';
import { ResetPasswordDialogComponent } from '../reset-password-dialog/reset-password-dialog.component';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-reset-user-password',
  templateUrl: './reset-user-password.component.html',
  styleUrls: ['./reset-user-password.component.scss']
})
export class ResetUserPasswordComponent implements OnInit {

  public roles: any;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private appComponent: AppComponent
    ) { }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.appComponent.showLoader();
    this.apiService.getDataFromServer(`/users`).subscribe(
      (res: any) => {
        this.roles = res;
        this.appComponent.hideLoader();
      },
      err => {
        console.log(err);
      }
    );
  }

  public resetPassword(data) {
    const modalRef = this.modalService.open(ResetPasswordDialogComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const resetPasswordDialogComponent: ResetPasswordDialogComponent = modalRef.componentInstance;
    resetPasswordDialogComponent.roleData = data;
    modalRef.result.then((result) => {
      if (result === 'success') {
        this.appComponent.showMessageAlert('Password Updated Successfully.', 'success');
        this.getList();
      }
    });
  }


}

