
  <div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
          <div class="d-flex bd-highlight mb-3">
            <div class="p-2 bd-highlight">
              <div class="form-group">
                <label>From Date</label>
                <input class="form-control border-primary" [max] = "to_date" [owlDateTime]="dt" placeholder="Date" name="from_date"
                  [(ngModel)]="from_date" readonly>
                <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
              </div>
            </div>
            <div class="p-2 bd-highlight">
              <div class="form-group">
                <label>To Date</label>
                <input class="form-control border-primary" [min]="from_date" [max] = "maxDate" [owlDateTime]="dt1" placeholder="Date" name="to_date"
                  [(ngModel)]="to_date" readonly>
                <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
            </div>
            </div>
            <div class="p-2 bd-highlight">
              <div class="form-group">
                <label >Payment Status</label>
                <select class="form-control border-primary" name="is_paid" [(ngModel)]="is_paid">
                  <option [value]="''">All</option>
                  <option [value]="true">Paid</option>
                  <option [value]="false">Unpaid</option>
                </select>
              </div>
            </div>
            
            <div class="p-2 bd-highlight">
              <div class="form-group">
                <label >Payment Type</label>
                <select class="form-control border-primary"  name="payment_type_id" [(ngModel)]="payment_type_id">
                  <option [value]="''">select</option>
                  <ng-container *ngFor="let data of allPaymentTypes">
                    <option *ngIf = "data.slug != 'card'" [value]="data.id">{{data.type}}</option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="p-2 bd-highlight">
              <div class="form-group">
                <button type="button" style="margin-top: 30px;" class="btn btn-inverse-primary btn-fw top-btn-head" (click)="getList($event)">Load</button>
              </div>
            </div>
            <div class="ml-auto p-2 bd-highlight">
              <div class="form-group" style="text-align:right;align-items: right;">
                <a title="Add Donation"  (click) = "navigateTo('add-donation')"> <i class="fa fa-plus-circle" style="font-size: 35px;cursor: pointer;margin-top: 30px;" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Donation List</div>
              <div class="p-2 bd-highlight"  *ngIf = "permissionsService.checkPermission('export_dontaions')"><button  type="btn"  class="btn btn-primary btn-fw" (click) = "export()">Export</button>
              </div>
            </div>
          </h4>
          <div class="table-responsive" *ngIf="donationList && donationList.data && donationList.data.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Mobile No.</th>
                <th>Purpose</th>
                <th>Payment Type</th>
                <th>Amount</th>
                <th>Address</th>
                <th>View Receipt</th>
                <th>View Id Card</th>


              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of donationList?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>{{data?.created_at}}</td>
                <td>{{data?.first_name}}</td>
                <td>{{data?.last_name}}</td>
                <td>{{data?.mobile_no}}</td>
                <td>{{data?.purpose?.title}}</td>
                <td>{{data?.payment_detail?.payment_type?.type}}</td>
                <td>{{data?.amount}}</td>
                <td>
                  <span *ngIf = "data?.address_line_1">{{data?.address_line_1}}</span>
                  <span *ngIf = "!data?.address_line_1">{{data?.payment_detail?.Add}}</span>
                </td>
                <td>
                  <a [href]="data?.receipt_url" target="_blank" *ngIf = "data?.receipt_url">View Receipt</a>
                </td>
                <td>
                  <a [href]="data?.id_card_url" target="_blank" *ngIf = "data?.id_card_url">View Id Card</a>
                </td>
              </tr> 
              </tbody>
            </table>
          </div>

          <div class="p-12" *ngIf="donationList && donationList.data && !donationList.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
          <ngb-pagination *ngIf="donationList && donationList.data && donationList.data.length" [collectionSize]="totalCount" [pageSize]="perPage"
          [(page)]="page" (pageChange)="getList('pagination')" [maxSize]="3"
          class="pull-right justify-content-end page-mt-10"
          aria-label="Default pagination"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>

