import {Component, OnInit} from '@angular/core';
import {ApiService} from '../services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {PermissionsService} from '../services/permissions.service';
import {environment} from '../../environments/environment';
import {AppComponent} from '../app.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { SendOtpComponent } from '../send-otp/send-otp.component';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  env = environment;
  loginForm: FormGroup;
  verifyOtpForm: FormGroup;
  activeModal: NgbModalRef;


  constructor(private apiService: ApiService, private _formBuilder: FormBuilder, private router: Router,
              public _prmsn: PermissionsService, private appComponent: AppComponent, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.verifyOtpForm = this._formBuilder.group({
      otp: ['', [Validators.required]],
      mobile_no: ['', Validators.required]
    });

    this.appComponent.hideLoader();

  }

  async login() {
    this.appComponent.showLoader();
    let formObj = this.loginForm.getRawValue();
    if(formObj.email == 'special_protocol@mahakal.com') {
      this.sendOtp(formObj);
    } else {
      this.apiService.postDataToServer('/login', formObj).subscribe(
        async(res:any) => {
          localStorage.setItem('loginDetails', JSON.stringify(res['data']));
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.appComponent.hideLoader();
          if(res.data && res.data.roles && res.data.roles.length && res.data.roles[0] == 'report_admin') {
            this.router.navigate(['daily-quota']);
          } else if(await this._prmsn.isAccessPage('registration-list')){
            this.router.navigate(['registration-list']);
          } else if(await this._prmsn.isAccessPage('qr-scanner')){
            this.router.navigate(['qr-scanner']);
          } else if(await this._prmsn.isAccessPage('donation-list')){
            this.router.navigate(['donation-list']);
          } else{
            this.router.navigate(['item-transactions']);
          }
          // if(res['data'] && res['data'].roles && res['data'].roles.length && res['data'].roles[0] == 'special_protocol') {
          //   this.sendOtp(res['data'].user, res);
          // } else {
          
          // }
        },
        err => {
          console.log(err);
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          this.appComponent.hideLoader();
        }
      );
    }
  }

    sendOtp(userDetail) {
      this.apiService.postDataToServer('/generate_otp',userDetail).subscribe((res: any) => {
        userDetail.mobile_no = res.data ? res.data.mobile_no : '';
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(res['message'], 'success');
        const modalRef = this.modalService.open(SendOtpComponent,
          {
            centered: false, size: 'sm', windowClass: 'my-class', scrollable: false,
            backdrop: 'static', keyboard: false,
          });
        const sendOtpComponent: SendOtpComponent = modalRef.componentInstance;
        sendOtpComponent.userDetail = userDetail;
        modalRef.result.then((result) => {
          if (result && result.data) {
            localStorage.setItem('loginDetails', JSON.stringify(result['data']));
            this.appComponent.showMessageAlert(result['message'], 'success');
            this.router.navigate(['registration-list']);
          }
        });
      }, (error) => {
        this.appComponent.showMessageAlert(error.error.error[0].message, 'error');
        this.appComponent.hideLoader();
        console.log(error , 'error');
      });
  }

}
