<div class="row">
    <div class="col-lg-12 grid-margin">
        <div class="card">
            <div class="card-body upper-header">
                <div class="row">
                    <div class="form-group col-2 ">
                        <label>From Date</label>
                        <input class="form-control border-primary" [max]="to_date" [owlDateTime]="dt" placeholder="Date"
                            name="from_date" [(ngModel)]="from_date" readonly>
                        <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                        <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                    </div>
                    <div class="form-group col-2 ">
                        <label>To Date</label>
                        <input class="form-control border-primary" [min]="from_date" [max]="maxDate" [owlDateTime]="dt1"
                            placeholder="Date" name="to_date" [(ngModel)]="to_date" readonly>
                        <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                        <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                    </div>

                    <div class="form-group col-1.5">
                        <button type="button" style="margin-top: 30px;"
                            class="btn btn-primary btn-fw top-btn-head" (click)="getList($event)">Load</button>
                    </div>
                </div>
                <h4 class="card-title">
                    <div class="d-flex bd-highlight">
                        <div class="p-2 flex-grow-1 bd-highlight">Visitors Feedback</div>
                    </div>
                </h4>
                <div class="table-responsive"
                    *ngIf="feedbackList && feedbackList.data && feedbackList.data.length">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Darshan Type </th>
                                <th>Question & Answer</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of feedbackList?.data; let i = index">
                                <td>
                                    <span *ngIf="page == 1">{{i+1}}</span>
                                    <span *ngIf="page > 1">{{((page - 1 )* 5) + (i+1)}}</span>
                                </td>
                                <td>{{data?.first_name}} {{data?.last_name}}</td>
                                <td>{{data?.darshan_type?.name}}</td>
                                <td>
                                    <ul>
                                        <li *ngFor="let qusAns of data.feedbacks | keyvalue;">
                                            <label>{{qusAns?.value?.question}} </label>
                                            <span *ngIf="qusAns.value.answer">( {{qusAns?.value?.answer}} )</span>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="p-12" *ngIf="feedbackList && feedbackList.data && !feedbackList.data.length">
                    <span class="ng-star-inserted no_data">No Data found.</span>
                </div>
                <ngb-pagination *ngIf="feedbackList && feedbackList.data && feedbackList.data.length" [collectionSize]="totalCount" [pageSize]="perPage"
                [page]="page" (pageChange)="getList('pagination')" class="pull-right justify-content-end page-mt-10"
          aria-label="Default pagination"></ngb-pagination>
            </div>
        </div>
    </div>
</div>