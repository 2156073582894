
  <div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Permission To Role</div>
            </div>
          </h4>
          <div class="table-responsive"  *ngIf="allData && allData.data && allData.data.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Role Name</th>
                <th>Assign Permissions</th>

              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of allData?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>
                    <span *ngIf = "data?.name">{{data?.name}}</span>
                </td>
                <td class="fixed-side">
                    <a title="Assign Permissions"  *ngIf = "permissionsService.checkPermission('create_assign_role_permissions')"   [routerLink]="['/assign-permission/', data.id]"><i class="fa fa-user-plus" aria-hidden="true"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="allData && allData.data && !allData.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
        </div>
      </div>
    </div>
  </div>

