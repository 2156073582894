<form [formGroup]="form" novalidate (ngSubmit)="saveData()" style="display: inline-block;width: 100%;height: auto;">
    <div style="width: 100%; float: left; padding-left: 20px;box-sizing: border-box;">
        <div class="card">
            <div class="card-body upper-header">
                <div class="table-responsive">
                    <h4 class="card-title card_heading">
                        <div class="d-flex bd-highlight">
                            <div class="p-2 flex-fill bd-highlight">Dharmshala Details</div>
                        </div>
                    </h4>
                    <ul style="width: 100%; display: inline-block;list-style: none;padding: 0;margin: 0;">
                        <li class="wrapper_label">
                            <label>Dharmshala Name</label>
                            <small>{{dharmshalaDetail?.name}}</small>
                        </li>

                        <li class="wrapper_label">
                            <label>Phone Number</label>
                            <small>{{dharmshalaDetail?.phone_no}}</small>
                        </li>
                        <li class="wrapper_label">
                            <label>Address</label>
                            <small>{{dharmshalaDetail?.address}}</small>
                        </li>
                    </ul>



                    <h4 class="card-title card_heading">
                        <div class="d-flex bd-highlight">
                            <div class="p-2 flex-fill bd-highlight">Room Distribution</div>
                        </div>
                    </h4>

                    <div class="room_distribution_wrap card-body">
                        <div class="row">
                            <div class="form-group col-4">
                                <label>From Date</label>
                                <input class="form-control border-primary" (ngModelChange) = "getAvailableRooms()" [owlDateTime]="dt" placeholder="Date"
                                    name="from_date" formControlName="from_date" readonly>
                                <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                                <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                            </div>
                            <div class="form-group col-4">
                                <label>To Date</label>
                                <input class="form-control border-primary" (ngModelChange) = "getAvailableRooms()" [owlDateTime]="dt1" placeholder="Date"
                                    name="to_date" formControlName="to_date" readonly>
                                <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                                <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                            </div>
                        </div>
                        <div *ngIf="form.controls.from_date.value && form.controls.to_date.value" class="row">
                            <div class="form-group col-4">
                                <label>Name*</label>
                                <input [pattern]="'^[a-zA-Z \-\']+'" class="form-control border-primary"
                                formControlName="name" name="name">
                                <div class=""
                                    *ngIf="form.controls.name.touched && !form.controls.name.valid">
                                    <div class="inaild_no" *ngIf=" form.controls.name.errors.number">Numbers
                                        Are Not Allowed!</div>
                                </div> 

                                 <div class=""
                                    *ngIf="form.controls.name.touched && !form.controls.name.valid">
                                    <div class="inaild_no" *ngIf=" form.controls.name.errors.required">First
                                        Name is required!</div>
                                </div>
                            </div>

                            <div class="form-group col-4">
                                <label>Contact No.*</label>
                                <input class="form-control border-primary" type="text"
                                    (keypress)="keyPressNumbers($event)" maxlength="10" minlength="10"
                                    formControlName="mobile_no" name="mobile_no">
                                <div class="" *ngIf="form.controls.mobile_no.touched && !form.controls.mobile_no.valid">
                                    <div class="inaild_no" *ngIf=" form.controls.mobile_no.errors.pattern">Invalid
                                        Mobile Number</div>
                                </div>
                                <div class="" *ngIf="form.controls.mobile_no.touched && !form.controls.mobile_no.valid">
                                    <div class="inaild_no" *ngIf=" form.controls.mobile_no.errors.required">Contact
                                        Number is required!</div>
                                </div>
                            </div>
                        

                            <div class="form-group col-8">
                                <label>Address*</label>
                                <textarea class="form-control border-primary" id="exampleFormControlTextarea1"
                                formControlName="description" rows="2" name="address_line_1"></textarea>

                             <div class="" *ngIf="form.controls.description.touched && !form.controls.description.valid">
                                  <div  class="inaild_no" *ngIf=" form.controls.description.errors.required">Address is required!</div>
                                </div>
                            </div>

                        </div>

                        <div class="room_distribution_wrap" formArrayName="rooms">

                        <ng-container *ngFor="let data of availableRooms; let i = index" [formGroupName]="i">
                            <table class="room_distribution_tabble" *ngIf="data.available_rooms > 0">
                                <tbody>
                                    <tr>
                                        <th width="300">
                                            <label>{{data.room_type_title}}</label>
                                        </th>
                                        <th>Assign Room</th>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr [formGroupName]="data.room_type_id" *ngFor="let row of numSequence(data.available_rooms); let j = index">
                                        <td># Room {{j+1}}</td>
                                        <td>
                                            <select [formControlName]="j">
                                                <option [value]="''">Do Not Reserve</option>
                                                <option [value]="rooms.id" *ngFor="let rooms of getRooms(data?.room_type_id, i, j)">{{rooms?.room_no}}</option>
                                            </select>
                                            <!-- <div class="" *ngIf="form.controls.rooms.touched">
                                                <div  class="inaild_no" *ngIf="form.controls.rooms.errors.atLeastOneError">Atleast One Room is required!</div>
                                              </div> -->
                                        </td>
                                    </tr>     
                                </tbody>
                            </table>
                        </ng-container>
                            <!-- <ul>
                                <ng-container *ngFor="let data of availableRooms; let i = index" [formGroupName]="i">
                                    <label *ngIf="data.available_rooms > 0">{{data.room_type_title}}</label>
                                         <li [formGroupName]="data.room_type_id" *ngFor="let row of numSequence(data.available_rooms); let j = index">
                                          <select [formControlName]="j">
                                              <option [value]="''">Do Not Reserve</option>
                                              <option [value]="rooms.id" *ngFor="let rooms of getRooms(data?.room_type_id, i, j)">{{rooms?.room_no}}</option>
                                          </select>
                                        </li>
    
                                </ng-container>
                            </ul> -->
                        </div>

                        ​<div class="button-wrap">
                            <button type="submit"
                                [disabled]="!form.valid" class="btn btn-primary btn-fw mr-2">Submit</button>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</form>