import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';

@Component({
  selector: 'app-bhasmaarti-request-details',
  templateUrl: './bhasmaarti-request-details.component.html',
  styleUrls: ['./bhasmaarti-request-details.component.scss']
})
export class BhasmaartiRequestDetailsComponent implements OnInit {

  visitorDetails = null;
  public errorMsg = '';
  public successMsg = '';
  public loading = false;
  public env: any = environment;

  constructor(
  private appComponent: AppComponent,
  private apiService: ApiService,
  private modalService: NgbModal,
  private route: ActivatedRoute
  ) { 
    let visitors_id = this.route.snapshot.params.id;
    if(visitors_id) {
      this.getList(visitors_id);
    }
  }

  ngOnInit() {
  }
 

  viewImage(imageUrl){
    const modalRef = this.modalService.open(ImageDialogComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const imageDialogComponent: ImageDialogComponent = modalRef.componentInstance;
    imageDialogComponent.imageUrl = `${imageUrl}`;
  }



  getList(visitors_id) {
    this.loading = true;
    
    this.appComponent.showLoader();
    this.apiService.getDataFromServer(`/bhasmarti_requests/${visitors_id}`).subscribe(
      (res: any) => {
        this.visitorDetails = res && res.data;
      
        this.appComponent.hideLoader();
         this.loading = false;
      },
      err => {
        this.errorMsg = err.error.error[0].message;
         this.appComponent.hideLoader();
         this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
         this.loading = false;
      }
    );
  }


}

