import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AppComponent } from '../app.component';
import * as fileSaver from 'file-saver';
import { ApiService } from '../services/api.service';
import { PermissionsService } from '../services/permissions.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CapturePhotoPopoverComponent } from '../capture-photo-popover/capture-photo-popover.component';
import * as faceapi from 'face-api.js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-local-darshanarthis-face-registration',
  templateUrl: './local-darshanarthis-face-registration.component.html',
  styleUrls: ['./local-darshanarthis-face-registration.component.scss']
})
export class LocalDarshanarthisFaceRegistrationComponent implements OnInit {

  form: FormGroup;
  face_img:any;
  param_id = '';

  constructor( private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private appComponent: AppComponent,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal) {
      this.param_id  = this.route.snapshot.params.id;
     }

  async ngOnInit() {
    await Promise.all([faceapi.nets.tinyFaceDetector.loadFromUri('../../assets/models'),
    await faceapi.nets.faceLandmark68Net.loadFromUri('../../assets/models'),
    await faceapi.nets.faceRecognitionNet.loadFromUri('../../assets/models')]).then(() => {});
      this.createForm();
  }

  createForm() {
    this.form = this._formBuilder.group({
      local_darshanarthi_id: ['', [Validators.required]],
      images: [[], [Validators.required]]
    });
  }

 saveData(){
  var formData = new FormData();
  formData.append('local_darshanarthi_id', this.param_id);
  formData.append('images[]', this.form.getRawValue().images[0]);

    this.appComponent.showLoader();
    this.apiService.postDataToServer('/face_registration', formData).subscribe(
      (res:any) => {
        this.appComponent.showMessageAlert(res['message'], 'success');
        this.createForm();
        this.face_img = '';
        setTimeout(() => {
          this.appComponent.hideLoader();
          this.router.navigate(['local-darshanarthis-list']);
        },200);
      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
 }

  onClickCapturePhoto(event, data) {
    const modalRef = this.modalService.open(CapturePhotoPopoverComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const capturePhotoPopoverComponent: CapturePhotoPopoverComponent = modalRef.componentInstance;
    capturePhotoPopoverComponent.data = data;

    modalRef.result.then((result) => {
      if (result) {
        this.appComponent.showLoader();
        this.detect_Faces(result);
      }
    });

  }

    async detect_Faces(result) {
      let blob: any = '';
      this.apiService.getBlobFileFromServer(result).subscribe(async res => {
        blob = new Blob([res], { type: res.type.toString() });
        this.appComponent.hideLoader();

        const img = await faceapi.bufferToImage(blob)

        let fullFaceDescriptions = await faceapi.detectSingleFace(img,  new  faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
        
        if(fullFaceDescriptions && fullFaceDescriptions.detection.score){
          this.form.controls.images.setValue([blob]);
          this.face_img = result  ;

        }
      });
      }

}
