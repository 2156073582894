import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {AppComponent} from '../app.component';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-roles',
  templateUrl: './add-roles.component.html',
  styleUrls: ['./add-roles.component.scss']
})
export class AddRolesComponent implements OnInit {

  form: FormGroup;

  constructor(private _formBuilder: FormBuilder, private apiService : ApiService, private appComponent: AppComponent, private router: Router,private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.createForm();
  }


  createForm() {
    this.form = this._formBuilder.group({
      name: ['', [Validators.required]],
      description: ['']
    });
  }


  saveData() {
    const formObj = this.form.getRawValue();
    this.appComponent.showLoader();
    formObj.name = formObj.name.trim();
    formObj.description = formObj.description.trim();
      this.apiService.postDataToServer('/roles', formObj).subscribe(
        res => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.createForm();
          this.router.navigate(['roles']);
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
  }

  close() {
    this.router.navigate(['/roles']);
  }

}

