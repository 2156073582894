import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppComponent } from '../app.component';
import * as fileSaver from 'file-saver';
import { ApiService } from '../services/api.service';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-pujan-bookings',
  templateUrl: './pujan-bookings.component.html',
  styleUrls: ['./pujan-bookings.component.scss']
})
export class PujanBookingsComponent implements OnInit {
  public pujanBookingList: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  public perPage = 15;
  public totalCount = 0;
  public page = 1;
  public maxDate =  '';
  public payment_status = '';

  constructor(
    private apiService: ApiService,
    private appComponet: AppComponent,
    public permissionsService: PermissionsService,
    private appComponent: AppComponent, 
    ) { }

  ngOnInit() {
    this.getList('');
  }

  getList(_type) {
    this.appComponet.showLoader();
    console.log(this.payment_status);
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/pujan_bookings?date=${from_date}&end_date=${to_date}&payment_status=${this.payment_status}&page=${this.page}&perpage=${this.perPage}`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.pujanBookingList = res;
        this.pujanBookingList.data.map(res => {
          res.date_of_puja = moment.utc(res.date_of_puja).local().format('DD-MM-yyyy');
        });
        this.totalCount = res.total;
      },
      err => {
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        this.appComponet.hideLoader();
        // console.log(err);
      }
    );
  }

  exportList(){
    this.appComponent.showLoader();
    let from_date = moment(this.from_date).format('YYYY-MM-DD');
    let to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/export_pujan_bookings?date=${from_date}&end_date=${to_date}&payment_status=${this.payment_status}`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        if(res.status_code === 200) {
          const imageArry =  res.data.split('/uploads');
          fileSaver.saveAs(res.data, imageArry[1]);
        } 
      }, err => {
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        this.appComponet.hideLoader();
        // console.log(err);
      });
  }

  onPrintClick(_data){
    
        window.open(_data.receipt_url);
  }
}