
<div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
            <div class="row">
                <div class="form-group col-1.5 ml-2">
                  <label>From Date</label>
                  <input class="form-control border-primary" [max] = "to_date" [owlDateTime]="dt" placeholder="Date" name="from_date"
                    [(ngModel)]="from_date" readonly>
                  <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                  <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-1.5 ml-2 mr-2">
                    <label>To Date</label>
                    <input class="form-control border-primary" [min]="from_date" [max] = "maxDate" [owlDateTime]="dt1" placeholder="Date" name="to_date"
                      [(ngModel)]="to_date" readonly>
                    <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                    <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-1.5">
                  <button type="button" style="margin-top: 30px;" class="btn btn-primary btn-fw" (click)="getList($event)">Load</button>
                </div>
                <div *ngIf = "permissionsService.checkPermission('read_dharmshala_reserve_room')" class="form-group col-1  text-center pt-4 mt-2 ml-4" style="margin-left: auto !important;">
                  <a title="Dharmshala Reserve Room" routerLink="/dharmshala-reserve-room"> <i class="fa fa-plus-circle" style="font-size: 35px;cursor: pointer;" aria-hidden="true"></i></a>
                </div>
              </div>
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Dharmshala Reservations List</div>
            </div>
          </h4>
          <div class="table-responsive" *ngIf="allDharmshalaBooking && allDharmshalaBooking.data && allDharmshalaBooking.data.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Mobile No.</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>Description</th>
                <th>No of Rooms</th>
                <!-- <th>Action</th> -->
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of allDharmshalaBooking?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>{{data?.name}}</td>
                <td>{{data?.mobile_no}}</td>
                <td>{{data?.from_date}}</td>
                <td>{{data?.to_date}}</td>
                <td>{{data?.description}}</td>
                <td>{{data?.no_of_rooms}}</td>
                <!-- <td>
                  <a title="Dharmshala Detail" class="ml-4" routerLink = "/dharmshala-booking-detail/{{data.id}}">
                    <i class="fa fa-info-circle" style="font-size: 24px !important;" aria-hidden="true"></i>
                  </a>
                </td> -->
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="allDharmshalaBooking && allDharmshalaBooking.data && !allDharmshalaBooking.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
          <ngb-pagination  *ngIf="allDharmshalaBooking && allDharmshalaBooking.data && allDharmshalaBooking.data.length" [collectionSize]="totalCount" [pageSize]="perPage"
          [(page)]="page" [maxSize]="3" (pageChange)="getList('pagination')"
          class="pull-right justify-content-end page-mt-10"
          aria-label="Default pagination"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>

