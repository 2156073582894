import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { SortListService } from 'src/app/services/sort-list.service';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-news-and-events',
  templateUrl: './news-and-events.component.html',
  styleUrls: ['./news-and-events.component.scss']
})
export class NewsAndEventsComponent implements OnInit {

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";


  form!: FormGroup;
  thumbnail;
  file_name;
  

  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private appComponent: AppComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sortListService: SortListService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal

  ) {
    this.createForm();
  }

  ngOnInit() {
  }


  createForm() {
    this.form = this._formBuilder.group({
      subject: ['', [Validators.required]],
      year: ['', [Validators.required, Validators.pattern('[1-9]\\d{3}')]],
      date: ['', [Validators.required]],
      type: ['', [Validators.required]],
      description: ['', [Validators.required]],
      thumbnail: ['', [Validators.required]],
      files: ['', [Validators.required]]
    });
  }

 
 async saveData() {
    const formObj = this.form.getRawValue();
    let formData = new FormData();

    formData.append('subject', formObj.subject);
    formData.append('year', formObj.year);
    formData.append('date', moment(formObj.date).format('YYYY-MM-DD'));
    formData.append('type', formObj.type);
    formData.append('description', formObj.description);
    formData.append('files[1]', formObj.files);

    formData.append('thumbnail[1]', formObj.thumbnail);

      this.appComponent.showLoader();
      this.apiService.postDataToServer('/news_and_events_with_thumb', formData).subscribe(
        (res:any) => {
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.router.navigate(['/news-and-events-list']);
          this.createForm();
          this.appComponent.hideLoader();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
  }

  

  onImageFileChange(event:any) {


    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      // this.visitor_picture_file_name = file.name;

      this.form.controls['thumbnail'].setValue(event.target.files[0]);

      reader.onload = (e) => {
        this.thumbnail = e.target['result'];
        this.cd.markForCheck();
      };
    }
  }

  async onFileChange(event:any) {

    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.file_name = file.name;
      

      this.form.controls['files'].setValue(event.target.files[0]);

      reader.onload = async (e) => {
        this.thumbnail = e.target['result'];

        this.cd.markForCheck();
      };
    }
  }

  async screenshot(_data) {
    // Select the element that you want to capture
    const captureElement = document.querySelector("canvas");
  
    console.log(_data);
    await html2canvas(captureElement).then(async(canvas) => {
      const imageData = canvas.toDataURL("image/png");
     await fetch(imageData)
      .then(async res => {
        return res.blob();
      })
      .then(async blob => {
        const file = new File([blob], 'thumbnail.png', { type: blob.type })

        await this.form.controls['thumbnail'].setValue(blob);
      });
      
    });
  }

  

  

}
