import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';
import { saveAs } from 'file-saver';
import { PermissionsService } from '../services/permissions.service';

@Component({
  selector: 'app-mobile-locker-report',
  templateUrl: './mobile-locker-report.component.html',
  styleUrls: ['./mobile-locker-report.component.scss']
})
export class MobileLockerReportComponent implements OnInit {

  public locker_report: any;
  locker_list = [];
  locker_location_id = '';
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  // public to_date = moment(new Date()).format('YYYY-MM-DD');
  public perPage = 20;
  public totalCount = 0;
  public page = 1;
  public maxDate = ''; // moment(new Date()).format('YYYY-MM-DD');

  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent,
    public permissionsService: PermissionsService

    ) { }

  ngOnInit() {
    this.getList('');
    this.getLockerLocationsList();
  }
  getLockerLocationsList() {
    this.apiService.getPublicDataFromServer('/locker_locations').subscribe(
      (res:any) => {
          this.locker_list = res.data;
          console.log(this.locker_list);
      },
      err => {
        console.log(err);
      }
    );
  }

  getList(_type) {
    this.appComponent.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    // const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/mobile_lockers_report?locker_location_id=${this.locker_location_id}&date=${from_date}`).subscribe(
      (res: any) => {
        this.appComponent.hideLoader();
        this.locker_report = res;
        this.locker_report.data.map(res => {
          // res.created_at = moment.utc(res.created_at).local().format('DD-MM-YYYY h:mm a z');
          res.date = moment.utc(res.date).local().format('DD-MM-YYYY');
        });
        this.totalCount = res.total;
      },
      err => {
        console.log(err);
      }
    );
  }

  // public export() {
  //   const from_date = moment(this.from_date).format('YYYY-MM-DD');
  //   const to_date = moment(this.to_date).format('YYYY-MM-DD');
  //   this.appComponent.showLoader();
  //   this.apiService.getDataFromServer(`/export_foot_fall_report?date=${from_date}&end_date=${to_date}&page=1&perpage=10000`).subscribe((res: any) => {
  //     if(res.status_code === 200) {
  //       const fileArry =  res.data.split('/uploads/');
  //       this.apiService.getBlobFileFromServer(res.data).subscribe(res => {
  //         this.appComponent.hideLoader();
  //         let blob:any = new Blob([res], { type: res.type });
  //         const url = window.URL.createObjectURL(blob);
  //         let file_name = fileArry[fileArry.length - 1];
  //         saveAs(blob, file_name);
  //       }, (error) => {
  //       });
  //     } 
  //     this.appComponent.hideLoader();
  //   }, (error) => {
  //     this.appComponent.hideLoader();
  //     console.log('error' , error);
  //   });
  // }


}
