import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { SortListService } from 'src/app/services/sort-list.service';
import { CapturePhotoPopoverComponent } from 'src/app/capture-photo-popover/capture-photo-popover.component';

@Component({
  selector: 'app-add-local-darshanarthis',
  templateUrl: './add-local-darshanarthis.component.html',
  styleUrls: ['./add-local-darshanarthis.component.scss']
})
export class AddLocalDarshanarthisComponent implements OnInit {
  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";

  aadhar_img : any = {
    aadhaar_back : '',
    aadhaar_front : ''
  };

  form!: FormGroup;
  allStates = [];
  allCities = [];
  formMode = 'Add';
  addPayment = false;

  local_darshanarthis:any;

  public env: any = environment;

  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private appComponent: AppComponent,
    private router: Router,
    private sortListService: SortListService,
    private cd: ChangeDetectorRef

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getAllStates();
  }


  createForm() {
    this.form = this._formBuilder.group({
      name: ['', [Validators.required,this.removeSpaces,this.textValidation()]],
      age: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      aadhaar_no: ['', [Validators.required, Validators.pattern('^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$')]],
      mobile_no: ['', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      aadhaar_front: ['', [Validators.required]],
      aadhaar_back: ['', [Validators.required]],
      state_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      pin_code: ['', [Validators.required]],
      address_line_1:  ['', [Validators.required]],
      address_line_2:  [''],
    });
  }

  textValidation() {
    return (control: AbstractControl): { [key: string]: any } | null =>
       !this.extractNumber(control.value)
            ? null : {number: control.value};
  }

  extractNumber(string) {
    let numArray = string.split('').map(item => {
      if (typeof +item === 'number' && !isNaN(+item)) return +item
    })
    return +numArray.join('')
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }



  getAllStates() {
    this.apiService.getDataFromPublicServer(`/public/api/v1/states`).subscribe(
      (res:any) => {
        // this.allStates = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allStates = res;
          console.log('state =',this.allStates);
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllCities(_id:any) {
    this.apiService.getDataFromPublicServer('/public/api/v1/cities/?state_id=' + _id).subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allCities = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }


  saveData() {
    const formObj = this.form.getRawValue();
    const formValue = new FormData();
    formValue.append('name', formObj.name);
    formValue.append('age', formObj.age);
    formValue.append('gender', formObj.gender);
    formValue.append('aadhaar_no', formObj.aadhaar_no);
    formValue.append('mobile_no', formObj.mobile_no);
    formValue.append('aadhaar_front', formObj.aadhaar_front);
    formValue.append('aadhaar_back', formObj.aadhaar_back);
    formValue.append('state_id', formObj.state_id);
    formValue.append('city_id', formObj.city_id);
    formValue.append('pin_code', formObj.pin_code);
    formValue.append('address_line_1', formObj.address_line_1);
    formValue.append('address_line_2', formObj.address_line_2);

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/local_darshanarthis', formValue).subscribe(
        (res:any) => {
          this.local_darshanarthis = res.data;
          this.appComponent.hideLoader();
          this.createForm();
          this.aadhar_img.aadhaar_back = '';
          this.aadhar_img.aadhaar_front = '';
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  createSpaceProofNumber(){
      let aadhaar_no = this.form.controls['aadhaar_no'].value;
      aadhaar_no = aadhaar_no.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
       this.form.controls['aadhaar_no'].setValue(aadhaar_no);
}

  onStateChange(_value:any){
    this.getAllCities(_value.target.value);
  }

  onFileChange(event:any, img_result: any) {
    let reader = new FileReader();
    if (event.target['files'] && event.target['files'].length) {
      const [file] = event.target['files'];
      reader.readAsDataURL(file);

      reader.onload = (e : any) => {
        this.cd.markForCheck();
       this.aadhar_img[img_result] = e.target.result;
       this.form.controls[img_result].setValue(file);

      };
    }

  }

  close() {
    this.router.navigate(['donation-list']);
  }

}
