import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { SortListService } from 'src/app/services/sort-list.service';
import { CapturePhotoPopoverComponent } from 'src/app/capture-photo-popover/capture-photo-popover.component';

@Component({
  selector: 'app-locker-booking',
  templateUrl: './locker-booking.component.html',
  styleUrls: ['./locker-booking.component.scss']
})
export class LockerBookingComponent implements OnInit {

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";


  form!: FormGroup;
  allDarshanTypes = [];
  timeDisable = false;
  formMode = 'Add';

  locker_list = []

  visitor_picture_file_name = '';

  id_proof_name = '';
  imageUrlLoading = false;
  idProofLoading = false;
  receiptNo = '';

  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private appComponent: AppComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sortListService: SortListService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getLockerLocationsList();
  }


  createForm() {
    this.form = this._formBuilder.group({
      name: ['', [Validators.required,this.removeSpaces,this.textValidation()]],
      mobile_no: ['', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      locker_location_id: ['', [Validators.required]],
      locker_no: ['', [Validators.required]],
      item_qty: ['', [Validators.required]],
      items_image_url: ['', [Validators.required]],
      person_image_url: ['', [Validators.required]]
    });
  }

  getLockerLocationsList() {
    this.apiService.getPublicDataFromServer('/locker_locations').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.locker_list = res;
          if (this.locker_list.length == 1){
            this.form.controls.locker_location_id.setValue(this.locker_list[0].id);
            this.form.controls.locker_location_id.disable();
          }
        });

      },
      err => {
        console.log(err);
      }
    );
  }

  textValidation() {
    return (control: AbstractControl): { [key: string]: any } | null =>
       !this.extractNumber(control.value)
            ? null : {number: control.value};
  }

  extractNumber(string) {
    let numArray = string.split('').map(item => {
      if (typeof +item === 'number' && !isNaN(+item)) return +item
    })
    return +numArray.join('')
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }

saveData() {
    const formObj = this.form.getRawValue();

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/locker_assignments', formObj).subscribe(
        (res:any) => {
          // this.router.navigate(['/payment', res['data']['id']]);
          this.appComponent.showMessageAlert(res['message'], 'success');
          window.open(res.receipt_url, '_blank');
          this.id_proof_name = '';
          this.visitor_picture_file_name = '';
          this.createForm();
          this.appComponent.hideLoader();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onFileChange(event:any) {
    this.imageUrlLoading = true;


    let document_id = this.form.value.image_url;
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      // this.visitor_picture_file_name = file.name;

      this.postDocumentToServer(event.target.files[0], document_id , 'person_image_url');

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }

  getIdProofFileDetails(event:any) {
    this.idProofLoading = true;
    let document_id = this.form.value.id_proof_image_url;

    let reader = new FileReader();

    if (event.target['files'] && event.target['files'].length) {
      const [file] = event.target['files'];
      reader.readAsDataURL(file);
      // this.id_proof_name = file.name;

      this.postDocumentToServer(event.target['files'][0], document_id, 'items_image_url');

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }

  onClickCapturePhoto(name) {
    const modalRef = this.modalService.open(CapturePhotoPopoverComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const capturePhotoPopoverComponent: CapturePhotoPopoverComponent = modalRef.componentInstance;
    capturePhotoPopoverComponent.data = null;

    modalRef.result.then((result:any) => {
      if (result) {
        this.apiService.getBlobFileFromServer(result).subscribe(res => {
          let blob:any = new Blob([res], { type: res.type.toString() });
          const imageFile = new File([blob], name, { type: 'image/png' });
          this.postDocumentToServer(imageFile, '' , name);
        });

      }
    });
  }

  postDocumentToServer(file_data:any, document_id:any,keyName?:any) {
    const formData = new FormData();
    formData.append('file', file_data);

    this.apiService.postPublicDataToServer('/add_image_to_space', formData).subscribe(res => {
      let responsedata :  any = res['data'];
      if(responsedata){
        this.appComponent.showMessageAlert(res['message'], 'success');
        this.form.controls[keyName].setValue(responsedata.documentURL);
        this.visitor_picture_file_name = keyName == 'person_image_url' ? responsedata.documentURL : this.visitor_picture_file_name;
        this.imageUrlLoading = false;
        this.id_proof_name = keyName != 'person_image_url' ? responsedata.documentURL : this.id_proof_name;
        this.idProofLoading = false;
      }
    }, (error) => {
      this.appComponent.showMessageAlert(error.error.error[0].message, 'error');
      this.imageUrlLoading = false;
      this.idProofLoading = false;

    });
  }

  // close() {
  //   this.router.navigate(['donation-list']);
  // }

}
