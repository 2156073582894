import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class ApiService {

  base_url = environment.apiBaseUrl;
  public_base_url = environment.baseUrl;

  constructor(private http: HttpClient) {
  }
  postDataToServer(url, body) {

    return this.http.post(this.base_url + url, body);
  }

  public getBlobFileFromServer(relativeUrl: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','*');
    return this.http.get(relativeUrl, { headers : headers,  responseType: 'blob' });
  }
  

  getDataFromServer(url) {

    return this.http.get(this.base_url + url);
  }

  getDataFromPublicServer(url) {
    return this.http.get(this.public_base_url + url);
  }

  getUserDetail(url) {
    return this.http.get(this.base_url + url, {headers : new HttpHeaders().set('Authorization' , 'No-auth')});

  }

  putDataToServer(url, body) {

    return this.http.put(this.base_url + url, body);
  }

  deleteDataFromServer(url) {

    return this.http.delete(this.base_url + url);
  }

  postPublicDataToServer(url, body) {

    return this.http.post(this.base_url + url, body, {headers : new HttpHeaders().set('Authorization' , 'No-auth')});
  }


  getPublicDataFromServer(url) {

    return this.http.get(this.base_url + url, {headers : new HttpHeaders().set('Authorization' , 'No-auth')});
  }

  putPublicDataToServer(url, body) {

    return this.http.put(this.base_url + url, body, {headers : new HttpHeaders().set('Authorization' , 'No-auth')});
  }
}
