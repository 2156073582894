import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {AppComponent} from '../app.component';
import {Router } from '@angular/router';

@Component({
  selector: 'app-add-darshan-type',
  templateUrl: './add-darshan-type.component.html',
  styleUrls: ['./add-darshan-type.component.scss']
})
export class AddDarshanTypeComponent implements OnInit {

  form: FormGroup;
  formMode = 'Add';
  allDarshanTypes = [];
  userList = [];

  constructor(
    private _formBuilder: FormBuilder, 
    private apiService : ApiService, 
    private appComponent: AppComponent, 
    private router: Router,
    private appComponet: AppComponent,
    ) {}

  ngOnInit() {
    this.createForm();
  }


  createForm() {
    this.form = this._formBuilder.group({
      name: ['', [Validators.required ,this.removeSpaces]],
    });
  }

  
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
  
  

  saveData() {
    const formObj = this.form.getRawValue();

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postDataToServer('/darshan_types', formObj).subscribe(
        res => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.createForm();
          this.close();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
    //  else {
    //   this.appComponent.showLoader();
    //   this.apiService.putDataToServer(`/visitors/${formObj.id}`, formObj).subscribe(
    //     res => {
    //       this.appComponent.hideLoader();
    //       this.appComponent.showMessageAlert(res['message'], 'success');
    //       this.router.navigate(['/registration-list']);
    //       this.createForm();
    //     },
    //     err => {
    //       this.appComponent.hideLoader();
    //       this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
    //       console.log(err);
    //     }
    //   );
    // }
  }

  close() {
    this.router.navigate(['/darshan-type']);

  }

}

