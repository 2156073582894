import {Component, NgZone, OnInit} from '@angular/core';
import {ApiService} from '../services/api.service';
import {AppComponent} from '../app.component';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

declare var Razorpay: any;


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

//    allPaymentTypes = [];
//   payment_type_id = '';
//   visitor_id;
//   visitorDetails: any = {};
//   transaction_no = '';
//   transaction_validation_msg = '';

//   constructor(private apiService: ApiService, private appComponent: AppComponent, private route: ActivatedRoute, private router: Router) {
//     this.visitor_id = this.route.snapshot.params.id;
//   }

//   ngOnInit() {
//     this.getAllPaymentTypes();
//     this.getVisitor();
//   }

//   getAllPaymentTypes() {
//     this.apiService.getDataFromServer('/payment_types').subscribe(
//       res => {
//         this.allPaymentTypes = res['data'];
//       },
//       err => {
//         console.log(err);
//       }
//     );
//   }

//   removeSpaces() {
//     setTimeout(() => {
//       const isWhitespace = (this.transaction_no).trim().length === 0;
//       if (isWhitespace) {
//         this.transaction_validation_msg = 'Please Enter Valid Transaction No. / Receipt No.'
//       } else {
//         this.transaction_validation_msg = ''
//       }
//     },200);

//   }

//   getVisitor() {
//     this.apiService.getDataFromServer('/visitors/' + this.visitor_id).subscribe(
//       res => {
//         this.visitorDetails = res;
//         this.visitorDetails.data.date_of_visit = moment.utc(this.visitorDetails.data.date_of_visit).local().format('DD-MM-YYYY');
//       },
//       err => {
//         console.log(err);
//       }
//     );
//   }

//   saveData() {
//      let formObj = {
//        visitor_id : this.visitor_id,
//        payment_type_id : this.payment_type_id,
//        amount: this.visitorDetails.payment_details.total_amount,
//        trx_id:  this.transaction_no
//      };

//     this.appComponent.showLoader();
//     this.apiService.postDataToServer('/visitor_payment', formObj).subscribe(
//       res => {
//         this.apiService.getDataFromServer('/generate_entry_pass/' + this.visitor_id).subscribe(
//           res => {
//             window.open(res['data'][0]);
//             this.appComponent.hideLoader();
//             this.appComponent.showMessageAlert(res['message'], 'success');
//             this.router.navigate(['/registration-list']);
//           },
//           err => {
//             console.log(err);
//           }
//         );
//       },
//       err => {
//         this.appComponent.hideLoader();
//         this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
//         console.log(err);
//       }
//     );
//   }

// }

allPaymentTypes = [];
payment_type_id = '';
visitor_id;
visitorDetails: any = {};
transaction_no = '';
transaction_validation_msg = '';
form: FormGroup;
addPayment = false;
allPaymentGateways = [];
public env: any = environment;


constructor(
  private apiService: ApiService,
  private appComponent: AppComponent,
  private route: ActivatedRoute,
  private router: Router,
  private _formBuilder: FormBuilder,
  private _ngZone: NgZone
  ) {
  this.visitor_id = this.route.snapshot.params.id;
}

ngOnInit() {
  this.getAllPaymentTypes();
  this.getVisitor();
  this.getAllPaymentGateways();
}

getAllPaymentTypes() {
  this.apiService.getDataFromServer('/payment_types').subscribe(
    res => {
      this.allPaymentTypes = res['data'];
    },
    err => {
      console.log(err);
    }
  );
}

  getAllPaymentGateways() {
    this.apiService.getPublicDataFromServer('/payment_gateways').subscribe(
      (res:any) => {
        this.allPaymentGateways = res['data'].filter((val : any) => val.is_enable);
      },
      err => {
        console.log(err);
      }
    );
  }

removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}

removeSpacesTransaction_no() {
  setTimeout(() => {
    const isWhitespace = (this.transaction_no).trim().length === 0;
    if (isWhitespace) {
      this.transaction_validation_msg = 'Please Enter Valid Transaction No. / Receipt No.'
    } else {
      this.transaction_validation_msg = ''
    }
  },200);

}

getVisitor() {
  this.apiService.getDataFromServer('/visitors/' + this.visitor_id).subscribe(
    res => {
      this.visitorDetails = res;
      this.visitorDetails.data.date_of_visit = moment.utc(this.visitorDetails.data.date_of_visit).local().format('DD-MM-YYYY');
    },
    err => {
      console.log(err);
    }
  );
}

addPaymentDetail() {
  this.createForm();
  this.addPayment = true;
}



createForm() {
  const darshan_type_name  = this.visitorDetails && this.visitorDetails.data && this.visitorDetails.data.darshan_type ? this.visitorDetails.data.darshan_type.slug : '';
  const darshan_type = darshan_type_name == 'bhasma_aarti' ? 'Protocol Bhasm Aarti' : darshan_type_name == 'general_bhasmaarti' ? 'Bhasm Aarti' : darshan_type_name == 'general_darshan' ? 'Protocol Darshan' : darshan_type_name == 'sheegra_darshan' ? 'Sheeghra Darshan' : '';
  this.form = this._formBuilder.group({
    payer_first_name: ['', [Validators.required,this.removeSpaces]],
    payer_last_name: ['', [Validators.required,this.removeSpaces]],
    service_id:[this.visitorDetails.data ? this.visitorDetails.data.id :'',[Validators.required]],
    payment_type_id:[this.payment_type_id,[Validators.required]],
    payer_contact:['',[Validators.required, Validators.pattern('[5-9]\\d{9}')]],
    payer_email:['',[Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    payer_address:['',[Validators.required,this.removeSpaces]],
    service_type: 'Darshan',
    is_from_mobile: false,
    darshan_type: darshan_type,
    payment_gateway_id: ['',[Validators.required]]
  });

}


keyPressNumbers(event) {
  var charCode = (event.which) ? event.which : event.keyCode;
  // Only Numbers 0-9
  if ((charCode < 48 || charCode > 57)) {
    event.preventDefault();
    return false;
  } else {
    return true;
  }
}

close() {
  this.addPayment = false;
  this.createForm();
}

saveDataOfPayment() {
  this.appComponent.showLoader();
  this.apiService.postDataToServer('/make_payment', this.form.value).subscribe(
    (res:any) => {
      if(res.success) {
        // window.open(res.url);
        if(this.form.value.payment_gateway_id == 2){
          let _data = res.data;
          let options = {
            "key": this.env.razorpay_key,
            "amount": (_data.amount * 100),
            "currency": _data.currency,
            "name": "Mahakaleshwar Temple Prabhand Samiti Ujjain",
            "order_id": _data.id,
            "handler": (response: any) => {
              this.verifyPayment(response);
            },
            "prefill": {
              "name": _data.prefill_fields.name,
              "email": _data.prefill_fields.email,
              "contact": _data.prefill_fields.contact
            }
          };
          var rzp1 = new Razorpay(options);

          rzp1.open();
        } else {
          window.location.replace(res.url);
        }
      }

      this.appComponent.hideLoader();
    },
    err => {
      this.appComponent.hideLoader();
      this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
      console.log(err);
    }
  );
}

viewPass() {
  this.appComponent.showLoader();
  this.apiService.getDataFromServer('/generate_entry_pass/' + this.visitor_id).subscribe(
    res => {
      window.open(res['data'][0]);
      this.appComponent.hideLoader();
      // this.appComponent.showMessageAlert(res['message'], 'success');
      // this.router.navigate(['/registration-list']);
    },
    err => {
      console.log(err);
    }
  );
}

saveData() {
   let formObj = {
     visitor_id : this.visitor_id,
     payment_type_id : this.payment_type_id,
     amount: this.visitorDetails.payment_details.total_amount,
     trx_id:  this.transaction_no
   };

  this.appComponent.showLoader();
  this.apiService.postDataToServer('/visitor_payment', formObj).subscribe(
    res => {
      this.apiService.getDataFromServer('/generate_entry_pass/' + this.visitor_id).subscribe(
        res => {
          window.open(res['data'][0]);
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.router.navigate(['/registration-list']);
        },
        err => {
          console.log(err);
        }
      );
    },
    err => {
      this.appComponent.hideLoader();
      this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
      console.log(err);
    }
  );
}

  verifyPayment(_data : any) {
    this.appComponent.showLoader();
    let formObj = {
      razorpay_payment_id: _data.razorpay_payment_id,
      razorpay_order_id: _data.razorpay_order_id,
      razorpay_signature: _data.razorpay_signature
    };

    this.apiService.postPublicDataToServer('/verify_payment', formObj).subscribe(
      (res : any) => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(res['message'], 'success');
        this._ngZone.run(()=>{
          this.router.navigate(['/payment-status'], {queryParams: {redirect: true , clientTxnId: res['data']['clientTxnId'] }});
        });

      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }

}

