import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.scss']
})
export class SendSmsComponent implements OnInit {

  form?: FormGroup | any;
  loading =  false;
  roleData: any;
  userList = [];

  constructor(
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    public activeModal: NgbActiveModal,
    ) { }

  ngOnInit(): void {
    this.CreateForm();

  }

  CreateForm() {
    this.form = this._formBuilder.group({
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  
  public closeModal() {
    this.activeModal.close('');
  }
  

  submit() {
    // this.apiService.postDataToServer('/assign_user_roles',this.form.value).subscribe((res: any) => {
    //   this.activeModal.close('success');
    // }, (error) => {
    //   console.log(error , 'error');
    // });
  }
}
