
<div class="toast-msg">
  <ngb-toast *ngIf="isAlertShow" style="position: fixed;" [autohide]="true" (hide)="isAlertShow = false"
            [delay]="3000" [class]="(alert_type == 'success') ? 'bg-success text-light' : 'bg-danger text-light'">
    {{message}}
  </ngb-toast>
</div>

<div class="container-scroller" *ngIf = "urlName != '/login'">
  <app-navbar ></app-navbar>
  <div class="container-fluid page-body-wrapper">
    <app-sidebar></app-sidebar>
     <router-outlet></router-outlet>
  </div>
</div>

<router-outlet *ngIf = "urlName == '/login'"></router-outlet>


<div *ngIf="loader"  class="loader-wrap">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
