import { BrowserModule, Title } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AuthInterceptor} from './services/auth.interceptor';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ChartsModule } from 'ng2-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';


import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import { OrderModule } from 'ngx-order-pipe';
import {MomentDateTimeAdapter} from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import {FilterPipe} from './services/filter.pipe';
import { RefillingCentersComponent } from './refilling-centers/refilling-centers.component';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { RegistrationListComponent } from './registration-list/registration-list.component';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { PaymentComponent } from './payment/payment.component';
import { ConfirmationPopoverComponent } from './confirmation-popover/confirmation-popover.component';
import { PaymentReportComponent } from './payment-report/payment-report.component';
import { FootFallReportComponent } from './foot-fall-report/foot-fall-report.component';
import { RolesComponent } from './roles/roles.component';
import { AddRolesComponent } from './add-roles/add-roles.component';
import { AssingRolesComponent } from './assing-roles/assing-roles.component';
import { ReferenceRegCountReportComponent } from './reference-reg-count-report/reference-reg-count-report.component';
import { SendSmsComponent } from './send-sms/send-sms.component';
import { ResetUserPasswordComponent } from './reset-user-password/reset-user-password.component';
import { ResetPasswordDialogComponent } from './reset-password-dialog/reset-password-dialog.component';
import { VisitorsDetailByRefPersonComponent } from './visitors-detail-by-ref-person/visitors-detail-by-ref-person.component';
import { DailyQuotaComponent } from './daily-quota/daily-quota.component';
import { LateRegistrationsRefCountsReportComponent } from './late-registrations-ref-counts-report/late-registrations-ref-counts-report.component';
import { LateRegVisitorsDetailsComponent } from './late-reg-visitors-details/late-reg-visitors-details.component';
import { SendOtpComponent } from './send-otp/send-otp.component';
import { AssignDepartmentComponent } from './assign-department/assign-department.component';
import { AssignedUserComponent } from './assigned-user/assigned-user.component';
import { AssignDarshanTypeComponent } from './assign-darshan-type/assign-darshan-type.component';
import { AddDarshanTypeComponent } from './add-darshan-type/add-darshan-type.component';
import { DepartmentComponent } from './department/department.component';
import { AddDepartmentComponent } from './add-department/add-department.component';
import { DarshanTypeComponent } from './darshan-type/darshan-type.component';
import { UserToDarshanTypeMappingComponent } from './user-to-darshan-type-mapping/user-to-darshan-type-mapping.component';
import { PermissionsRoleComponent } from './permissions-role/permissions-role.component';
import { AssignPermissionComponent } from './assign-permission/assign-permission.component';
import { UserListComponent } from './user-list/user-list.component';
import { AssignRoleToUserComponent } from './assign-role-to-user/assign-role-to-user.component';
import { ReAssignmentsOfThePermissionsComponent } from './re-assignments-of-the-permissions/re-assignments-of-the-permissions.component';
import { AddDailyAssignmentComponent } from './add-daily-assignment/add-daily-assignment.component';
import { CapturePhotoPopoverComponent } from './capture-photo-popover/capture-photo-popover.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { LiveDarshanComponent } from './live-darshan/live-darshan.component';
import { VerfiyPaymentStatusComponent } from './verfiy-payment-status/verfiy-payment-status.component';
import { QrScanComponent } from './qr-scan/qr-scan.component';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { PrasadOrderListComponent } from './prasad-order-list/prasad-order-list.component';
import { AddDonationComponent } from './add-donation/add-donation.component';
import { DonationListComponent } from './donation-management/donation-list/donation-list.component';
import { SmsLogsComponent } from './sms-logs/sms-logs.component';
import { PaymentSummaryReportComponent } from './payment-summary-report/payment-summary-report.component';
import { RegistrationsSummaryReportComponent } from './registrations-summary-report/registrations-summary-report.component';
import { VisitorsDetailsComponent } from './visitors-details/visitors-details.component';
import { DharmshalaBookingListComponent } from './dharmshala-booking-list/dharmshala-booking-list.component';
import { DharmshalaBookingDetailComponent } from './dharmshala-booking-detail/dharmshala-booking-detail.component';
import { LockerBookingComponent } from './locker-booking/locker-booking.component';
import { LockerBookingListComponent } from './locker-booking-list/locker-booking-list.component';
import { LockerQrScanComponent } from './locker-qr-scan/locker-qr-scan.component';
import { MobileLockerReportComponent } from './mobile-locker-report/mobile-locker-report.component';
import { VisitorsFeedbackComponent } from './visitors-feedback/visitors-feedback.component';
import { PujanBookingsComponent } from './pujan-bookings/pujan-bookings.component';
import { AddLocalDarshanarthisComponent } from './add-local-darshanarthis/add-local-darshanarthis.component';
import { LocalDarshanarthisListComponent } from './local-darshanarthis-list/local-darshanarthis-list.component';
import { LocalDarshanarthisFaceRegistrationComponent } from './local-darshanarthis-face-registration/local-darshanarthis-face-registration.component';
import { ItemCategoriesComponent } from './item-categories/item-categories.component';
import { AddCategoriesComponent } from './add-categories/add-categories.component';
import { ItemsUnitsComponent } from './items-units/items-units.component';
import { ItemsComponent } from './items/items.component';
import { ItemTransactionsComponent } from './item-transactions/item-transactions.component';
import { NewsAndEventsListComponent } from './news-and-events-list/news-and-events-list.component';
import { NewsAndEventsComponent } from './news-and-events/news-and-events.component';
import { DharmshalaReserveRoomComponent } from './dharmshala-reserve-room/dharmshala-reserve-room.component';
import { DharmshalaReservationsListComponent } from './dharmshala-reservations-list/dharmshala-reservations-list.component';
import { BhasmaartiRequestsListComponent } from './bhasmaarti-requests-list/bhasmaarti-requests-list.component';
import { BhasmaartiRequestDetailsComponent } from './bhasmaarti-request-details/bhasmaarti-request-details.component';
import { LaunchingComponent } from './launching/launching.component';

export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'DD-MM-YYYY',
  parseInput: 'DD-MM-YYYY',
  datePickerInput: 'DD-MM-YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    LayoutComponent,
    LoginComponent,
    FilterPipe,
    RefillingCentersComponent,
    RegistrationFormComponent,
    RegistrationSuccessComponent,
    PaymentComponent,
    RegistrationListComponent,
    ConfirmationPopoverComponent,
    PaymentReportComponent,
    FootFallReportComponent,
    RolesComponent,
    AddRolesComponent,
    AssingRolesComponent,
    ReferenceRegCountReportComponent,
    SendSmsComponent,
    ResetUserPasswordComponent,
    ResetPasswordDialogComponent,
    VisitorsDetailByRefPersonComponent,
    DailyQuotaComponent,
    LateRegistrationsRefCountsReportComponent,
    LateRegVisitorsDetailsComponent,
    SendOtpComponent,
    AssignDepartmentComponent,
    AssignedUserComponent,
    AssignDarshanTypeComponent,
    AddDarshanTypeComponent,
    DepartmentComponent,
    AddDepartmentComponent,
    DarshanTypeComponent,
    UserToDarshanTypeMappingComponent,
    PermissionsRoleComponent,
    AssignPermissionComponent,
    UserListComponent,
    AssignRoleToUserComponent,
    ReAssignmentsOfThePermissionsComponent,
    AddDailyAssignmentComponent,
    CapturePhotoPopoverComponent,
    PaymentStatusComponent,
    LiveDarshanComponent,
    VerfiyPaymentStatusComponent,
    QrScanComponent,
    ImageDialogComponent,
    PrasadOrderListComponent,
    AddDonationComponent,
    DonationListComponent,
    SmsLogsComponent,
    PaymentSummaryReportComponent,
    RegistrationsSummaryReportComponent,
    VisitorsDetailsComponent,
    DharmshalaBookingListComponent,
    DharmshalaBookingDetailComponent,
    LockerBookingComponent,
    LockerBookingListComponent,
    LockerQrScanComponent,
    MobileLockerReportComponent,
    VisitorsFeedbackComponent,
    PujanBookingsComponent,
    AddLocalDarshanarthisComponent,
    LocalDarshanarthisListComponent,
    LocalDarshanarthisFaceRegistrationComponent,
    ItemCategoriesComponent,
    AddCategoriesComponent,
    ItemsUnitsComponent,
    ItemsComponent,
    ItemTransactionsComponent,
    NewsAndEventsListComponent,
    NewsAndEventsComponent,
    DharmshalaReserveRoomComponent,
    DharmshalaReservationsListComponent,
    BhasmaartiRequestsListComponent,
    BhasmaartiRequestDetailsComponent,
    LaunchingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AngularMultiSelectModule,
    NgbModule,
    OrderModule,
    ChartsModule,
    PdfViewerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
  { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    Title
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmationPopoverComponent,
    CapturePhotoPopoverComponent,
    AssingRolesComponent,
    ResetPasswordDialogComponent,
    SendSmsComponent,
    SendOtpComponent,
    VerfiyPaymentStatusComponent,
    ImageDialogComponent,
    SmsLogsComponent
  ]
})
export class AppModule { }
