
  <div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header">
            <div class="row">
                <div class="form-group col-1.5 mr-2">
                  <label>Date</label>
                  <input class="form-control border-primary" [owlDateTime]="dt" placeholder="Date" name="from_date"
                    [(ngModel)]="from_date" readonly>
                  <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                  <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                </div>
                <div class="form-group col-1.5 mr-2">
                <label >Locker Location</label>
                <select class="form-control border-primary"  name="locker_location_id" [(ngModel)]="locker_location_id"
                >
                    <option [value]="''">All</option>
                    <option [value] ="type.id" *ngFor = "let type of locker_list">{{type.name}}</option>
                </select>
                </div>
                <div class="form-group col-1.5">
                  <button type="button" style="margin-top: 30px;" class="btn btn-primary btn-fw" (click)="getList($event)">Load</button>
                </div>
              </div>
          <h4 class="card-title">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">Mobile Locker Report</div>
              <!-- <div class="p-2 bd-highlight"  *ngIf = "permissionsService.checkPermission('read_export_foot_fall_report')"><button  type="btn"  class="btn btn-primary btn-fw" (click) = "export()">Export</button>
              </div> -->
            </div>
          </h4>
          <div class="table-responsive" *ngIf="locker_report && locker_report.data && locker_report.data.length">
            <table class="table">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Locker Location</th>
                <th>Collection Count</th>
                <th>Return Count</th>
                <th>Pending</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of locker_report?.data; let i = index">
                <td>
                  <span *ngIf = "page == 1">{{i+1}}</span>
                  <span *ngIf = "page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                </td>
                <td>{{data?.date}}</td>
                <td>{{data?.location}}</td>
                <td>{{data?.collect_count}}</td>
                <td>{{data?.return_count}}</td>
                <td>{{data?.pending}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-12" *ngIf="locker_report && locker_report.data && !locker_report.data.length">
            <span class="ng-star-inserted no_data" >No Data found.</span>
          </div>
          <!-- <ngb-pagination  *ngIf="locker_report && locker_report.data && locker_report.data.length" [collectionSize]="totalCount" [pageSize]="perPage"
          [(page)]="page" (pageChange)="getList('pagination')" [maxSize]="3"
          class="pull-right justify-content-end page-mt-10"
          aria-label="Default pagination"></ngb-pagination> -->
        </div>
      </div>
    </div>
  </div>