<div class="modal-content modal-content">
    <div class="modal-header py-1">
        <h4 class="modal-title">View SMS Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" style="font-size: 40px !important;">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding: 15px 0px;">
        <div class="container">
            <div *ngIf="loader"  style="position: initial !important; height: 300px; background: transparent !important;" class="loader-wrap">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
            <table class="table" *ngIf="!loader && smsLogList.length">
                <thead>
                <tr>
                  <th>S.No</th>
                  <th>Service Type</th>
                  <th>Sms Type</th>
                  <th>Mobile No.</th>
                  <th>Description</th>
                  <th>Created At</th>
                  <th>Delivered At</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of smsLogList; let i = index">
                  <td>
                    <span>{{i+1}}</span>
                  </td>
                  <td>{{data?.service_type}}</td>
                  <td>{{data?.sms_type}}</td>
                  <td>{{data?.mob_no}}</td>
                  <td>{{data?.description}}</td>
                  <td>{{data?.created_at}}</td>
                  <td>{{data?.delivery_date_time}}</td>
                </tr>
                </tbody>
              </table>

            <div class="p-12" *ngIf="!loader && smsLogList && !smsLogList.length">
              <span class="ng-star-inserted no_data" >No Data found.</span>
            </div>
        </div>
      
    </div>
</div>