<div class="row">
    <div class="col-lg-12 grid-margin">
        <div class="card">
            <div class="card-body upper-header">

                <div class="filter-wrapper" *ngIf="form">
                    <form [formGroup]="form" novalidate (ngSubmit)="saveData()">
                        <h5>News And Events</h5>
                        <div class="form-group col-12-div">
                            <div class="form-group col-4-div">
                                <label>Subject*</label>
                                <input class="form-control border-primary" formControlName="subject" name="subject">
                                <div class="" *ngIf="form.controls.subject.touched && !form.controls.subject.valid">
                                    <div class="inaild_no" *ngIf=" form.controls.subject.errors.required">Subject is
                                        required!</div>
                                </div>
                            </div>

                            <div class="form-group col-4-div">
                                <label>Type*</label>
                                <input class="form-control border-primary" type="text" formControlName="type"
                                    name="type">

                                <div class="" *ngIf="form.controls.type.touched && !form.controls.type.valid">
                                    <div class="inaild_no" *ngIf=" form.controls.type.errors.required">Type is required!
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-4-div">
                                <label>Year*</label>
                                <input class="form-control border-primary" type="number" formControlName="year"
                                    name="year">

                                <div class="" *ngIf="form.controls.year.touched && !form.controls.year.valid">
                                    <div class="inaild_no" *ngIf=" form.controls.year.errors.required">Year is required!
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-4-div">
                                <label>Date*</label>
                                <input class="form-control border-primary" 
                                    [owlDateTime]="dt2" [min]="minDate" placeholder="Date" name="date"
                                    formControlName="date" readonly>
                                <span [owlDateTimeTrigger]="dt2" class="date-ico"><i class="fa fa-calendar"></i></span>
                                <owl-date-time #dt2 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>

                                <div class="" *ngIf="form.controls.date.touched && !form.controls.date.valid">
                                    <div class="inaild_no" *ngIf=" form.controls.date.errors.required">Date is required!
                                    </div>
                                </div>

                            </div>

                            <div class="form-group col-4-div">
                                <label>Description*</label>
                                <textarea class="form-control border-primary" id="description" rows="2"
                                    formControlName="description" name="description"></textarea>

                                <!-- <input   class="form-control border-primary"   formControlName="address" name="address" required> -->
                                <div class=""
                                    *ngIf="form.controls.description.touched && !form.controls.description.valid">
                                    <div class="inaild_no" *ngIf=" form.controls.description.errors.required">
                                        Description is required!</div>
                                </div>
                            </div>

                            <!-- <div class="form-group  col-4-div">
                                <label>Thumbnail*</label>
                                <div class="upload-btn-wrapper">
                                    <button class="btn" [ngClass]="thumbnail ? 'upload_file': ''">Upload
                                        Thumbnail</button>
                                    <input type="file" name="document" accept="image/*" class="form-control"
                                        (change)="onImageFileChange($event)" style="padding: 0 !important;" /> 
                                    <img class="mt-2" *ngIf="thumbnail" [src]="thumbnail"
                                        height="125" />
                                    <span class="file_name" *ngIf = "id_proof_name">{{id_proof_name}}</span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>

                            </div> -->



                            <div class="form-group  col-4-div">
                                <label>File*</label>
                                <div class="upload-btn-wrapper">
                                    <button class="btn" [ngClass]="id_proof_name ? 'upload_file': ''">Upload
                                        File</button>
                                    <input type="file" name="document" accept="document/*" class="form-control"
                                        (change)="onFileChange($event)" style="padding: 0 !important;" />
                                       
                                    <!-- <span class="file_name" *ngIf = "file_name">{{file_name}}</span> -->
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>

                            </div>
                            <div class="form-group col-4-div" *ngIf="thumbnail">
                                <label></label>
                                <div style="max-height: 150px;overflow: hidden;border: 2px solid #999;border-radius: 10px;">
                                    <pdf-viewer [src]="thumbnail"
                                                [render-text]="true"
                                                [original-size]="false"
                                                (page-rendered)="screenshot($event)"
                                                style="width: 150px; height: 100px;"
                                    ></pdf-viewer>
                                </div>
                            </div>




                            ​<div class="button-wrap">
                                <button type="submit" [disabled]="!form.valid"
                                    class="btn btn-primary btn-fw">Submit</button>
                                <!-- <button  type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button> -->
                            </div>
                            </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>