import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AppComponent } from '../app.component';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-sms-logs',
  templateUrl: './sms-logs.component.html',
  styleUrls: ['./sms-logs.component.scss']
})
export class SmsLogsComponent implements OnInit {
  visitor_id = '';
  WIDTH = 440;
  HEIGHT = 340;
  clientTxnId:any;
  public smsLogList:any = [];
  public loader = false;
  public errorMsg = "";

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.getSmsLog();
  }

  public closeModal() {
    this.activeModal.close('');
  }

  getSmsLog() {
    this.loader = true;
    this.apiService.getPublicDataFromServer(`/sms_logs/${this.visitor_id}`).subscribe(
      (res:any) => {
        this.smsLogList = res['data'];
        this.loader = false;
        this.smsLogList.map(res => {
          res.created_at = res.created_at ? moment.utc(res.created_at).local().format('DD-MM-YYYY h:mm a z') : '';
          res.delivery_date_time = res.delivery_date_time ? moment.utc(res.delivery_date_time).local().format('DD-MM-YYYY h:mm a z') : '';
        });
      },
      err => {
        this.loader = false;
        console.log(err);
      }
    );
  }


}

