<div class="row">
    <div class="col-lg-12 grid-margin">
        <div class="card">
            <div class="card-body upper-header">
                <div class="row">
                    <div class="form-group col-2 ">
                        <label>From Date</label>
                        <input class="form-control border-primary" [max]="to_date" [owlDateTime]="dt" placeholder="Date"
                            name="from_date" [(ngModel)]="from_date" readonly>
                        <span [owlDateTimeTrigger]="dt" class="date-ico"><i class="fa fa-calendar"></i></span>
                        <owl-date-time #dt [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                    </div>
                    <div class="form-group col-2 ">
                        <label>To Date</label>
                        <input class="form-control border-primary" [min]="from_date" [max]="maxDate" [owlDateTime]="dt1"
                            placeholder="Date" name="to_date" [(ngModel)]="to_date" readonly>
                        <span [owlDateTimeTrigger]="dt1" class="date-ico"><i class="fa fa-calendar"></i></span>
                        <owl-date-time #dt1 [pickerType]="'calendar'" [pickerMode]="'dialog'"></owl-date-time>
                    </div>

                    <div class="form-group col-2 ">
                        <label>Darshan Type</label>
                        <select class="form-control border-primary" (change)="getAllReferences();getAllDepartment()" name="darshan_type_id"
                            [(ngModel)]="darshan_type_id">
                            <option [value]="''">select</option>
                            <option *ngFor="let data of allDarshanTypes" [value]="data.id">{{data.name}}</option>
                        </select>
                    </div>

                    <div class="form-group col-2">
                        <label >Department</label>
                        <select class="form-control border-primary"  name="department_id"  [(ngModel)]="department_id">
                          <option [value]="''">select</option>
                          <option *ngFor="let data of allDepartments" [value]="data.id">{{data.name}}</option>
                        </select>
                      </div>

                    <div class="form-group col-2">
                        <label>Referred By</label>
                        <select class="form-control border-primary" name="referred_by_id" [(ngModel)]="referred_by_id">
                            <option [value]="''">select</option>
                            <option *ngFor="let data of allReferences" [value]="data.id">{{data.name}}</option>
                        </select>
                    </div>


                    <div class="form-group col-1.5">
                        <button type="button" style="margin-top: 30px;"
                            class="btn btn-inverse-primary btn-fw top-btn-head" (click)="getList($event)">Load</button>
                    </div>
                </div>
                <h4 class="card-title">
                    <div class="d-flex bd-highlight">
                        <div class="p-2 flex-grow-1 bd-highlight">Registrations Summary Report</div>
                    </div>
                </h4>
                <div class="table-responsive"
                    *ngIf="registrationSummary && registrationSummary.data && registrationSummary.data.length">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Date</th>
                                <th>Reg. No.</th>
                                <th>Darshan Type</th>
                                <th>Department</th>
                                <th>Reference By</th>
                                <th>Payment Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of registrationSummary?.data; let i = index">
                                <td>
                                    <span *ngIf="page == 1">{{i+1}}</span>
                                    <span *ngIf="page > 1">{{((page - 1 )*20) + (i+1)}}</span>
                                </td>
                                <td>{{data?.date}}</td>
                                <td>{{data?.Reg_no}}</td>
                                <td>{{data?.darshan_type}}</td>
                                <td>{{data?.department}}</td>
                                <td>{{data?.reference}}</td>
                                <td>{{data?.payment_type}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="p-12" *ngIf="registrationSummary && registrationSummary.data && !registrationSummary.data.length">
                    <span class="ng-star-inserted no_data">No Data found.</span>
                </div>
            </div>
        </div>
    </div>
</div>