
<div class="row">
  <div class="col-lg-12 grid-margin">
    <div class="card">
      <div class="card-body upper-header" >

        <div class="filter-wrapper field_space" *ngIf = "form">
          <form   [formGroup]="form" novalidate (ngSubmit)="saveData()">
            <div class="form-group col-12-div">

              <div class="form-group col-4-div">
                <label >User*</label>
                <select class="form-control border-primary"   name="user_id" formControlName="user_id">
                  <option [value]="''">select</option>
                  <option [value] ="user.id" *ngFor = "let user of userList">{{user.username}}</option>
                </select>
                <div class="" *ngIf="form.controls.user_id.touched && !form.controls.user_id.valid">
                  <div  class="inaild_no" *ngIf=" form.controls.user_id.errors.required">User is required!</div>
                </div>
              </div>

              <div class="form-group col-4-div">
                  <label >Darshan Type*</label>
                  <select class="form-control border-primary"  name="darshan_type_id" formControlName="darshan_type_id">
                    <option [value]="''">select</option>
                    <option *ngFor="let data of allDarshanTypes" [value]="data.id">{{data.name}}</option>
                  </select>
                  <div class="" *ngIf="form.controls.darshan_type_id.touched && !form.controls.darshan_type_id.valid">
                    <div  class="inaild_no" *ngIf=" form.controls.darshan_type_id.errors.required">Darshan Type is required!</div>
                  </div>
              </div>
            </div>

            ​<div class="button-wrap">
            <button  type="submit" [disabled]="!form.valid" class="btn btn-primary btn-fw">Submit</button>
            <button  type="button" class="btn btn-secondary btn-fw" (click)="close()">Close</button>
          </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>


