import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {ApiService} from '../services/api.service';
import {AppComponent} from '../app.component';
import * as moment from 'moment';
import {PermissionsService} from '../services/permissions.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {

  env = environment;
  public sidebarOpened = false;
  public userData: any;
   all_alert_data = [];
  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      document.querySelector('.sidebar-offcanvas').classList.add('active');
    }
    else {
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }
  }
  constructor(config: NgbDropdownConfig, private router: Router, private apiService: ApiService, private appComponent: AppComponent,
              public _prmsn: PermissionsService) {
    config.placement = 'bottom-right';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
  ngOnInit() {
    this.userData =  JSON.parse(localStorage.getItem("loginDetails")).user;
  }

  logout(){
    localStorage.clear();
    this.router.navigate(['login']);
  }


}
