import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {PermissionsService} from '../services/permissions.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router, private activatedRoute: ActivatedRoute, private prmsn: PermissionsService) { }

  async canActivate(
    router: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {


      if (localStorage.getItem('loginDetails') != null) {
        
          if (await this.prmsn.isAccessPage(router.routeConfig.path)) {
            return true;
          } else {
            this.router.navigate(['/login']);
          }
   
      } else {
        this.router.navigate(['/login']);
        return false;
      }
  }

}
