
<div class="row">
    <div class="col-lg-12 grid-margin">
      <div class="card">
        <div class="card-body upper-header" >
  
          <div class="filter-wrapper field_space" *ngIf = "form">
            <form   [formGroup]="form" novalidate (ngSubmit)="saveData()">
              <div class="form-group col-12-div">

                <div class="form-group col-6-div" style="min-width: 50%;">
                    <label >Upadate Video Url</label>
                    <input class="form-control border-primary"  formControlName="video_url" name="video_url" >
                    <!-- <div class="" *ngIf="form.controls.video_url.touched && !form.controls.video_url.valid">
                        <div  class="inaild_no" *ngIf=" form.controls.video_url.errors.pattern">Only Alphabet Allowed</div>
                      </div>
                    <div class="" *ngIf="form.controls.video_url.touched && !form.controls.video_url.valid">
                        <div  class="inaild_no" *ngIf=" form.controls.video_url.errors.required">Video Url is required!</div>
                    </div> -->
                </div>
              </div>
  
              ​<div class="button-wrap">
              <button  type="submit" class="btn btn-primary btn-fw">Update</button>
            </div>
  
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  