<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <ul class="nav">
    <!-- *ngIf = "roles && roles.length && roles[0] != 'report_admin'" -->
    <li class="nav-item" *ngIf = "checkPermission('manage_visitors')">
      <a class="nav-link" routerLink="/registration-list" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Registration List</span>
      </a>
    </li>
    <li class="nav-item" *ngIf = "checkPermission('read_reg_details')">
      <a class="nav-link" routerLink="/visitors-details" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Visitors Details</span>
      </a>
    </li>
    <li class="nav-item" *ngIf = "checkPermission('read_visitors_feedback')">
      <a class="nav-link" routerLink="/visitors-feedback" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Visitors Feedback</span>
      </a>
    </li>
    <li class="nav-item" *ngIf = "checkPermission('read_dharmshala_booking')">
      <a class="nav-link" routerLink="/dharmshala-booking" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Dharmshala Bookings</span>
      </a>
    </li>
    <li class="nav-item" *ngIf = "checkPermission('read_dharmshala_reservations')">
      <a class="nav-link" routerLink="/dharmshala-reservations-list" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Dharmshala Reservations List</span>
      </a>
    </li>
    <li class="nav-item" *ngIf = "checkPermission('read_bhasmarti_waiting_list')">
      <a class="nav-link" routerLink="/bhasmarti-waiting-list" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title" >Bhasm Aarti Waiting List</span>
      </a>
    </li>
    <li class="nav-item" *ngIf = "checkPermission('read_bhasmarti_advance_booking_requests')">
      <a class="nav-link" routerLink="/bhasmaarti-requests-list" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title" >Bhasm Aarti Advance Booking <br> Requests List</span>
      </a>
    </li>
    <li class="nav-item" *ngIf = "checkPermission('read_pujan_bookings')">
      <a class="nav-link" routerLink="/pujan-bookings" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Pujan Bookings</span>
      </a>
    </li>
    <li class="nav-item" *ngIf = "checkPermission('read_id_cards')">
      <a class="nav-link" routerLink="/generate-id-card" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Id Card Generator</span>
      </a>
    </li>
    <li class="nav-item" *ngIf = "checkPermission('read_qr_code_scans')">
      <a class="nav-link" routerLink="/qr-scanner" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">QR Scanner</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('create_mark_return')">
      <a class="nav-link" routerLink="/locker-qr-scan" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Locker QR Scanner</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('create_locker_assignments')">
      <a class="nav-link" routerLink="/locker-booking" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Locker Booking</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_locker_assignments')">
      <a class="nav-link" routerLink="/locker-bookings-list" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Locker Booking List</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_mobile_lockers_report')">
      <a class="nav-link" routerLink="/mobile-locker-report" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Mobile Locker Report</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('manage_restricted_dates')">
      <a class="nav-link" routerLink="/restricted-date" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Restricted Date</span>
      </a>
    </li>
    <li class="nav-item" *ngIf = "checkPermission('read_donations')">
      <a class="nav-link" routerLink="/donation-list" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Donation List</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_prasads')">
      <a class="nav-link" routerLink="/prasad-order-list" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Prasad Order List</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_payment_summary')">
      <a class="nav-link" routerLink="/payment-summary-report" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Payment Summary Report</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_registrations_summary')">
      <a class="nav-link" routerLink="/registrations-summary-report" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Registrations Summary Report</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_daily_quota')">
      <a class="nav-link" routerLink="/daily-quota" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Daily Quota</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_foot_fall_report')">
      <a class="nav-link" routerLink="/foot-fall-report" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Foot Fall Report</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_reference_reg_count_report')">
      <a class="nav-link" routerLink="/reference-reg-count-report" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Reference Reg Count Report</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_late_registrations_ref_counts')">
      <a class="nav-link" routerLink="/reference-reg-count-report-7pm" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Reference Reg Count Report - 7 PM</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_visitors_detail_by_ref_person')">
      <a class="nav-link" routerLink="/visitors-detail-by-ref-person" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Visitors Detail By Ref Person</span>
      </a>
    </li>


    <li class="nav-item" *ngIf = "checkPermission('read_late_reg_visitors_details')">
      <a class="nav-link" routerLink="/visitors-detail-by-ref-person-7pm" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Visitors Detail By Ref Person - 7 PM</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_payment_report')">
      <a class="nav-link" routerLink="/payment-report" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Payment Report</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_re_assign_permissions')">
      <a class="nav-link" routerLink="/re-assignments-of-permissions-7pm" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Re Assignments Permissions - 7 PM</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_news_and_events_list')">
      <a class="nav-link" routerLink="/news-and-events-list" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">News And Events list</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('manage_item_units')">
      <a class="nav-link" routerLink="/item-units" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Item Units</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('manage_item_categories')">
      <a class="nav-link" routerLink="/item-categories" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Item Categories</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('manage_items')">
      <a class="nav-link" routerLink="/items" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Items</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('manage_item_transactions')">
      <a class="nav-link" routerLink="/item-transactions" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Item Transaction</span>
      </a>
    </li>


    <!-- <li class="nav-item" *ngIf = "checkPermission('read_re_assign_permissions')">
      <a class="nav-link" routerLink="/daily-assignments" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Daily Assignments</span>
      </a>
    </li> -->

    <li class="nav-item" *ngIf = "checkPermission('manage_departments')">
      <a class="nav-link" routerLink="/department" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Department</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('manage_department_user')">
      <a class="nav-link" routerLink="/user-to-department-mapping" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">User To Department Mapping</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('manage_darshan_types')">
      <a class="nav-link" routerLink="/darshan-type" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Darshan Type</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('manage_darshan_type_user')">
      <a class="nav-link" routerLink="/user-to-darshan-type-mapping" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">User To Darshan Type Mapping</span>
      </a>
    </li>

    <li class="nav-item"  *ngIf = "checkPermission('update_reset_user_password')">
      <a class="nav-link" routerLink="/reset-user-password" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Reset User Password</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('manage_roles')">
      <a class="nav-link" routerLink="/roles" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Roles</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('manage_role_permissions')">
      <a class="nav-link" routerLink="/permissions-role" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Permissions Role</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('create_assign_user_roles')">
      <a class="nav-link" routerLink="/user-list" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Users</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('update_darshan_url')">
      <a class="nav-link" routerLink="/live-darshan" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">Update Live Darshan Url</span>
      </a>
    </li>

    <li class="nav-item" *ngIf = "checkPermission('read_news_and_events_list')">
      <a class="nav-link" routerLink="/news-and-events-list" routerLinkActive="active">
        <i class="menu-icon mdi mdi-view-list"></i>
        <span class="menu-title">News And Events List</span>
      </a>
    </li>

  </ul>
</nav>
