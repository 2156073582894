import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {AppComponent} from '../app.component';
import {ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-live-darshan',
  templateUrl: './live-darshan.component.html',
  styleUrls: ['./live-darshan.component.scss']
})
export class LiveDarshanComponent implements OnInit {


  form: FormGroup;
  formMode = 'Add';
  url = '';
  urlList: any = {};

  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService, 
    private appComponent: AppComponent, 
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appComponet: AppComponent,

    ) {
   
  }

  ngOnInit() {
    this.getList();
    // const id = this.activatedRoute.snapshot.params['id'];
    // if(id) {
    //   this.formMode =  'Edit';
    //   this.getDepartmentDetail(id);
    // } else {
    //   this.createForm();
    // }

    
    this.createForm();
  }


  createForm(data?) {
    this.form = this._formBuilder.group({
      video_url: [data ?  data.name : this.url],
      // id:[data ?  data.id : '']
    });
   
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
  
  getList() {
    this.appComponet.showLoader();
    this.apiService.getDataFromPublicServer(`/public/api/v1/live_darshan`).subscribe(
      (res: any) => {
        this.appComponet.hideLoader();
        this.urlList = res;
        this.url = res.data.video_url;

        this.createForm();
      },
      err => {
        console.log(err);
      }
    );
  }


  saveData() {
    const formObj = this.form.getRawValue();

      this.appComponent.showLoader();
      this.apiService.putDataToServer(`/update_darshan_url`, formObj).subscribe(
        res => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.getList();
          this.createForm();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
  }

}